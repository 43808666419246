import { useState, useEffect } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Select from "../../../components/element/select";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Image } from "primereact/image";

//element component
import Button from "../../../components/element/button";
import InfoTemplate from "../../../components/modal/info.template";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import CheckBox from "../../../components/element/checkbox";

export default function UpdateItemModalBox({
  show,
  close,
  selectedData,
  getInfoDetail,
}) {
  const [roleId, setRoleId] = useState([]);

  const [data, setData] = useState({
    title: selectedData.title ? selectedData.title : "",
    roles_id: selectedData.roles_id ? selectedData.roles_id : "",
    is_quantity: selectedData.is_quantity ? selectedData.is_quantity : false,
    description: selectedData.description ? selectedData.description : "",
    original_price: selectedData.original_price
      ? parseFloat(selectedData.original_price).toFixed(2)
      : "",
    current_price: selectedData.current_price
      ? parseFloat(selectedData.current_price).toFixed(2)
      : "",
  });

  const useSchema = Yup.object({
    title: Yup.string(),
    original_price: Yup.string(),
    current_price: Yup.string(),
  });

  const handleSubmit = async (values) => {
    const payload = {
      type: "room_service_category_service_items",
      id: selectedData.id,
      title: values.title,
      original_price: values.original_price,
      current_price: values.current_price,
      is_quantity: values.is_quantity,
      description: values.description,
      roles_id: values.roles_id,
    };

    try {
      const response = await apiServices.updateServiceCategory({
        api_name: "room_service_category",
        id: 1,
        payload: payload,
      });
      if (response) {
        close();
        getInfoDetail();
        sweetAlertHelper({
          title: "Success",
          text: "Edit Item Success",
          icon: "success",
        });
      }
    } catch {}
  };

  const getRoleListing = async () => {
    const response = await apiServices.getRoleListing();
    if (response) {
      setRoleId(response);
    }
  };

  useEffect(() => {
    getRoleListing();
  }, []);
  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="small-modal" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Service Item</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, values, setFieldValue }) => (
                <Form className="modal-form">
                  <Input name="title" label="Title" />
                  <Input name="description" label="description" />
                  {/* <Input name="original_price" label="Original Price" /> */}
                  <Input name="current_price" label="Current Price" />
                  <CheckBox name="is_quantity" label="Quantity" />
                  <Select
                    name="roles_id"
                    label="Roles Id"
                    options={roleId}
                    placeholder="- Please Select -"
                  />
                  <Button name="submit" type="submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
