import { useState, useRef } from "react";
import moment from "moment";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import { MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import apiServices from "../../../services/api.services";

export default function MerchantListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const actionOptions = ["Wallet Log", "Ticket Log"];
  const [date, setDate] = useState(new Date());
  const toast = useRef(null);

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userStatusToggle({ userId });
      if (response == 200) {
        sweetAlertHelper({
          title: "Success",
          text: "Edit Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="Active"
        falseValue="Inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const phoneBodyTemplate = ({ phone }) => {
    if (phone) {
      return (
        <span>
          {phone.countryCode}
          {phone.phoneNumber}
        </span>
      );
    }
  };

  const dateBodyTemplate = ({ lastLoginedAt }) => {
    if (lastLoginedAt) {
      return <span>{moment(lastLoginedAt).format("DD/MM/YYYY")}</span>;
    }
  };

  const actionBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => handleActionChange(options)}
        >
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(e.value[0]);
          setEndDate(e.value[1]);
          // options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    {
      field: "created_at",
      header: "Register Date",
      filterElement: filterDateTemplate,
    },
    { field: "username", header: "User Name", editor: true },
    { field: "phone_number", header: "Phone Number", editor: true },
    { field: "first_name", header: "First Name", editor: true },
    { field: "last_name", header: "Last Name", editor: true },
    { field: "downline", header: "Referral" },
    { field: "company_name", header: "Company Name", editor: true },
    {
      field: "status",
      header: "Status",
      body: statusBodyTemplate,
    },

    { field: "", header: "Action", body: actionBodyTemplate },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  // inline edit complete function //
  const onRowEditComplete = async (e) => {
    const payload = {
      email: e.newData.username,
      phone_number: e.newData.phone_number,
      first_name: e.newData.first_name,
      last_name: e.newData.last_name,
      method: "PUT",
    };
    try {
      const response = await apiServices.updateListing({
        id: e.newData.id,
        payload: payload,
      });

      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Edit Merchant Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";

      if (errors && Object.keys(errors).length > 0) {
        Object.keys(errors).map((item, i) => {
          //   setFieldError(item, errors[item]);
          errorMsg = errors[item];
        });

        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Merchant Listing"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
        onRowEditComplete={onRowEditComplete}
      />
    </div>
  );
}
