import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import apiServices from "../../../services/api.services";
import config from "../../../config.json";

export default function ActionCard() {
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState(0);
  const [myOrderNumber, setMyOrderNumber] = useState(0);
  const [pendingToClean, setPendingToClean] = useState(0);
  const [myJobNumber, setMyJobNumber] = useState(0);
  const role_id = localStorage.getItem("roles_id");
  const role = localStorage.getItem("roles");
  const username = localStorage.getItem("username");
  const user = localStorage.getItem("user_id");

  const getServiceListing = async () => {
    if (config.operation_roles.includes(role_id)) {
      const response = await apiServices.getAllListing({
        api_name: "room_service_request",
        type: "active",
        search: "",
        order: "desc",
        startDate: "",
        endDate: "",
        length: 99999,
        start: 0,
      });
      if (response) {
        if (config.operation_roles.includes(role_id)) {
          let newDataMyOrder = [];
          let newDataOrder = [];
          for (var key in response.aaData) {
            if (response.aaData[key]["accept_by"] == username) {
              newDataMyOrder.push(response.aaData[key]);
            } else if (response.aaData[key]["accept_by"] == null) {
              newDataOrder.push(response.aaData[key]);
            } else {
            }
            setMyOrderNumber(newDataMyOrder.length);
            setOrderNumber(newDataOrder.length);
          }
        }
      }
    }
  };

  const getRoomListing = async () => {
    if (["10"].includes(role_id)) {
      const response = await apiServices.getBookingListing({
        type: "checked_out",
        search: "",
        order: "desc",
        startDate: "",
        endDate: "",
        length: 99999,
        start: 0,
      });
      if (response) {
        let newPendingData = [];
        let newJobData = [];
        for (var key in response.aaData) {
          if (response.aaData[key]["clean_by"] == user) {
            newJobData.push(response.aaData[key]);
          } else if (response.aaData[key]["clean_by"] == null) {
            newPendingData.push(response.aaData[key]);
          } else {
          }
        }
        setPendingToClean(newPendingData.length);
        setMyJobNumber(newJobData.length);
      }
    }
  };

  useEffect(() => {
    getServiceListing();
    getRoomListing();
  }, []);

  const tabList = [
    {
      role_id: 10,
      role_name: "housekeeping",
      group: [
        {
          path: "/room_service_order",
          label: "Service Request",
          number: orderNumber,
        },
        {
          path: "/room_service_operation_order",
          label: "My Service",
          number: myOrderNumber,
        },
        {
          path: "/booking_checkout",
          label: "Pending to Clean",
          number: pendingToClean,
        },
        {
          path: "/room_my_job",
          label: "My Job",
          number: myJobNumber,
        },
      ],
    },
    {
      role_id: 9,
      role_name: "maintenance",
      group: [
        {
          path: "/room_service_order",
          label: "Service Request",
          number: orderNumber,
        },
        {
          path: "/room_service_operation_order",
          label: "My Service",
          number: myOrderNumber,
        },
      ],
    },
    {
      role_id: 13,
      role_name: "food and beverage",
      group: [
        {
          path: "/room_service_order",
          label: "Service Request",
          number: orderNumber,
        },
        {
          path: "/room_service_operation_order",
          label: "My Service",
          number: myOrderNumber,
        },
      ],
    },
  ];

  return (
    <MDBContainer className="action-card-container">
      {tabList.map((item, index) => (
        <MDBRow key={index}>
          <label>{item.label}</label>
          {item.role_name == role &&
            item.group.map((tab, index) => (
              <MDBCol
                key={index}
                onClick={() => {
                  navigate(tab.path);
                }}
                className="tab-cards-colums col-12 col-md-4"
              >
                <div>
                  <label className="w-100">{tab.label}</label>
                  <label>{tab.number}</label>
                </div>
              </MDBCol>
            ))}
        </MDBRow>
      ))}
    </MDBContainer>
  );
}
