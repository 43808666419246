import { MDBContainer } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";

//component
import BookingListing from "./component/listing";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import useWindowDimensions from "../../components/common/window-dimension";
import apiServices from "../../services/api.services";
import UpdateModal from "./component/update.modal";

export default function RoomJob() {
  const [loader, setLoader] = useState(false);

  const user = localStorage.getItem("user_id");
  //data for api
  const [type, setType] = useState("checked_out"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(99999); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [columns, setColumns] = useState("");
  const [startDate, setStartDate] = useState(""); //filter date
  const [endDate, setEndDate] = useState(""); //filter date
  const [selectedId, setSelectedId] = useState("selectedId");

  //handle modalbox
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  const [actionValue, setActionValue] = useState(false);
  const [updateModal, setUpdateModal] = useState("");
  const [usedId, setUsedId] = useState("");

  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  const [infoData, setInfoData] = useState("");
  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.getBookingListing({
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
      columns,
    });
    if (response) {
      setLoader(false);
      let newData = [];
      for (var key in response.aaData) {
        if (response.aaData[key]["clean_by"] == user) {
          newData.push(response.aaData[key]);
        } else {
          delete response.aaData[key];
        }
        setListingData(newData);
      }
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  const handleCloseModal = () => {
    setActionValue(false);
    setSelectedId("selectedId");
  };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    setActionValue(selectedOption);
    setSelectedId(selectedDataRow.id);

    setInfoData(selectedDataRow);
  };

  const updateModalBox = (id) => {
    setUpdateModal(!updateModal);
    setUsedId(id);
  };

  useEffect(() => {
    if (search === "") {
      // setLoader(true);
    }
    getListing();
  }, [search, length, handleApplyFilter, start]);

  const [openSideBar, setOpenSideBar] = useState(true);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {updateModal && (
          <UpdateModal
            show={true}
            title="My Job"
            close={updateModalBox}
            getListing={getListing}
            listingData={listingData}
            usedId={usedId}
          />
        )}
        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <BookingListing
              length={length}
              start={start}
              listingData={listingData}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
              updateModalBox={updateModalBox}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
