import { useEffect, useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

export default function SignalSetting({ close, setOpenSetting }) {
  return (
    <MDBModal
      staticBackdrop
      tabIndex="-1"
      show={true}
      className="modal-setting"
    >
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Signal Setting</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setOpenSetting(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody></MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
