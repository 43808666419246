import { useState } from "react";
import moment from "moment";
import { MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import apiServices from "../../../services/api.services";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import { Image } from "primereact/image";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

//primereact

//component
import ListingTable from "../../../components/modal/listingTable";

export default function UserListing({
  createAble,
  listingData,
  getListing,
  length,
  start,
}) {
  const deleteBanner = async (id) => {
    try {
      const response = await apiServices.DeleteBanner(id);
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {}
  };

  const editBodyTemplate = (options) => {
    const confirm2 = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Do you want to delete this record?",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept: () => deleteBanner(options.id),
      });
    };
    return (
      <MDBRow className="action-button">
        <div style={{ width: "auto" }} onClick={confirm2}>
          <Icon
            icon="material-symbols:delete-forever-rounded"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const bannerImage = (listingData) => {
    return (
      <Image
        src={listingData.url}
        zoomSrc={listingData.url}
        alt="Image"
        width="80"
        height="60"
        preview
      />
    );
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "id", header: "ID " },
    { field: "url", header: "Banner Image", body: bannerImage },
    { field: "", body: editBodyTemplate, headerStyle: "1em", header: "Update" },
  ];

  return (
    <div className="listing-container">
      <ConfirmPopup />
      <ListingTable
        title="Banner Setting"
        data={listingData}
        columns={columns}
        createAble={createAble}
        length={length}
        start={start}
      />
    </div>
  );
}
