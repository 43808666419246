import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";
import CommissionListing from "./commission.listing";

//service
import useWindowDimensions from "../../components/common/window-dimension";

export default function CommissionSetting() {
  const [openSideBar, setOpenSideBar] = useState(true);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />

        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          <CommissionListing />
        </div>
      </div>
    </MDBContainer>
  );
}
