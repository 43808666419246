import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Calendar } from "primereact/calendar";
import React, { useState, useRef } from "react";

export default function DatePicker({
  label,
  name,
  disabled,
  placeholder,
  selectionMode,
}) {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <MDBRow className="form-group">
      <MDBCol className="col-12 col-md-3 form-label">
        <label htmlFor={name}>{label}</label>
      </MDBCol>
      <MDBCol>
        <Calendar
          className="form-control2"
          name={name}
          id={name}
          value={values[name]}
          onChange={(e) => {
            setFieldValue(name, e.value);
          }}
          selectionMode={selectionMode}
          readOnlyInput
          showIcon
          disabled={disabled}
        />
        {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null}
      </MDBCol>
    </MDBRow>
  );
}
