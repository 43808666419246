import { useState } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function BookingListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const [date, setDate] = useState(new Date());
  const totalPackageLength =
    "payment_info.use_at".length > 0 && " payment_info.use_at".length - 1;

  // const badgeBodyTemplate = (rowData) => {
  //   let status = "";
  //   if (rowData.status == "Active") {
  //     status = "Active";
  //   } else if (rowData.status == "Checked_in") {
  //     status = "Checked_in";
  //   } else if (rowData.status == "Checked_out") {
  //     status = "Checked_out";
  //   } else if (rowData.status == "Completed") {
  //     status = "Completed";
  //   }
  //   return <span className={`custom-badge status-${status}`}>{status}</span>;
  // };

  const badgeBodyTemplate = (listingData) => {
    return <span>{parseFloat(listingData.amount).toFixed(2)}</span>;
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "no", header: "No " },
    { field: "paid_at", header: "Paid on " },
    { field: "reference_no", header: "Booking ID" },
    {
      field: "username",
      header: "Username",
    },
    ,
    { field: "payment_name", header: "Payment By" },
    { field: "amount", header: "Amount", body: badgeBodyTemplate },
    ,
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Room Transaction"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
