import { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
//primereact
import { Steps } from "primereact/steps";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import CreateFormStep1 from "./create.form.step1";
import CreateFormStep2 from "./create.form.step2";
import CreateFormStep3 from "./create.form.step3";
import CreateFormStep4 from "./create.form.step4";
import CreateFormStep5 from "./create.form.step5";
import CreateFormStep6 from "./create.form.step6";

export default function CreateModalBox({ show, close, getListing, disabled }) {
  const [step, setStep] = useState(0);
  const [allData, setAllData] = useState({});
  const [marchantList, setMarchantList] = useState({});
  const [ticketType, setTicketType] = useState("");
  const [packageType, setPackageType] = useState("additional service");

  const items = [
    { label: ticketType ? ticketType.type : "Ticket Type" },
    {
      label: "Ticket Details",
    },
    { label: "Package & Term" },
    { label: "Package Type" },
  ];

  const getMarchantList = async () => {
    const response = await apiServices.getAllListing({
      api_name: "user",
      type: "merchant",
      search: "",
      order: "desc",
      startDate: "",
      endDate: "",
      length: "9999",
      start: "0",
      columns: "",
    });
    if (response) {
      setMarchantList(response.aaData);
    }
  };

  useEffect(() => {
    getMarchantList();
  }, []);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="create-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Create Ticket</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                close();
                getListing();
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer breakpoint="none" className="">
              <Steps model={items} activeIndex={step} />
            </MDBContainer>
            {step == 0 ? (
              <CreateFormStep1
                setTicketType={setTicketType}
                handleNextStep={handleNextStep}
                disabled={disabled}
              />
            ) : step == 1 ? (
              <CreateFormStep2
                handleNextStep={handleNextStep}
                setPackageType={setPackageType}
                ticketType={ticketType}
                setAllData={setAllData}
                disabled={disabled}
              />
            ) : step == 2 ? (
              <CreateFormStep3
                handleNextStep={handleNextStep}
                allData={allData}
                marchantList={marchantList}
                setAllData={setAllData}
                disabled={disabled}
              />
            ) : (
              <CreateFormStep4
                handleNextStep={handleNextStep}
                allData={allData}
                packageType={packageType}
                setAllData={setAllData}
                disabled={disabled}
                close={close}
                getListing={getListing}
              />
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
