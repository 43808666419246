import { useState } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import { MDBRadio, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import { Rating } from "primereact/rating";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Dropdown } from "primereact/dropdown";

//element component
import Button from "../../../components/element/button";
import Input from "../../../components/element/input";
import apiServices from "../../../services/api.services";
import FileUpload from "../../../components/element/file";
import Select from "../../../components/element/select";
import { ColorPicker } from "primereact/colorpicker";

export default function CreateFormStep1({
  handleNextStep,
  getListing,
  setRoomId,
}) {
  const facility = [
    {
      value: "ic:round-wifi",
    },
    {
      value: "fa-solid:umbrella-beach",
    },
    {
      value: "bx:bath",
    },
    {
      value: "icon-park-outline:massage-chair",
    },
    {
      value: "ion:tv-outline",
    },
  ];

  const general = [
    {
      value: "ic:round-wifi",
    },
    {
      value: "fa-solid:umbrella-beach",
    },
    {
      value: "bx:bath",
    },
    {
      value: "icon-park-outline:massage-chair",
    },
    {
      value: "ion:tv-outline",
    },
  ];

  const safety = [
    {
      value: "ic:round-wifi",
    },
    {
      value: "fa-solid:umbrella-beach",
    },
    {
      value: "bx:bath",
    },
    {
      value: "icon-park-outline:massage-chair",
    },
    {
      value: "ion:tv-outline",
    },
  ];

  const cleaning = [
    {
      value: "ic:round-wifi",
    },
    {
      value: "fa-solid:umbrella-beach",
    },
    {
      value: "bx:bath",
    },
    {
      value: "icon-park-outline:massage-chair",
    },
    {
      value: "ion:tv-outline",
    },
  ];
  const rating = [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ];

  const [data, setData] = useState({
    method: "POST",
    name: "",
    type: "tt",
    size: "",
    pax: "",
    description: "",
    original_price: "",
    current_price: "",
    operation_time: "11.00",
    main: null,
    tag: [{ text: "", color: "e8e9e9" }],
    facility: [],
    general: [],
    safety: [],
    cleaning: [],
    expectation: [],
    sub: [null],
    properties: [3],
    policy: [],
  });

  const useSchema = Yup.object({
    name: Yup.string().required("Required"),
    size: Yup.string().required("Required"),
    pax: Yup.string().required("Required"),
    // current_price: Yup.string().required("Required"),
    // tag: Yup.string().required("Required"),
    // tag_color: Yup.string().required("Required"),
    // facility: Yup.string().required("Required"),
    // facility_icon: Yup.string().required("Required"),
    // general: Yup.string().required("Required"),
    // general_icon: Yup.string().required("Required"),
    // safety: Yup.string().required("Required"),
    // safety_icon: Yup.string().required("Required"),
    // cleaning: Yup.array().required("Required"),
    // cleaning_icon: Yup.string().required("Required"),
    operation_time: Yup.string().required("Required"),
    // sub: Yup.string(),
  });

  const handleSubmit = async (values, setFieldError) => {
    const formData = new FormData();
    for (let key in values) {
      if (key == "tag") {
        values.tag.map((item, index) =>
          formData.append(`tag[${index}][text]`, item.text)
        );
        values.tag.map((item, index) =>
          formData.append(`tag[${index}][color]`, item.color)
        );
      } else if (key == "facility") {
        values.facility.map((item, index) =>
          formData.append(`facility[${index}][icon]`, item.icon)
        );
        values.facility.map((item, index) =>
          formData.append(`facility[${index}][title]`, item.title)
        );
      } else if (key == "general") {
        values.general.map((item, index) =>
          formData.append(`general[${index}][title]`, item.title)
        );
      } else if (key == "safety") {
        values.safety.map((item, index) =>
          formData.append(`safety[${index}][title]`, item.title)
        );
      } else if (key == "cleaning") {
        values.cleaning.map((item, index) =>
          formData.append(`cleaning[${index}][title]`, item.title)
        );
      } else if (key == "expectation") {
        values.expectation.map((item, index) => {
          for (var key in item) {
            if (item[key] == "" || item[key] == null) {
            } else {
              if (key == "text") {
                formData.append(`expectation[${index}][text]`, item.text);
              } else if (key == "image") {
                formData.append(`expectation_image[]`, item.image);
              }
            }
          }
        });
      } else if (key === "sub") {
        values.sub.map((item) => formData.append("sub[]", item));
      } else if (key == "properties") {
        // values.properties.map((item) => {
        formData.append("properties[rating][0][label]", "Google Review");
        formData.append("properties[rating][0][rating]", values[key]);
        // });
      } else {
        formData.append(key, values[key]);
        // else {
        //   if (key != "main") {
        //     formData.append(key, values[key]);
        //   } else {
        //     if (values[key] != null && values[key] != "")
        //       formData.append(key, values[key]);
        //   }
        // }
      }
    }
    try {
      const response = await apiServices.createRoom({
        payload: formData,
      });
      if (response) {
        getListing();
        setRoomId(response.id);
        handleNextStep();
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={data}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
        // resetForm({ values: ""})
      }}
    >
      {({ handleReset, values, setFieldValue, errors }) => (
        <Form className="modal-form">
          <Input label="Room Name " name="name" />
          <FileUpload label="Display Image " name="main" />
          <Input label="Room for Pax " name="pax" type="number" />
          <Input label="Room Size " name="size" type="number" />
          <FieldArray name="tag">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Tag for this Room</label>
                </MDBCol>
                <MDBCol>
                  {values.tag.length > 0 &&
                    values.tag.map((tag, index) => (
                      <MDBRow>
                        <MDBCol className="col-5">
                          <Input
                            placeholder="Text"
                            name={`tag.${index}.text`}
                          />
                        </MDBCol>
                        <MDBCol className="col-6">
                          <Input
                            label="Tag Color:"
                            placeholder="Color"
                            name={`tag.${index}.color`}
                            onChange={(e) => {
                              setFieldValue(`tag.${index}.color`, e.value);
                            }}
                          />
                        </MDBCol>
                        <MDBCol className="button-col col-1">
                          <button
                            type="button"
                            className="form-group remove-button"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </MDBCol>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push({ text: "", color: "" })}
                  >
                    Add Tag
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <FieldArray name="sub">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Room Image</label>
                </MDBCol>
                <MDBCol>
                  {values.sub.length > 0 &&
                    values.sub.map((tag, index) => (
                      <MDBRow key={index} className="m-0">
                        <MDBCol className="p-0">
                          <FileUpload
                            placeholder="Text"
                            name={`sub[${index}]`}
                            index={index}
                          />

                          {errors && errors[`sub.${index}`] ? (
                            <div className="text-danger text-right no-padding error-message">
                              {errors[`sub.${index}`]}
                            </div>
                          ) : null}
                        </MDBCol>
                        <MDBCol className="col-1 button-col">
                          <button
                            type="button"
                            className="form-group remove-button"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </MDBCol>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push()}
                  >
                    Add SUB
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <FieldArray name="facility">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Choose Facilities & service</label>
                </MDBCol>
                <MDBCol>
                  {values.facility.length > 0 &&
                    values.facility.map((tag, index) => (
                      <MDBRow key={index}>
                        <MDBRow className="icon-row m-0">
                          {facility.map((item) => (
                            <MDBCol className="icon-container">
                              <label>
                                <Field
                                  type="radio"
                                  name={`facility.${index}.icon`}
                                  value={item.value}
                                />
                                <Icon icon={item.value} width="25" />
                              </label>
                            </MDBCol>
                          ))}
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>
                            <Input
                              placeholder="Title"
                              name={`facility.${index}.title`}
                            />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push({ text: "", color: "" })}
                  >
                    Add Facility
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <FieldArray name="general">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>General Tag info</label>
                </MDBCol>
                <MDBCol>
                  {values.general.length > 0 &&
                    values.general.map((tag, index) => (
                      <MDBRow key={index}>
                        <MDBRow className="m-0 ">
                          <MDBCol>
                            <Input
                              placeholder="Title"
                              name={`general.${index}.title`}
                            />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push({ text: "", color: "" })}
                  >
                    Add General
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <FieldArray name="safety">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Safety & security Tag info</label>
                </MDBCol>
                <MDBCol>
                  {values.safety.length > 0 &&
                    values.safety.map((tag, index) => (
                      <MDBRow key={index}>
                        <MDBRow className="m- icon-row0">
                          <MDBCol>
                            <Input
                              placeholder="Title"
                              name={`safety.${index}.title`}
                            />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push({ text: "", color: "" })}
                  >
                    Add Safety
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <FieldArray name="cleaning">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label> Cleaning service tag info</label>
                </MDBCol>
                <MDBCol>
                  {values.cleaning.length > 0 &&
                    values.cleaning.map((tag, index) => (
                      <MDBRow key={index}>
                        <MDBRow className="m-0" icon-row>
                          <MDBCol>
                            <Input
                              placeholder="Title"
                              name={`cleaning.${index}.title`}
                            />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push({ text: "", color: "" })}
                  >
                    Add Cleaning
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          {/* <Input label="Check in Start at" name="operation_time" />
          <Input label="Check Out Close at " name="check_out" /> */}

          <MDBRow className="form-group">
            <MDBCol className="col-12 col-md-3 form-label">
              <label>Display Rating</label>
            </MDBCol>
            <MDBCol>
              <Rating
                value={values.properties}
                cancel={false}
                onChange={(e) => setFieldValue("properties", e.value)}
              />
            </MDBCol>
          </MDBRow>
          <Input label="Term & Condition" name="policy" />
          <FieldArray name="expectation">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Expectation</label>
                </MDBCol>
                <MDBCol>
                  {values.expectation.length > 0 &&
                    values.expectation.map((tag, index) => (
                      <MDBRow className="p-0" key={index}>
                        <MDBRow className="m-0">
                          <MDBCol className="p-0">
                            <Input
                              placeholder="Title"
                              name={`expectation[${index}].text`}
                            />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="m-0 p-0">
                          <MDBCol>
                            <FileUpload
                              name={`expectation[${index}].image`}
                              index={index}
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() =>
                      push({
                        text: "",
                        image: "",
                      })
                    }
                  >
                    Add Expectation
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <Button name="Next" type="submit" />
        </Form>
      )}
    </Formik>
  );
}
