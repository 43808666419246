import { useState, useRef } from "react";
import moment from "moment";
import { MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function RoomListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
  editModalBox,
}) {
  const actionOptions = ["information"];
  const [date, setDate] = useState(new Date());
  const toast = useRef(null);

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userRoomStatusToggle({ userId });
      if (response == 200) {
        sweetAlertHelper({
          title: "Success",
          text: "Edit Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="active"
        falseValue="inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const editBodyTemplate = (options) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={(e) => editModalBox(options.id)}
        >
          <Icon icon="prime:pencil" color="#41B1CD" width="25" height="25" />
        </div>
      </MDBRow>
    );
  };

  const deleteRoom = async (id) => {
    try {
      const response = await apiServices.DeleteRoom(id);
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  const deleteBodyTemplate = (options) => {
    const confirm2 = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Do you want to delete this record?",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept: () => deleteRoom(options.id),
      });
    };
    return (
      <MDBRow className="action-button">
        <ConfirmPopup />
        <div style={{ width: "auto" }} onClick={confirm2}>
          <Icon
            icon="material-symbols:delete-forever-rounded"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(e.value[0]);
          setEndDate(e.value[1]);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "name", header: "Name" },
    { field: "pax", header: "Room for Pax " },
    { field: "size", header: "Room Size" },
    {
      field: "current_price",
      header: "Display Price",
      editor: true,
    },

    {
      field: "status",
      header: "Status",
      body: statusBodyTemplate,
    },
    // { field: "", body: actionBodyTemplate, headerStyle: "0.5em" },
    { field: "", body: editBodyTemplate, headerStyle: "1em", header: "Update" },
    {
      field: "",
      body: deleteBodyTemplate,
      headerStyle: "1em",
      header: "Delete",
    },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Room"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
