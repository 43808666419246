import moment from "moment/moment";
import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";

//component
import TicketListing from "./component/ticket.listing";
import InfoModalBox from "./component/info.modalbox";
import CreateModalBox from "./component/create.modalbox";
import EditModalBox from "./component/edit.modalbox";
import TicketModalBox from "./component/ticket.modalbox";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import apiServices from "../../services/api.services";
import useWindowDimensions from "../../components/common/window-dimension";

export default function Ticket() {
  const [loader, setLoader] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(true);
  //data for api
  const [type, setType] = useState("all"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(""); //filter date
  const [endDate, setEndDate] = useState(""); //filter date
  const [columns, setColumns] = useState(""); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("selectedId");
  const [infoModalBoxData, setInfoModalBoxData] = useState({});

  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);
  const getListing = async () => {
    const response = await apiServices.getAllListing({
      api_name: "ticket",
      type,
      search,
      order,
      columns,
      startDate,
      endDate,
      length,
      start,
    });
    if (response) {
      setLoader(false);
      setListingData(response.aaData);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  const getInfoDetail = async () => {
    const response = await apiServices.getTicketInfo(selectedId);
    if (response) {
      setInfoModalBoxData(response);
    }
  };

  useEffect(() => {
    if (search === "") {
      // setLoader(true);
    }
    getListing();
  }, [search, length, handleApplyFilter, start]);

  useEffect(() => {
    if (selectedId >= 0) {
      getInfoDetail();
    }
  }, [selectedId]);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleCloseModal = () => {
    setInfoModalBoxData({});
    setActionValue(false);
    setSelectedId("selectedId");
  };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    setActionValue(selectedOption);
    setSelectedId(selectedDataRow.id);
  };

  const createAble = () => {
    setCreateModal(!createModal);
  };

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {actionValue == "information" && (
          <TicketModalBox
            show={true}
            selectedId={selectedId}
            close={handleCloseModal}
            disabled={true}
          />
        )}
        {actionValue == "edit" && Object.keys(infoModalBoxData).length > 0 && (
          <EditModalBox
            show={true}
            selectedData={infoModalBoxData}
            close={handleCloseModal}
            getInfoDetail={getInfoDetail}
            getListing={getListing}
            selectedId={selectedId}
          />
        )}
        {createModal && (
          <CreateModalBox
            show={createModal}
            title="Create Modal Box"
            close={createAble}
            getListing={getListing}
          />
        )}
        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />

          <TicketListing
            length={length}
            start={start}
            createAble={createAble}
            listingData={listingData}
            totalRecords={totalRecords}
            displayRecords={displayRecords}
            setListingData={setListingData}
            setSearch={setSearch}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setOrder={setOrder}
            setLength={setLength}
            setStart={setStart}
            // handleNewModal={handleNewModal}
            actionValue={actionValue}
            // createAble={handleNewModal}
            handleActionChange={handleActionChange}
            getListing={getListing}
            setHandleApplyFilter={setHandleApplyFilter}
            setColumns={setColumns}
            ticketType={type}
            // setTicketType={setType}
          />
        </div>
      </div>
    </MDBContainer>
  );
}
