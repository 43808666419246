import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useState } from "react";
import useWindowDimensions from "../../components/common/window-dimension";
import QrCodeScanner from "../../components/modal/qr.payment.scanner";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";
import ActionCard from "./component/action.card";
import SignalSetting from "../SignalUi";

export default function Dashboard() {
  const [openSideBar, setOpenSideBar] = useState(true);
  const { height, width } = useWindowDimensions();
  const [openSetting, setOpenSetting] = useState(false);
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  const [payment, setPayment] = useState("11");

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };
  const handleOpenQrCodeScan = () => {
    setOpenQrCodeScan(!openQrCodeScan);
  };

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {openQrCodeScan && (
          <QrCodeScanner
            show={true}
            close={handleOpenQrCodeScan}
            payment={payment}
          />
        )}
        <div className="page-container">
          <Header
            handleSideBar={handleOpenSideBar}
            handleOpenQrCodeScan={handleOpenQrCodeScan}
            setOpenSetting={setOpenSetting}
            openSetting={openSetting}
          />
          <ActionCard />
        </div>
      </div>
    </MDBContainer>
  );
}
