import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import moment from "moment/moment";
import { useState, useEffect } from "react";
import apiServices from "../../../services/api.services";
import useWindowDimensions from "../../../components/common/window-dimension";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function InfoModalBox({
  show,
  close,
  data,
  selectedId,
  setSelectedId,
  setInfoModalBoxData,
  getListing,
  setSearch,
}) {
  const roles = localStorage.getItem("roles");
  const { height, width } = useWindowDimensions();

  const [responsive, setResponsive] = useState("mobile");

  const scanQRBooking = async () => {
    if (roles === "merchant") {
      try {
        const response = await apiServices.scanMyPlanning({
          api_name: roles,
          payload: {
            qr: data.booking_id,
          },
        });
        if (response) {
          sweetAlertHelper({
            title: "Success",
            text: "Success",
            icon: "success",
          });
          getListing();
          close(false);
        }
      } catch (ex) {
        let errorMsg = "";
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                // setFieldError(item, errors[item]);
                errorMsg = errors[item];
              });
            }
          }
          sweetAlertHelper({
            title: "Error",
            text: `${errorMsg}`,
            icon: "error",
          });
        }
      }
    } else {
      sweetAlertHelper({
        title: "Error",
        text: `Only Merchant can Accept`,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="booking-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Information Ticket</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                setSearch("");
                close();
                setInfoModalBoxData({});
                setSelectedId("");
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <label className="booking-label-bold">
                  Booking ID: <span>{data.booking_id}</span>
                </label>
              </MDBRow>
              <MDBRow>
                <label className="booking-label-bold">
                  Ticket name: <span>{data.ticket_name}</span>
                </label>
              </MDBRow>
              <MDBRow>
                <label className="booking-label-bold">
                  Customer Email: {data.customer_name}
                </label>
              </MDBRow>
              <div className="package-box-container">
                <MDBRow>
                  <label className="booking-label-bold">Package Detail</label>
                </MDBRow>
                <div className="booking-package-box detail">
                  <MDBRow className="m-0">
                    <MDBCol className="">
                      <label className="booking-label-grey">Package Type</label>
                    </MDBCol>
                    <MDBCol>
                      <label>{data.ticket_package_type_name}</label>
                    </MDBCol>
                  </MDBRow>
                  {data.package_detail
                    ? data.package_detail.map((item, index) => (
                        <MDBRow className="m-0" key={index}>
                          <MDBCol className="">
                            <label className="booking-label-grey">
                              {item.ticket_package_type_item_name}
                            </label>
                          </MDBCol>
                          <MDBCol>
                            <label>x{item.quantity}</label>
                          </MDBCol>
                        </MDBRow>
                      ))
                    : ""}
                  <MDBRow className="m-0">
                    <MDBCol className="">
                      <label className="booking-label-grey">
                        Additional service
                      </label>
                    </MDBCol>
                    <MDBCol>
                      {data.additional_service
                        ? data.additional_service.map((item, index) => (
                            <MDBRow>
                              <label>{item.addon_item_name}</label>
                            </MDBRow>
                          ))
                        : ""}
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
              <div className="merchant-action-container">
                <MDBRow className="booking-label-bold">
                  <label>Merchant Action</label>
                </MDBRow>
                <MDBRow className="marchant-action-row">
                  <MDBCol>
                    <label>{data.merchant}</label>
                  </MDBCol>
                  <MDBCol className="text-end">
                    {data.ticket_status === "use now" ? (
                      <button
                        className="merchant-status accept"
                        onClick={() => scanQRBooking()}
                        disabled={roles == "merchant" ? false : true}
                      >
                        ACCEPT !
                      </button>
                    ) : data.ticket_status === "used" ? (
                      <button className="merchant-status done" disabled={true}>
                        CLAIM !
                      </button>
                    ) : (
                      " - "
                    )}
                  </MDBCol>
                </MDBRow>
              </div>
              <div className="package-box-container">
                <MDBRow className="booking-label-bold">
                  <label>Claim Activity</label>
                </MDBRow>
                <div className="booking-package-box">
                  <MDBRow className="m-0">
                    <MDBCol className="col-12 col-md-3">
                      <MDBRow>
                        <MDBCol className="col-12 activity">
                          <label className="booking-label-bold">Merchant</label>
                        </MDBCol>
                        <MDBCol className="col-12">
                          <label>{data.used_at ? data.merchant : " - "}</label>
                        </MDBCol>
                      </MDBRow>
                      {/* <label className="booking-label-bold">Merchant</label> */}
                    </MDBCol>
                    <MDBCol className="col-12 col-md-3">
                      <MDBRow>
                        <MDBCol className="col-12 activity">
                          <label className="booking-label-bold">Notes</label>
                        </MDBCol>
                        <MDBCol className="col-12 activity-data">
                          <label>
                            {data.used_at
                              ? `claim order ID ${selectedId}`
                              : " - "}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol
                      className={
                        responsive == "mobile" ? "text-start" : "text-end"
                      }
                    >
                      <MDBRow>
                        <MDBCol className="col-12 activity">
                          <label className="booking-label-bold">
                            Record On
                          </label>
                        </MDBCol>
                        <MDBCol className="col-12 activity-data">
                          <label>
                            {/* {data && data.used_at
                              ? moment(data.used_at).format("DD - MM - YYYY")
                              : " - "} */}
                            {data.used_at}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </div>
              </div>
            </MDBContainer>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
