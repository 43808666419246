import { useRef, useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBContainer,
} from "mdb-react-ui-kit";

//primereact
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";

//service
import apiServices from "../../../services/api.services";

//component
import TicketModalBoxStep1 from "./ticket.modalbox.step1";
import TicketModalBoxStep2 from "./ticket.modalbox.step2";

export default function TicketModalBox({
  show,
  close,
  getListing,
  selectedId,
  disabled,
}) {
  const toast = useRef(null);
  const [step, setStep] = useState(0);
  const [tempData, setTempData] = useState({});
  const [marchantList, setMarchantList] = useState({});
  const [ticketType, setTicketType] = useState("");
  const [packageType, setPackageType] = useState("");
  const getInfoDetail = async () => {
    const response = await apiServices.getTicketInfo(selectedId);
    if (response) {
      setTempData(response);
    }
  };

  const getMarchantList = async () => {
    const response = await apiServices.getAllListing({
      api_name: "user",
      type: "merchant",
      search: "",
      order: "desc",
      startDate: "",
      endDate: "",
      length: "9999",
      start: "0",
      columns: "",
    });
    if (response) {
      setMarchantList(response.aaData);
    }
  };

  const items = [
    {
      label: ticketType ? ticketType : "Ticket Type",
      command: (event) => {
        setStep(0);
      },
    },
    {
      label: "Ticket Details",
      command: (event) => {
        setStep(1);
      },
    },
    {
      label: "Package & Term",
      command: (event) => {
        tempData.is_ticket_package_valid
          ? setStep(2)
          : toast.current.show({
              severity: "info",
              summary: "Save step 2 to coutinue",
              detail: event.item.label,
            });
      },
    },
    {
      label: "Package type",
      command: (event) => {
        tempData.is_ticket_package_type_valid
          ? setStep(3)
          : toast.current.show({
              severity: "info",
              summary: `Save step 
              ${tempData.is_ticket_package_valid ? "2" : "3"} to coutinue`,
              detail: event.item.label,
            });
      },
    },
  ];

  useEffect(() => {
    getMarchantList();
    if (disabled) {
      getInfoDetail();
    }
  }, []);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="create-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Create Ticket</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                close();
                getListing();
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer breakpoint="none" className="">
              <Steps
                model={items}
                activeIndex={step}
                readOnly={disabled ? false : true}
              />
            </MDBContainer>
            {step == 0 ? (
              <TicketModalBoxStep1
                setTicketType={setTicketType}
                handleNextStep={handleNextStep}
                tempData={tempData}
                disabled={disabled}
              />
            ) : (
              <TicketModalBoxStep2
                handleNextStep={handleNextStep}
                tempData={tempData}
                disabled={disabled}
                ticketType={ticketType}
              />
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
