import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Button from "../element/button";
import apiServices from "../../services/api.services";
import { sweetAlertHelper } from "../../helper/sweetalert.helper";
import Input from "../element/input";
import {
  Html5QrcodeScanner,
  Html5QrcodeSupportedFormats,
  Html5Qrcode,
} from "html5-qrcode";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBRow,
  MDBModalFooter,
  MDBContainer,
  MDBCol,
} from "mdb-react-ui-kit";
export default function QrCodeScanner({
  title,
  show,
  close,
  setSearch,
  payment,
  listingData,
  setModalBox,
  handleActionChange,
}) {
  const role = localStorage.getItem("roles");
  const [scannedCodes, setScannedCodes] = useState([]);
  const [array, setArray] = useState([]);

  const [input, setInput] = useState(false);

  const [data, setData] = useState({
    amount: "",
  });

  const useSchema = Yup.object({
    // amount: Yup.string(),
  });

  const handleSubmit = async (values, setFieldError) => {
    if (role === "merchant") {
      try {
        const response = await apiServices.scanQr({
          api_name: role,
          payload: {
            qr: scannedCodes[0].decodedText,
            amount: values.amount,
          },
        });
        if (response) {
          sweetAlertHelper({
            title: "Success",
            text: "Create  Success",
            icon: "success",
          });
          close(false);
        }
      } catch (ex) {
        let errorMsg = "";
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
                errorMsg = errors[item];
              });
            }
          }
          sweetAlertHelper({
            title: "Error",
            text: `${errorMsg}`,
            icon: "error",
          });
          setInput(true);
        }
      }
    } else {
      sweetAlertHelper({
        title: "Error",
        text: "The role does not support the scanning function",
        icon: "error",
      });
    }
  };

  const errorMessage = () => {
    sweetAlertHelper({
      title: "Error",
      text: "The role does not support the scanning function",
      icon: "error",
    });
  };
  const checkClick = async () => {
    let qr = scannedCodes[0].decodedText;
    setSearch(qr);
    close(false);
    let newData = {};
    // for (var key in listingData) {
    //   if (listingData[key]["booking_id"] == qr) {
    //     // newData.push(listingData[key]);
    //     newData = listingData[key];
    //   } else {
    //   }
    const response = await apiServices.getScanPlanningInfo(qr);
    if (response) {
      // setInfoModalBoxData(response);
      handleActionChange("information", response);
    }
  };

  const checkRoom = () => {
    let qr = scannedCodes[0].decodedText;
    setSearch(qr);
    close(false);
    let newData = {};
    for (var key in listingData) {
      if (listingData[key]["order_number"] == qr) {
        newData = listingData[key];
      } else {
      }
      handleActionChange("information", newData);
    }
  };

  function activateLasers() {
    var decodedText = "asdf";
    var decodedResult = "asdfasdfasdf";

    setScannedCodes(scannedCodes.concat([{ decodedText, decodedResult }]));
  }

  useEffect(() => {
    if (role === "merchant" || role === "frontdesk") {
      if (scannedCodes[0] && role === "frontdesk") {
        checkRoom();
      }
      if (scannedCodes[0] && payment !== "11" && role === "merchant") {
        checkClick();
      }
    } else {
      errorMessage();
      close(false);
    }
  }, [scannedCodes]);

  useEffect(() => {
    let html5QrCode = new Html5Qrcode("reader", {
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    });
    function onScanSuccess(decodedText, decodedResult) {
      // handle the scanned code as you like, for example:
      setScannedCodes(scannedCodes.concat([{ decodedText, decodedResult }]));
      html5QrCode
        .stop()
        .then((ignore) => {
          // QR Code scanning is stopped.
        })
        .catch((err) => {
          // Stop failed, handle it.
        });
    }
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };
    html5QrCode.start({ facingMode: "environment" }, config, onScanSuccess);
  }, []);

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div id="reader" width="600px"></div>
                  {scannedCodes.map((scannedCode, index) => (
                    <label key={index}>{scannedCode.decodedText}</label>
                  ))}
                  {payment === "11" ? (
                    <Input name="amount" label="Amount" />
                  ) : (
                    ""
                  )}
                  {payment === "11" ? (
                    <Button name="Submit" type="submit" />
                  ) : (
                    ""
                  )}
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
