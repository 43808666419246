import { FieldArray } from "formik";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function InputFieldArray({
  label,
  name,
  values,
  arrayItem,
  errors,
  pushItem,
  class_name_group,
  class_name_button,
}) {
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <MDBRow className="form-group">
          <MDBCol className="col-12 col-3 form-label">
            <label>{label}</label>
          </MDBCol>
          {values.length > 0 &&
            values.map((array, index) => (
              <MDBRow className="p-0">
                <MDBCol className={`array-item-group ${class_name_group}`}>
                  {arrayItem}
                </MDBCol>
                <MDBRow
                  className="m-0"
                  style={{
                    padding: "0em 0.5em",
                  }}
                >
                  <MDBCol
                    className={`p-0 form-group array-item-group-button ${class_name_button}`}
                  >
                    <button
                      type="button"
                      className={
                        index >= values.length - 1
                          ? "add button"
                          : "remove-button"
                      }
                      onClick={() => {
                        index >= values.length - 1
                          ? pushItem
                            ? push({ pushItem })
                            : push()
                          : remove(index);
                      }}
                    >
                      {index >= values.length - 1
                        ? `Add ${label}`
                        : `Remove ${label}`}
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBRow>
            ))}
        </MDBRow>
      )}
    </FieldArray>
  );
}
