import { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Calendar } from "primereact/calendar";

//element component
import Button from "../../../components/element/button";
import Select from "../../../components/element/select";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import DateRange from "../../../components/element/dateRange";
import DateMultiSelect from "../../../components/element/dateMultiSelect";
import CheckBox from "../../../components/element/checkbox";
import FileUpload from "../../../components/element/file";

export default function CreateFormStep1({
  handleNextStep,
  setTicketType,
  disabled,
}) {
  const [dateRange, setDateRange] = useState("");
  const [specificDate, setSpecificDate] = useState("");
  //options
  const type = [
    { label: "Event", id: "event" },
    { label: "Activity", id: "activity" },
    { label: "Promotion", id: "promotion" },
    { label: "Place", id: "place" },
  ];

  //createTicket
  const [createData, setCreateData] = useState({
    ticket_type: "ticket",
  });
  const useSchema = Yup.object({
    type: Yup.string().required("required"),
  });

  const handleSubmit = async (values, setFieldError) => {
    handleNextStep();
    setTicketType(values);
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={createData}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue }) => (
        <Form className="modal-form">
          <Select
            name="type"
            label="Ticket Type"
            options={type}
            placeholder="- Please Select -"
            disabled={disabled}
          />
          <Button name="Next" type="submit" />
        </Form>
      )}
    </Formik>
  );
}
