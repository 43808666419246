import { MDBContainer } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

//common component
import Header from "../../components/header/header-one";
import QrCodeScanner from "../../components/modal/qr.code.scanner";
import Sidebar from "../../components/sidebar/sidebar-one";

//component
import HistoryListing from "./component/history.listing";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import apiServices from "../../services/api.services";
import useWindowDimensions from "../../components/common/window-dimension";

export default function PlanningHistory() {
  const [loader, setLoader] = useState(false);

  //data for api
  const [type, setType] = useState("all"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //handle modalbox
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.getPlanningHistory({
      api_name: "user",
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
    });
    if (response) {
      setLoader(false);
      setListingData(response.aaData);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  useEffect(() => {
    // setLoader(true);
    getListing();
  }, [search, length, handleApplyFilter, start]);

  const [openSideBar, setOpenSideBar] = useState(true);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleOpenQrCodeScan = () => {
    setOpenQrCodeScan(!openQrCodeScan);
  };

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {openQrCodeScan && (
          <QrCodeScanner
            show={true}
            title="QR SCan"
            close={handleOpenQrCodeScan}
          />
        )}
        <div className="page-container">
          <Header
            handleSideBar={handleOpenSideBar}
            handleOpenQrCodeScan={handleOpenQrCodeScan}
          />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <HistoryListing
              length={length}
              start={start}
              listingData={listingData}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
