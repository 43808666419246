import { useState } from "react";
import moment from "moment";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function Request({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const actionOptions = ["Wallet Log", "Ticket Log"];
  const [date, setDate] = useState(new Date());

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userStatusToggle({ userId });
      if (response == 200) {
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="Active"
        falseValue="Inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const phoneBodyTemplate = ({ phone }) => {
    if (phone) {
      return (
        <span>
          {phone.countryCode}
          {phone.phoneNumber}
        </span>
      );
    }
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "username", header: "Email" },
    { field: "password", header: "Password", editor: true },
    { field: "role_name", header: "Room Balance", editor: true },
    { field: "downline", header: "Downline" },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Agent Listing"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
