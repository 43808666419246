import { useState } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function BookingListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const [date, setDate] = useState(new Date());
  const totalPackageLength =
    "payment_info.use_at".length > 0 && " payment_info.use_at".length - 1;

  const badgeBodyTemplate = (rowData) => {
    let status = "";
    if (rowData.status == "Active") {
      status = "Active";
    } else if (rowData.status == "Checked_in") {
      status = "Checked_in";
    } else if (rowData.status == "Checked_out") {
      status = "Checked_out";
    } else if (rowData.status == "Completed") {
      status = "Completed";
    }
    return <span className={`custom-badge status-${status}`}>{status}</span>;
  };

  const actionBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => handleActionChange("information", options)}
        >
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "order_number", header: "Booking ID" },
    { field: "room_number", header: "Room No" },
    { field: "checked_in_at", header: "Check in at" },
    { field: "checked_out_at", header: "Check out at" },
    { field: "title", header: "Room" },
    {
      field: "status",
      header: "Status",
      body: badgeBodyTemplate,
    },
    { field: "username", header: "User" },
    { field: "guest_name", header: "Guest Name" },
    {
      field: "created_at",
      header: "Booking On",
      // filterElement: filterDateTemplate,
    },
    { field: "payment_method", header: "Payment Via" },
    { field: "created_by", header: "Create By" },
    ,
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Complete Room"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
