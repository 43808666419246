import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../components/common/window-dimension";

//component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";
import CreditListing from "./component/listing";
import InfoModalBox from "./component/info.modal.box";
import Gif from "../../assets/fonts/loader/loader.gif";

//service
import apiServices from "../../services/api.services";

export default function CreditPurchase() {
  const [loader, setLoader] = useState(false);

  const [openSideBar, setOpenSideBar] = useState(true);
  //data for api
  const [type, setType] = useState("all"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("selectedId");
  const [infoModalBoxData, setInfoModalBoxData] = useState({});
  const [infoData, setInfoData] = useState("");
  const { height, width } = useWindowDimensions();

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.walletReport({
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
      status: "completed",
      columns: "created_at",
    });
    if (response) {
      setLoader(false);
      setListingData(response.aaData);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  const getInfoDetail = async () => {
    const response = await apiServices.getPlanningInfo(selectedId);
    if (response) {
      setInfoModalBoxData(response);
    }
  };

  const handleCloseModal = () => {
    setActionValue(false);
    setSelectedId("selectedId");
  };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    setActionValue(selectedOption);
    setSelectedId(selectedDataRow.id);
    // setInfoModalBoxData(selectedDataRow);
  };

  useEffect(() => {
    if (search === "") {
      // setLoader(true);
    }
    getListing();
  }, [search, length, start]);

  // useEffect(() => {
  //   if (actionValue == "information" && selectedId >= 0) {
  //     getInfoDetail();
  //   }
  // }, [selectedId]);

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {actionValue == "information" &&
          Object.keys(listingData).length > 0 && (
            <InfoModalBox
              show={true}
              selectedData={infoData}
              close={handleCloseModal}
              getListing={getListing}
            />
          )}
        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <CreditListing
              length={length}
              start={start}
              listingData={listingData}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
