import { useState } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function DayListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
  editModalBox,
}) {
  const [date, setDate] = useState(new Date());

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userStatusToggle({ userId });
      if (response == 200) {
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="active"
        falseValue="inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const editBodyTemplate = (options) => {
    return (
      <MDBRow className="action-button">
        <div style={{ width: "auto" }} onClick={(e) => editModalBox(options)}>
          <Icon icon="prime:pencil" color="#41B1CD" width="25" height="25" />
        </div>
      </MDBRow>
    );
  };

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(e.value[0]);
          setEndDate(e.value[1]);
          options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    // { field: "created_at", header: "Create Day" },
    {
      field: "date",
      header: "Date",
      // filterElement: filterDateTemplate,
    },
    { field: "type", header: "Type" },
    // {
    //   field: "status",
    //   header: "Status",
    //   body: statusBodyTemplate,
    // },
    { field: "", body: editBodyTemplate, headerStyle: "1em", header: "Update" },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Peak Session Setting"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
