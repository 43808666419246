import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRadio,
  MDBRow,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import authServices from "../../services/auth.services";
import SubSideBar from "./sub-sidebar";

export default function Sidebar({ isOpen, handleSideBar }) {
  const navigate = useNavigate();
  // const { pathname } = useLocation();

  const role = localStorage.getItem("roles");
  // isOpen = true;
  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },
    {
      name: "Management",
      icon: "fe:list-bullet",
      group: [
        {
          path: "/user",
          name: "User Listing",
        },
        {
          path: "/admin",
          name: "Operation Listing",
        },
        {
          path: "/merchant",
          name: "Merchant Listing",
        },
        {
          path: "/guest",
          name: "Guest",
        },
      ],
    },
    {
      name: "Room Service",
      icon: "material-symbols:room-service",
      group: [
        {
          path: "/room_service_category",
          name: "Service Listing",
        },
        {
          path: "/room_service_order",
          name: "Order Request",
        },
        {
          path: "/room_service_order_history",
          name: "Complete Order",
        },
      ],
    },
    {
      name: "Room",
      icon: "material-symbols:meeting-room",
      group: [
        {
          path: "/room",
          name: "Room Type Listing",
        },
        {
          path: "/booking",
          name: "Booking Listing",
        },
        {
          path: "/booking_checkout",
          name: "Pending to Clean",
        },
        {
          path: "/room_history",
          name: "Complete Room ",
        },
      ],
    },

    {
      name: "Ticket",
      icon: "mdi:ticket-account",
      group: [
        {
          path: "/ticket",
          name: "Ticket Listing",
        },
        {
          path: "/ticket_booking",
          name: "Booking Listing",
        },
        {
          path: "/ticket_history",
          name: "Claimed Listing",
        },
      ],
    },
    {
      name: "App Setting",
      icon: "ic:outline-app-settings-alt",
      group: [
        {
          path: "/day",
          name: "Peak Setting",
          // icon: "bxs:user-detail",
        },
        {
          path: "/banner",
          name: "Banner Setting",
          // icon: "bxs:user-detail",
        },
      ],
    },
    {
      name: "Report",
      icon: "carbon:report",
      group: [
        {
          path: "/transaction",
          name: "All Transaction ",
        },
        {
          path: "/pg_transaction",
          name: "PG Transaction ",
        },
        {
          path: "/wallet_transaction",
          name: "Wallet Transaction",
        },
        {
          path: "/reload_transaction",
          name: "Reload Transaction",
        },
      ],
    },
  ];
  const menuItemMaintenance = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },
    {
      name: "Room Service",
      icon: "material-symbols:room-service",
      group: [
        {
          path: "/room_service_order",
          name: "Order Request",
        },
        {
          path: "/room_service_operation_order",
          name: "My Order",
        },
        {
          path: "/room_service_order_history",
          name: "Complete Order",
        },
      ],
    },
  ];
  const menuItemHousekeeping = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },
    {
      name: "Room Service",
      icon: "material-symbols:room-service",
      group: [
        {
          path: "/room_service_order",
          name: "Order Request",
        },
        {
          path: "/room_service_operation_order",
          name: "My Order",
        },
        {
          path: "/room_service_order_history",
          name: "Complete Order",
        },
      ],
    },
    {
      name: "Room",
      icon: "material-symbols:meeting-room",
      group: [
        {
          path: "/booking_checkout",
          name: "Check Out",
        },
        {
          path: "/room_my_job",
          name: "My Job",
        },
      ],
    },
  ];
  const menuItemFinance = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },

    {
      name: "Room Service",
      icon: "material-symbols:room-service",
      group: [
        {
          path: "/room_service_transaction",
          name: "Room Service Transaction",
        },
      ],
    },
    {
      name: "Room",
      icon: "material-symbols:meeting-room",
      group: [
        {
          path: "/room_transaction",
          name: " Room Transaction",
        },
      ],
    },

    {
      name: "Ticket",
      icon: "mdi:ticket-account",
      group: [
        {
          path: "/ticket_transaction",
          name: "Ticket Transaction",
        },
      ],
    },
    {
      name: "Report",
      icon: "carbon:report",
      group: [
        {
          path: "/transaction",
          name: "All Transaction ",
        },
        {
          path: "/pg_transaction",
          name: "PG Transaction ",
        },
        {
          path: "/wallet_transaction",
          name: "Wallet Transaction",
        },
        {
          path: "/reload_transaction",
          name: "Reload Transaction",
        },
      ],
    },
  ];
  const menuItemFoodandbeverage = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },

    {
      name: "Room Service",
      icon: "material-symbols:room-service",
      group: [
        {
          path: "/room_service_order",
          name: "Order Request",
        },
        {
          path: "/room_service_operation_order",
          name: "My Order",
        },
        {
          path: "/room_service_order_history",
          name: "Complete Order",
        },
      ],
    },
  ];
  const menuItemMerchant = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },

    {
      name: "Ticket",
      icon: "mdi:ticket-account",
      group: [
        {
          path: "/ticket",
          name: "Ticket Listing",
        },
        {
          path: "/ticket_booking",
          name: "Booking Listing",
        },
        {
          path: "/ticket_history",
          name: "Claimed Listing",
        },
        {
          path: "/ticket_transaction",
          name: "Ticket Transaction",
        },
      ],
    },
    {
      name: "Report",
      icon: "carbon:report",
      group: [
        {
          path: "/credit_payment_transaction",
          name: "Credit Payment Transaction",
        },
      ],
    },
  ];
  const menuItemMarketing = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },

    {
      name: "Ticket",
      icon: "mdi:ticket-account",
      group: [
        {
          path: "/ticket",
          name: "Ticket Listing",
        },
        {
          path: "/ticket_booking",
          name: "Booking Listing",
        },
        {
          path: "/ticket_history",
          name: "Claimed Listing",
        },
        {
          path: "/ticket_transaction",
          name: "Ticket Transaction",
        },
      ],
    },
  ];
  const menuItemFrontdesk = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },

    {
      name: "Room Service",
      icon: "material-symbols:room-service",
      group: [
        {
          path: "/room_service_order",
          name: "Order Request",
        },
        {
          path: "/room_service_order_history",
          name: "Complete Order",
        },
      ],
    },
    {
      name: "Room",
      icon: "material-symbols:meeting-room",
      group: [
        {
          path: "/room",
          name: "Room Type Listing",
        },
        {
          path: "/booking",
          name: "Booking Listing",
        },
        {
          path: "/room_history",
          name: "Complete Room ",
        },
      ],
    },
    {
      name: "Guest",
      icon: "bxs:user-detail",
      group: [
        {
          path: "/guest",
          name: "Guest",
        },
      ],
    },
  ];
  const menuItemNone = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "bxs:dashboard",
    },
  ];

  const handleLogout = () => {
    authServices.logout();
    navigate("/");
  };

  return (
    <div
      className={isOpen ? "sidebar active" : "sidebar "}
      style={{ width: isOpen ? "220px" : "50px" }}
    >
      <MDBContainer className="sidebar-container">
        <MDBRow className="sidebar-top">
          <MDBCol className="d-flex sidebar-control">
            <span
              className="role"
              style={{ display: isOpen ? "block" : "none" }}
            >
              {role}
            </span>
            <MDBRadio
              onClick={() => handleSideBar()}
              checked={isOpen}
              onChange={() => {}}
            />
          </MDBCol>
        </MDBRow>
        <div className="side-bar-item">
          {role === "superadmin"
            ? menuItem.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "maintenance"
            ? menuItemMaintenance.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "housekeeping"
            ? menuItemHousekeeping.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "finance"
            ? menuItemFinance.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "food and beverage"
            ? menuItemFoodandbeverage.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "merchant"
            ? menuItemMerchant.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "marketing"
            ? menuItemMarketing.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "frontdesk"
            ? menuItemFrontdesk.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : role === "admin"
            ? menuItem.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))
            : menuItemNone.map((item, index) => (
                <div key={index}>
                  <SubSideBar item={item} isOpen={isOpen} />
                </div>
              ))}
        </div>
        <MDBRow
          className="sidebar-bottom"
          style={{ display: isOpen ? "block" : "none" }}
        >
          <MDBCol className="d-flex logout" onClick={() => handleLogout()}>
            <span>logout</span>
            <Icon
              icon="heroicons-outline:logout"
              width="25"
              height="28"
              rotate={2}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
