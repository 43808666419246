import { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { ColorPicker } from "primereact/colorpicker";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import Select from "../../../components/element/select";
import CheckBox from "../../../components/element/checkbox";
import DateMultiSelect from "../../../components/element/dateMultiSelect";
import DateRange from "../../../components/element/dateRange";
import { useEffect } from "react";
import DatePicker from "../../../components/element/datePicker";
export default function EditModalForm3({
  getInfoDetail,
  selectedData,
  handleNextStep,
  marchantList,
  getListing,
  setAllData,
}) {
  let originalPrice = selectedData.display_original_price;
  let currentPrice = selectedData.display_current_price;
  let original = originalPrice.replace(/,/g, "");
  let current = currentPrice.replace(/,/g, "");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [specificDate, setSpecificDate] = useState("");
  const type = [
    { label: "event" },
    { label: "activity" },
    { label: "promotion" },
    { label: "place" },
  ];
  const date_type = [
    {
      label: "Date Range",
      id: "date range",
    },
    {
      label: "Specific Date",
      id: "specific date",
    },
  ];
  const package_type = [
    {
      label: "Simple Type",
      id: "standard",
    },
    {
      label: "Advance Type",
      id: "additional service",
    },
  ];
  const [data, setData] = useState({
    // date_of_birth: new Date(),
    method: selectedData.is_ticket_package_valid ? "PUT" : "POST",
    ticket_type: "ticket_package",
    id: selectedData.ticket_package_id ? selectedData.ticket_package_id : "",
    type: selectedData.type ? selectedData.type : "",
    title: selectedData.package_name ? selectedData.package_name : "",
    ticket_id: selectedData.id ? selectedData.id : "",
    display_original_price: selectedData.display_original_price
      ? parseFloat(original).toFixed(0)
      : "35",
    display_current_price: selectedData.display_current_price
      ? parseFloat(current).toFixed(0)
      : "15",
    package_title: selectedData.package_title ? selectedData.package_title : "",
    is_activate_require: selectedData.is_activate_require
      ? selectedData.is_activate_require
      : false,
    advance_booking_day: selectedData.advance_booking_day
      ? selectedData.advance_booking_day
      : 0,
    is_select_date_require: selectedData.is_select_date_require
      ? selectedData.is_select_date_require
      : false,
    availability_date: selectedData.ticket_package_availability_date
      ? selectedData.ticket_package_availability_date
      : [],
    availability_date_type: selectedData.ticket_package_availability_date_type
      ? selectedData.ticket_package_availability_date_type
      : "",
    availability_start_date: selectedData.ticket_package_availability_start_date
      ? moment(
          new Date(selectedData.ticket_package_availability_start_date)
        ).format("YYYY-MM-DD hh:mm")
      : "",
    availability_end_date: selectedData.ticket_package_availability_end_date
      ? moment(
          new Date(selectedData.ticket_package_availability_end_date)
        ).format("YYYY-MM-DD hh:mm")
      : "",
    package_pattern: selectedData.package_pattern
      ? selectedData.package_pattern
      : "",
    merchant: selectedData.merchant ? selectedData.merchant : "",
    tag: selectedData.ticket_package_tag
      ? selectedData.ticket_package_tag
      : [{ text: "", color: "e8e9e9" }],
    tnc: selectedData.tnc ? selectedData.tnc : [],
  });

  const useSchema = Yup.object({
    package_title: Yup.string().required("required"),
    availability_date_type: Yup.string().required("required"),
    package_pattern: Yup.string().required("required"),
    merchant: Yup.string().required("required"),
    tnc: Yup.array().min(1, "required").required("required"),
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    if (values.availability_date_type !== "date range") {
      delete values.availability_end_date;
      delete values.availability_start_date;
    }
    try {
      if (selectedData.is_ticket_package_valid) {
        const response = await apiServices.updateTicket({
          id: selectedData.ticket_package_id,
          payload: values,
        });
        if (response) {
          sweetAlertHelper({
            title: "Success",
            text: "Edit Ticket Success",
            icon: "success",
          });
          handleNextStep();
          getInfoDetail();
        }
      } else {
        const response = await apiServices.createTicketForm({
          payload: values,
        });
        if (response) {
          setAllData(response);
          getInfoDetail();
          handleNextStep();
        }
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (
      selectedData.ticket_package_availability_start_date &&
      selectedData.ticket_package_availability_end_date
    ) {
      setDateRange([
        new Date(selectedData.ticket_package_availability_start_date),
        new Date(selectedData.ticket_package_availability_end_date),
      ]);
    }
    if (selectedData.ticket_package_availability_date) {
      let newDate = [];
      selectedData.ticket_package_availability_date.map((array) => {
        newDate.push(moment(new Date(array)).format("YYYY-MM-DD hh:mm"));
      });
      setSpecificDate(newDate);
    }
  }, [selectedData]);

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={data}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue, errors, isSubmitting }) => (
        <Form className="modal-form">
          <Input name="package_title" label="(Title) Package" />
          <Input name="title" label="(Name) Package" />
          <FieldArray name="tag">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>TAG For This Package</label>
                </MDBCol>
                <MDBCol>
                  {values.tag.length > 0 &&
                    values.tag.map((tag, index) => (
                      <MDBRow className="p-0">
                        <MDBCol className="col ">
                          <Input
                            placeholder="Text"
                            name={`tag.${index}.text`}
                          />
                        </MDBCol>
                        <MDBCol className="col d-flex align-items-center">
                          <Input
                            label="Tag Color"
                            placeholder="Color"
                            name={`tag.${index}.color`}
                            onChange={(e) => {
                              setFieldValue(`tag.${index}.color`, e.value);
                            }}
                          />
                        </MDBCol>
                        <MDBCol className="button-col col-2">
                          <button
                            type="button"
                            className="form-group remove-button"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </MDBCol>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push({ text: "", color: "e8e9e9" })}
                  >
                    Add Tag
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <Select
            name="availability_date_type"
            label=" Date Term "
            options={date_type}
            placeholder="- Please Select -"
            setSpecificDate={setSpecificDate}
            setDateRange={setDateRange}
          />
          {values.availability_date_type == "date range" ? (
            <DateRange
              name="range_date"
              label="Range Of Date"
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          ) : (
            <DateMultiSelect
              name="specific_date"
              label="Specific Date"
              specificDate={specificDate}
              setSpecificDate={setSpecificDate}
            />
          )}
          {values.availability_date_type == "date range" ? (
            <>
              <CheckBox
                name="is_activate_require"
                label="Require User Advance Booking"
                checked={values.is_activate_require}
              />
              {values.is_activate_require == true ? (
                <Input
                  name="advance_booking_day"
                  type="number"
                  label="No. of Advance Booking day"
                />
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}{" "}
          <FieldArray name="tnc">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>T&C Of The Package</label>
                </MDBCol>
                <MDBCol>
                  {values.tnc.length > 0 &&
                    values.tnc.map((tag, index) => (
                      <MDBRow key={index} className="m-0">
                        <MDBCol className="p-0">
                          <Input placeholder="Text" name={`tnc.${index}`} />
                        </MDBCol>
                        <MDBCol className="col-1 button-col">
                          <button
                            type="button"
                            className="form-group remove-button"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </MDBCol>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push()}
                  >
                    Add Term & Condition
                  </button>
                </MDBCol>
                {errors && errors["tnc"] ? (
                  <div className="text-danger text-right no-padding error-message">
                    {errors["tnc"]}
                  </div>
                ) : null}
              </MDBRow>
            )}
          </FieldArray>
          <Select
            name="merchant"
            label="Related Merchant"
            options={marchantList}
          />
          <Select
            name="package_pattern"
            label="Package Pattern"
            options={package_type}
            placeholder="- Please Select -"
            disabled={false}
          />
          <Button name="Save" type="submit" isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}
