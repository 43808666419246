import { MDBNavbar, MDBContainer, MDBCol, MDBSwitch } from "mdb-react-ui-kit";
import ProtectedRoute from "../common/protected.route";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";

import OneSignal from "react-onesignal";
import { sweetAlertHelper } from "../../helper/sweetalert.helper";

export default function Header({ handleSideBar, handleOpenQrCodeScan }) {
  const notificationStatus = localStorage.getItem("isPushNotificationsEnabled");
  const role = localStorage.getItem("roles");
  const [open, setOpen] = useState(
    notificationStatus ? notificationStatus : "false"
  );

  // const setShowOneSignal = () => {
  //   setOpen(open == "true" ? "false" : "true");
  //   if (open == "false") {
  //     sweetAlertHelper({
  //       title: "Success",
  //       text: "OneSignal Notifications : Enabled",
  //       icon: "success",
  //     });
  //   } else if (open == "true") {
  //     sweetAlertHelper({
  //       title: "Success",
  //       text: "OneSignal Notifications : Disabled",
  //       icon: "success",
  //     });
  //   }
  // };

  // const configureOneSignal = () => {
  //   OneSignal.setSubscription(open);
  // };

  // useEffect(() => {
  //   OneSignal.setSubscription(open == "true" ? true : false);
  // }, [open]);

  return (
    <>
      <MDBNavbar sticky className={`header-container`}>
        <ProtectedRoute />
        <MDBContainer fluid>
          <MDBCol className="col-1 header-closemenu">
            <Icon
              icon="bi:layout-sidebar-inset"
              color="black"
              width="25"
              height="30"
              onClick={() => {
                handleSideBar();
              }}
            />
          </MDBCol>

          {handleOpenQrCodeScan ? (
            <MDBCol className="qr-btt">
              <Icon
                icon="bx:qr-scan"
                width="30"
                height="30"
                color="black"
                onClick={() => handleOpenQrCodeScan()}
              />
            </MDBCol>
          ) : (
            <MDBCol className="qr-btt"></MDBCol>
          )}
          {role == "superadmin" ||
            role == "housekeeping" ||
            role == "maintenance" ||
            role == "frontdesk" ||
            role == "food and beverage"}
          <MDBCol className="qr-btt col-1">
            {/* <MDBSwitch
              checked={open == "true"}
              onChange={() => setShowOneSignal()}
            /> */}
          </MDBCol>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
