import { useState } from "react";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";

export default function WalletListing({
  length,
  start,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setOrder,
  setLength,
  setStart,
  setHandleApplyFilter,
  getListing,
}) {
  const [date, setDate] = useState(new Date());
  const badgeBodyTemplate = (listingData) => {
    return <span>{parseFloat(listingData.amount).toFixed(2)}</span>;
  };
  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "no", header: "No " },
    { field: "paid_at", header: "Paid on " },
    { field: "transaction_id", header: "PG ID" },
    { field: "reference_no", header: "Booking ID" },
    { field: "order_type", header: "Order Type" },
    {
      field: "username",
      header: "Username",
    },
    {
      field: "fullname",
      header: "Full name",
    },
    {
      field: "phone_number",
      header: "Contact",
    },
    ,
    { field: "payment_name", header: "Payment type" },
    { field: "amount", header: "Amount", body: badgeBodyTemplate },

    // { field: "transaction_id", header: "Detail" },
  ];

  return (
    <div className="listing-container">
      <ListingTable
        title="PG Transaction Report"
        data={listingData}
        columns={columns}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
