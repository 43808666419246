import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";

export default function Select({
  label,
  name,
  options,
  // errors,
  disabled,
  placeholder,
  setDateRange,
  setSpecificDate,
}) {
  const { setFieldValue, handleChange, errors } = useFormikContext();
  return (
    <MDBRow className="form-group">
      <MDBCol className="col-12 col-md-3 form-label">
        <label htmlFor={name}>{label}</label>
      </MDBCol>
      <MDBCol>
        <Field
          className="form-control"
          as="select"
          id={name}
          name={name}
          disabled={disabled}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (name === "availability_date_type") {
              setDateRange("");
              setSpecificDate("");
            }
          }}
        >
          <option value="">{placeholder}</option>
          {name === "merchant" ? (
            <>
              {options &&
                options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.username}
                  </option>
                ))}
            </>
          ) : (
            <>
              {options &&
                options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
            </>
          )}
        </Field>
        {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null}
      </MDBCol>
    </MDBRow>
  );
}
