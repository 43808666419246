import { useState } from "react";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import Select from "../../../components/element/select";

export default function TicketModalBoxStep1({
  handleNextStep,
  setTicketType,
  tempData,
  disabled,
}) {
  const type = [
    { label: "Event", id: "Event" },
    { label: "Activity", id: "Activity" },
    { label: "Promotion", id: "Promotion" },
    { label: "Place", id: "Place" },
  ];

  const useSchema = Yup.object({
    type: Yup.string().required("required"),
  });

  const handleSubmit = async (values, setFieldError) => {
    handleNextStep();
    setTicketType(values.type);
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={tempData}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue }) => (
        <Form className="modal-form">
          <Select
            name="type"
            label="Ticket Type"
            options={type}
            placeholder="- Please Select -"
            disabled={disabled}
          />
          <Button name={disabled ? "Save" : "Next"} type="submit" />
        </Form>
      )}
    </Formik>
  );
}
