import { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Calendar } from "primereact/calendar";

//element component
import Button from "../../../components/element/button";
import Select from "../../../components/element/select";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function CreateFormStep4({
  handleNextStep,
  allData,
  packageType,
  setAllData,
  close,
  getListing,
}) {
  const [dataAddOn, setDataAddOn] = useState({
    ticket_type: "ticket_package_type",
    method: "POST",
    title: allData.title ? allData.title : "",
    ticket_package_id: allData ? allData.id : "",
    package_type: [
      {
        package_type_name: "",
        package_type_item: [
          {
            package_type_item_name: "",
            package_type_item_pricing: "",
            package_type_item_limit: "",
          },
        ],
        package_type_item_addon: [
          {
            add_on_name: "",
            add_on_item: [
              {
                add_on_item_name: "",
                add_on_item_pricing: "",
              },
            ],
          },
        ],
      },
    ],
  });

  const [data, setData] = useState({
    ticket_type: "ticket_package_type",
    method: "POST",
    title: allData.title ? allData.title : "",
    ticket_package_id: allData ? allData.id : "",
    package_type: [
      {
        package_type_name: "",
        package_type_item: [
          {
            package_type_item_name: "",
            package_type_item_pricing: "",
            package_type_item_limit: "",
          },
        ],
      },
    ],
  });

  const addonSchema = Yup.object({
    package_type: Yup.array(
      Yup.object().shape({
        package_type_name: Yup.string().required("required"),
        package_type_item: Yup.array(
          Yup.object().shape({
            package_type_item_name: Yup.string().required("required"),
            package_type_item_pricing: Yup.string().required("required"),
            package_type_item_limit: Yup.string().required("required"),
          })
        ),
        package_type_item_addon: Yup.array(
          Yup.object().shape({
            add_on_name: Yup.string().required("required"),
            add_on_item: Yup.array(
              Yup.object().shape({
                add_on_item_name: Yup.string().required("required"),
                add_on_item_pricing: Yup.string().required("required"),
              })
            ),
          })
        ),
      })
    ),
  });

  const useSchema = Yup.object({
    package_type: Yup.array(
      Yup.object().shape({
        package_type_name: Yup.string().required("required"),
        package_type_item: Yup.array(
          Yup.object().shape({
            package_type_item_name: Yup.string().required("required"),
            package_type_item_pricing: Yup.string().required("required"),
            package_type_item_limit: Yup.string().required("required"),
          })
        ),
      })
    ),
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    try {
      const response = await apiServices.createTicketForm({
        payload: values,
      });

      if (response) {
        close();
        getListing();
        sweetAlertHelper({
          title: "Success",
          text: "Add Ticket Success",
          icon: "success",
        });
      }
    } catch (ex) {
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={
        allData.package_pattern == "additional service" ? dataAddOn : data
      }
      validationSchema={
        allData.package_pattern == "additional service"
          ? addonSchema
          : useSchema
      }
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue, errors, isSubmitting }) => (
        <Form className="modal-form">
          <MDBRow className="form-group">
            <MDBCol>
              <MDBRow className="m-0">
                <MDBCol>
                  {/* <Input label="Title" placeholder="Title" name={`title`} /> */}
                  <FieldArray name={`package_type`}>
                    {({ insert, remove, push }) => (
                      <MDBRow className="form-group">
                        <MDBCol className="col-12 col-md-3 form-label">
                          <label>Package Type</label>
                        </MDBCol>
                        <MDBCol>
                          {values.package_type.length > 0 &&
                            values.package_type.map((tag, packageTypeIndex) => (
                              <MDBRow className="p-0">
                                <MDBCol>
                                  <Input
                                    placeholder="Package Type Name"
                                    name={`package_type.${packageTypeIndex}.package_type_name`}
                                  />
                                  {errors &&
                                  errors["package_type"] &&
                                  errors["package_type"][packageTypeIndex] &&
                                  errors["package_type"][packageTypeIndex][
                                    "package_type_name"
                                  ] ? (
                                    <div className="text-danger text-right no-padding error-message">
                                      {
                                        errors["package_type"][
                                          packageTypeIndex
                                        ]["package_type_name"]
                                      }
                                    </div>
                                  ) : null}
                                  <FieldArray
                                    name={`package_type.${packageTypeIndex}.package_type_item`}
                                  >
                                    {({ insert, remove, push }) => (
                                      <MDBRow className="form-group m-0">
                                        <MDBCol className="col-3 form-label p-0">
                                          <label className="p-0">
                                            Package Item
                                          </label>
                                        </MDBCol>
                                        <MDBCol className="p-0">
                                          {values.package_type[packageTypeIndex]
                                            .package_type_item.length > 0 &&
                                            values.package_type[
                                              packageTypeIndex
                                            ].package_type_item.map(
                                              (tag, packageTypeItemIndex) => (
                                                <MDBRow className="p-0">
                                                  <MDBCol className="package-type-item">
                                                    <Input
                                                      placeholder="Package Item Name"
                                                      name={`package_type.${packageTypeIndex}.package_type_item.${packageTypeItemIndex}.package_type_item_name`}
                                                    />
                                                    {errors &&
                                                    errors["package_type"] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"][
                                                      packageTypeItemIndex
                                                    ] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"][
                                                      packageTypeItemIndex
                                                    ][
                                                      "package_type_item_name"
                                                    ] ? (
                                                      <div className="text-danger text-right no-padding error-message">
                                                        {
                                                          errors[
                                                            "package_type"
                                                          ][packageTypeIndex][
                                                            "package_type_item"
                                                          ][
                                                            packageTypeItemIndex
                                                          ][
                                                            "package_type_item_name"
                                                          ]
                                                        }
                                                      </div>
                                                    ) : null}
                                                    <Input
                                                      placeholder="Package Item Pricing"
                                                      name={`package_type.${packageTypeIndex}.package_type_item.${packageTypeItemIndex}.package_type_item_pricing`}
                                                    />
                                                    {errors &&
                                                    errors["package_type"] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"][
                                                      packageTypeItemIndex
                                                    ] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"][
                                                      packageTypeItemIndex
                                                    ][
                                                      "package_type_item_pricing"
                                                    ] ? (
                                                      <div className="text-danger text-right no-padding error-message">
                                                        {
                                                          errors[
                                                            "package_type"
                                                          ][packageTypeIndex][
                                                            "package_type_item"
                                                          ][
                                                            packageTypeItemIndex
                                                          ][
                                                            "package_type_item_pricing"
                                                          ]
                                                        }
                                                      </div>
                                                    ) : null}
                                                    <Input
                                                      placeholder="Limit of this package item"
                                                      name={`package_type.${packageTypeIndex}.package_type_item.${packageTypeItemIndex}.package_type_item_limit`}
                                                    />
                                                    {errors &&
                                                    errors["package_type"] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"][
                                                      packageTypeItemIndex
                                                    ] &&
                                                    errors["package_type"][
                                                      packageTypeIndex
                                                    ]["package_type_item"][
                                                      packageTypeItemIndex
                                                    ][
                                                      "package_type_item_limit"
                                                    ] ? (
                                                      <div className="text-danger text-right no-padding error-message">
                                                        {
                                                          errors[
                                                            "package_type"
                                                          ][packageTypeIndex][
                                                            "package_type_item"
                                                          ][
                                                            packageTypeItemIndex
                                                          ][
                                                            "package_type_item_limit"
                                                          ]
                                                        }
                                                      </div>
                                                    ) : null}
                                                  </MDBCol>
                                                  <MDBRow
                                                    className="m-0"
                                                    style={{
                                                      padding: "0em 0.5em",
                                                    }}
                                                  >
                                                    {/* <MDBCol className="col-3"></MDBCol> */}
                                                    <MDBCol className="p-0 form-group package-item-button">
                                                      <button
                                                        type="button"
                                                        className={
                                                          packageTypeItemIndex >=
                                                          values.package_type[
                                                            packageTypeIndex
                                                          ].package_type_item
                                                            .length -
                                                            1
                                                            ? "add-button"
                                                            : "remove-button"
                                                        }
                                                        onClick={() => {
                                                          packageTypeItemIndex >=
                                                          values.package_type[
                                                            packageTypeIndex
                                                          ].package_type_item
                                                            .length -
                                                            1
                                                            ? push()
                                                            : remove(
                                                                packageTypeItemIndex
                                                              );
                                                        }}
                                                      >
                                                        {packageTypeItemIndex >=
                                                        values.package_type[
                                                          packageTypeIndex
                                                        ].package_type_item
                                                          .length -
                                                          1
                                                          ? "Add Package Item"
                                                          : "Remove Package Item"}
                                                      </button>
                                                    </MDBCol>
                                                  </MDBRow>
                                                </MDBRow>
                                              )
                                            )}
                                        </MDBCol>
                                      </MDBRow>
                                    )}
                                  </FieldArray>
                                  {allData.package_pattern ==
                                    "additional service" &&
                                  values.package_type[packageTypeIndex]
                                    .package_type_item_addon ? (
                                    <FieldArray
                                      name={`package_type.${packageTypeIndex}.package_type_item_addon`}
                                    >
                                      {({ insert, remove, push }) => (
                                        <MDBRow className="form-group m-0">
                                          <MDBCol className="col-3 form-label">
                                            <label>Add On Service</label>
                                          </MDBCol>
                                          <MDBCol className="p-0">
                                            {values.package_type[
                                              packageTypeIndex
                                            ].package_type_item_addon.length >
                                              0 &&
                                              values.package_type[
                                                packageTypeIndex
                                              ].package_type_item_addon.map(
                                                (
                                                  tag,
                                                  packageTypeItemAddOnIndex
                                                ) => (
                                                  <MDBRow className="p-0">
                                                    <MDBCol className="col package-type-item">
                                                      <Input
                                                        placeholder="Package Item Name"
                                                        name={`package_type.${packageTypeIndex}.package_type_item_addon.${packageTypeItemAddOnIndex}.add_on_name`}
                                                      />
                                                      {errors &&
                                                      errors["package_type"] &&
                                                      errors["package_type"][
                                                        packageTypeIndex
                                                      ] &&
                                                      errors["package_type"][
                                                        packageTypeIndex
                                                      ][
                                                        "package_type_item_addon"
                                                      ] &&
                                                      errors["package_type"][
                                                        packageTypeIndex
                                                      ][
                                                        "package_type_item_addon"
                                                      ][
                                                        packageTypeItemAddOnIndex
                                                      ] &&
                                                      errors["package_type"][
                                                        packageTypeIndex
                                                      ][
                                                        "package_type_item_addon"
                                                      ][
                                                        packageTypeItemAddOnIndex
                                                      ]["add_on_name"] ? (
                                                        <div className="text-danger text-right no-padding error-message">
                                                          {
                                                            errors[
                                                              "package_type"
                                                            ][packageTypeIndex][
                                                              "package_type_item_addon"
                                                            ][
                                                              packageTypeItemAddOnIndex
                                                            ]["add_on_name"]
                                                          }
                                                        </div>
                                                      ) : null}
                                                      <FieldArray
                                                        name={`package_type.${packageTypeIndex}.package_type_item_addon.${packageTypeItemAddOnIndex}.add_on_item`}
                                                      >
                                                        {({
                                                          insert,
                                                          remove,
                                                          push,
                                                        }) => (
                                                          <MDBRow className="form-group m-0">
                                                            <MDBCol className="col-3 form-label">
                                                              <label>
                                                                Add On Item
                                                              </label>
                                                            </MDBCol>
                                                            <MDBCol className="p-0">
                                                              {values
                                                                .package_type[
                                                                packageTypeIndex
                                                              ]
                                                                .package_type_item_addon[
                                                                packageTypeItemAddOnIndex
                                                              ].add_on_item
                                                                .length > 0 &&
                                                                values.package_type[
                                                                  packageTypeIndex
                                                                ].package_type_item_addon[
                                                                  packageTypeItemAddOnIndex
                                                                ].add_on_item.map(
                                                                  (
                                                                    tag,
                                                                    packageTypeItemAddOnItemIndex
                                                                  ) => (
                                                                    <MDBRow className="p-0">
                                                                      <MDBCol className="col">
                                                                        <Input
                                                                          placeholder="Package Item Name"
                                                                          name={`package_type.${packageTypeIndex}.package_type_item_addon.${packageTypeItemAddOnIndex}.add_on_item.${packageTypeItemAddOnItemIndex}.add_on_item_name`}
                                                                        />
                                                                        {errors &&
                                                                        errors[
                                                                          "package_type"
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ][
                                                                          "add_on_item"
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ][
                                                                          "add_on_item"
                                                                        ][
                                                                          packageTypeItemAddOnItemIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ][
                                                                          "add_on_item"
                                                                        ][
                                                                          packageTypeItemAddOnItemIndex
                                                                        ][
                                                                          "add_on_item_name"
                                                                        ] ? (
                                                                          <div className="text-danger text-right no-padding error-message">
                                                                            {
                                                                              errors[
                                                                                "package_type"
                                                                              ][
                                                                                packageTypeIndex
                                                                              ][
                                                                                "package_type_item_addon"
                                                                              ][
                                                                                packageTypeItemAddOnIndex
                                                                              ][
                                                                                "add_on_item"
                                                                              ][
                                                                                packageTypeItemAddOnItemIndex
                                                                              ][
                                                                                "add_on_item_name"
                                                                              ]
                                                                            }
                                                                          </div>
                                                                        ) : null}
                                                                        <Input
                                                                          placeholder="Package Item Pricing"
                                                                          name={`package_type.${packageTypeIndex}.package_type_item_addon.${packageTypeItemAddOnIndex}.add_on_item.${packageTypeItemAddOnItemIndex}.add_on_item_pricing`}
                                                                        />
                                                                        {errors &&
                                                                        errors[
                                                                          "package_type"
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ][
                                                                          "add_on_item"
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ][
                                                                          "add_on_item"
                                                                        ][
                                                                          packageTypeItemAddOnItemIndex
                                                                        ] &&
                                                                        errors[
                                                                          "package_type"
                                                                        ][
                                                                          packageTypeIndex
                                                                        ][
                                                                          "package_type_item_addon"
                                                                        ][
                                                                          packageTypeItemAddOnIndex
                                                                        ][
                                                                          "add_on_item"
                                                                        ][
                                                                          packageTypeItemAddOnItemIndex
                                                                        ][
                                                                          "add_on_item_pricing"
                                                                        ] ? (
                                                                          <div className="text-danger text-right no-padding error-message">
                                                                            {
                                                                              errors[
                                                                                "package_type"
                                                                              ][
                                                                                packageTypeIndex
                                                                              ][
                                                                                "package_type_item_addon"
                                                                              ][
                                                                                packageTypeItemAddOnIndex
                                                                              ][
                                                                                "add_on_item"
                                                                              ][
                                                                                packageTypeItemAddOnItemIndex
                                                                              ][
                                                                                "add_on_item_pricing"
                                                                              ]
                                                                            }
                                                                          </div>
                                                                        ) : null}
                                                                      </MDBCol>
                                                                      <MDBRow
                                                                        className="m-0"
                                                                        style={{
                                                                          padding:
                                                                            "0em 0.5em",
                                                                        }}
                                                                      >
                                                                        <MDBCol className="p-0 form-group package-item-button">
                                                                          <button
                                                                            type="button"
                                                                            className={
                                                                              packageTypeItemAddOnItemIndex >=
                                                                              values
                                                                                .package_type[
                                                                                packageTypeIndex
                                                                              ]
                                                                                .package_type_item_addon[
                                                                                packageTypeItemAddOnIndex
                                                                              ]
                                                                                .add_on_item
                                                                                .length -
                                                                                1
                                                                                ? "add-button"
                                                                                : "remove-button"
                                                                            }
                                                                            onClick={() => {
                                                                              {
                                                                                packageTypeItemAddOnItemIndex >=
                                                                                values
                                                                                  .package_type[
                                                                                  packageTypeIndex
                                                                                ]
                                                                                  .package_type_item_addon[
                                                                                  packageTypeItemAddOnIndex
                                                                                ]
                                                                                  .add_on_item
                                                                                  .length -
                                                                                  1
                                                                                  ? push()
                                                                                  : remove(
                                                                                      packageTypeItemAddOnItemIndex
                                                                                    );
                                                                              }
                                                                            }}
                                                                          >
                                                                            {packageTypeItemAddOnItemIndex >=
                                                                            values
                                                                              .package_type[
                                                                              packageTypeIndex
                                                                            ]
                                                                              .package_type_item_addon[
                                                                              packageTypeItemAddOnIndex
                                                                            ]
                                                                              .add_on_item
                                                                              .length -
                                                                              1
                                                                              ? "Add Package Add On Item"
                                                                              : "Remove Package Add On Item"}
                                                                          </button>
                                                                        </MDBCol>
                                                                      </MDBRow>
                                                                    </MDBRow>
                                                                  )
                                                                )}
                                                            </MDBCol>
                                                          </MDBRow>
                                                        )}
                                                      </FieldArray>
                                                    </MDBCol>
                                                    <MDBRow
                                                      className="m-0"
                                                      style={{
                                                        padding: "0em 0.5em",
                                                      }}
                                                    >
                                                      <MDBCol className="p-0 form-group package-item-button">
                                                        <button
                                                          type="button"
                                                          className={
                                                            packageTypeItemAddOnIndex >=
                                                            values.package_type[
                                                              packageTypeIndex
                                                            ]
                                                              .package_type_item_addon
                                                              .length -
                                                              1
                                                              ? "add-button"
                                                              : "remove-button"
                                                          }
                                                          onClick={() => {
                                                            {
                                                              packageTypeItemAddOnIndex >=
                                                              values
                                                                .package_type[
                                                                packageTypeIndex
                                                              ]
                                                                .package_type_item_addon
                                                                .length -
                                                                1
                                                                ? push({
                                                                    add_on_name:
                                                                      "",
                                                                    add_on_item:
                                                                      [
                                                                        {
                                                                          add_on_item_name:
                                                                            "",
                                                                          add_on_item_pricing:
                                                                            "",
                                                                        },
                                                                      ],
                                                                  })
                                                                : remove(
                                                                    packageTypeItemAddOnIndex
                                                                  );
                                                            }
                                                          }}
                                                        >
                                                          {packageTypeItemAddOnIndex >=
                                                          values.package_type[
                                                            packageTypeIndex
                                                          ]
                                                            .package_type_item_addon
                                                            .length -
                                                            1
                                                            ? "Add On Service"
                                                            : "Remove Add On Service"}
                                                        </button>
                                                      </MDBCol>
                                                    </MDBRow>
                                                  </MDBRow>
                                                )
                                              )}
                                          </MDBCol>
                                        </MDBRow>
                                      )}
                                    </FieldArray>
                                  ) : (
                                    ""
                                  )}
                                </MDBCol>
                                <MDBRow>
                                  <button
                                    type="button"
                                    className={
                                      packageTypeIndex >=
                                      values.package_type.length - 1
                                        ? "form-group add-button"
                                        : "form-group remove-button"
                                    }
                                    onClick={() => {
                                      packageTypeIndex >=
                                      values.package_type.length - 1
                                        ? allData.package_pattern ==
                                          "additional service"
                                          ? push({
                                              package_type_name: "",
                                              package_type_item: [
                                                {
                                                  package_type_item_name: "",
                                                  package_type_item_pricing: "",
                                                  package_type_item_limit: "",
                                                },
                                              ],
                                              package_type_item_addon: [
                                                {
                                                  add_on_name: "",
                                                  add_on_item: [
                                                    {
                                                      add_on_item_name: "",
                                                      add_on_item_pricing: "",
                                                    },
                                                  ],
                                                },
                                              ],
                                            })
                                          : push({
                                              package_type_name: "",
                                              package_type_item: [
                                                {
                                                  package_type_item_name: "",
                                                  package_type_item_pricing: "",
                                                  package_type_item_limit: "",
                                                },
                                              ],
                                            })
                                        : remove(packageTypeIndex);
                                    }}
                                  >
                                    {packageTypeIndex >=
                                    values.package_type.length - 1
                                      ? "Add Package Type"
                                      : "Remove Package Type"}
                                  </button>
                                </MDBRow>
                              </MDBRow>
                            ))}
                        </MDBCol>
                      </MDBRow>
                    )}
                  </FieldArray>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <Button name={"Submit"} type="submit" isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}
