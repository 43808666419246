import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

//component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";
import InfoModalBox from "./component/info.modal.box";
import Listing from "./component/listing";
import Gif from "../../assets/fonts/loader/loader.gif";

//service
import apiServices from "../../services/api.services";
import useWindowDimensions from "../../components/common/window-dimension";

export default function TicketTransaction() {
  const [loader, setLoader] = useState(false);

  const [openSideBar, setOpenSideBar] = useState(true);
  //data for api
  const [type, setType] = useState("ticket"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("selectedId");
  const [infoModalBoxData, setInfoModalBoxData] = useState({});

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const getListing = async () => {
    const response = await apiServices.summaryReport({
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
      status: "completed",
    });
    if (response) {
      setLoader(false);
      setListingData(response.aaData);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  const getInfoDetail = async () => {
    const response = await apiServices.getPlanningInfo(selectedId);
    if (response) {
      setInfoModalBoxData(response);
    }
  };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    setActionValue(selectedOption);
    setSelectedId(selectedDataRow.id);
    // setInfoModalBoxData(selectedDataRow);
  };

  useEffect(() => {
    if (search === "") {
      // setLoader(true);
    }
    getListing();
  }, [search, length, start]);

  useEffect(() => {
    if (actionValue == "information" && selectedId >= 0) {
      getInfoDetail();
    }
  }, [selectedId]);

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {actionValue == "information" &&
          Object.keys(infoModalBoxData).length > 0 && (
            <InfoModalBox
              show={true}
              data={infoModalBoxData}
              close={() => setActionValue(false)}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              setInfoModalBoxData={setInfoModalBoxData}
            />
          )}
        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <Listing
              length={length}
              start={start}
              // createAble={createAble}
              listingData={listingData}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
              // handleNewModal={handleNewModal}
              actionValue={actionValue}
              // createAble={handleNewModal}
              handleActionChange={handleActionChange}
              // getListing={getListing}
              // setHandleApplyFilter={setHandleApplyFilter}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
