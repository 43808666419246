import { MDBContainer } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";

//component
import BookingCheckOutListing from "./component/booking.checkout";
import UpdateModal from "./component/update.modal";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import apiServices from "../../services/api.services";
import useWindowDimensions from "../../components/common/window-dimension";
import config from "../../config.json";

//socket
import Pusher from "pusher-js";

export default function BookingCheckOut() {
  const [loader, setLoader] = useState(false);

  const role = localStorage.getItem("roles_id");
  const token = localStorage.getItem("token");
  //data for api
  const [type, setType] = useState("checked_out"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("asc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(""); //filter date
  const [endDate, setEndDate] = useState(""); //filter date
  //handle modalbox
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  const [usedId, setUsedId] = useState("");
  const [updateModal, setUpdateModal] = useState("");

  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.getBookingListing({
      type,
      order,
      search,
      startDate,
      endDate,
      length: config.operation_roles.includes(role) ? 99999 : length,
      start,
    });
    if (response) {
      setLoader(false);
      if (config.operation_roles.includes(role)) {
        let newData = [];
        for (var key in response.aaData) {
          if (response.aaData[key]["clean_by"] == null) {
            newData.push(response.aaData[key]);
          } else {
            delete response.aaData[key];
          }
          setListingData(newData);
        }
      } else {
        setLoader(false);

        setListingData(response.aaData);
        setTotalRecords(response.iTotalRecords);
        setDisplayRecords(response.iTotalDisplayRecords);
      }
    }
  };

  useEffect(() => {
    if (search === "") {
      // setLoader(true);
    }
    getListing();
  }, [search, length, handleApplyFilter, start]);

  const [openSideBar, setOpenSideBar] = useState(true);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const updateModalBox = (id) => {
    setUpdateModal(!updateModal);
    setUsedId(id);
  };

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
      cluster: "ap1",
      authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      },
    });

    const channel = pusher.subscribe("presence-check-out-" + role);
    var socketId = null;
    pusher.connection.bind("connected", () => {
      socketId = pusher.connection.socket_id;
    });
    channel.bind("check-out-event", (data) => {
      if (data.status == "success") {
        getListing();
      }
    });

    return () => {
      channel.unbind("check-out-event");
    };
  }, []);

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {updateModal && (
          <UpdateModal
            show={true}
            title="Create Day/Time"
            close={updateModalBox}
            getListing={getListing}
            listingData={listingData}
            usedId={usedId}
          />
        )}
        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />

          <BookingCheckOutListing
            length={length}
            start={start}
            listingData={listingData}
            totalRecords={totalRecords}
            displayRecords={displayRecords}
            setListingData={setListingData}
            setSearch={setSearch}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setOrder={setOrder}
            setLength={setLength}
            setStart={setStart}
            updateModalBox={updateModalBox}
          />
        </div>
      </div>
    </MDBContainer>
  );
}
