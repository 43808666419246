import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import config from "../../config.json";
//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";
import InfoModalBox from "./component/info.modalbox";
import RequestListing from "./component/request.listing";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import apiServices from "../../services/api.services";
import useWindowDimensions from "../../components/common/window-dimension";

export default function RoomServiceOperationOrder() {
  const [loader, setLoader] = useState(false);

  const role = localStorage.getItem("roles_id");
  const username = localStorage.getItem("username");
  const [openSideBar, setOpenSideBar] = useState(true);
  //data for api
  const [type, setType] = useState("active"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(99999); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("selectedId");
  const [infoModalBoxData, setInfoModalBoxData] = useState({});
  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);
  const getListing = async () => {
    const response = await apiServices.getAllListing({
      api_name: "room_service_request",
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
    });
    if (response) {
      setLoader(false);
      if (config.operation_roles.includes(role)) {
        let newData = [];
        for (var key in response.aaData) {
          if (response.aaData[key]["accept_by"] == username) {
            newData.push(response.aaData[key]);
          } else {
            delete response.aaData[key];
          }
          setListingData(newData);
        }
      } else {
        setLoader(false);
        setListingData(response.aaData);
        setTotalRecords(response.iTotalRecords);
        setDisplayRecords(response.iTotalDisplayRecords);
      }
    }
  };

  const getInfoDetail = async () => {
    const response = await apiServices.getServiceRequestInfo(selectedId);
    if (response) {
      setInfoModalBoxData(response);
    }
  };

  useEffect(() => {
    getListing();
    if (search === "") {
      // setLoader(true);
    }
  }, [search, length, handleApplyFilter, start]);

  useEffect(() => {
    if (actionValue == "information" && selectedId >= 0) {
      getInfoDetail();
    }
  }, [selectedId]);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleCloseModal = () => {
    setInfoModalBoxData({});
    setActionValue(false);
  };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    setActionValue(selectedOption);
    setSelectedId(selectedDataRow.id);
  };

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {actionValue == "information" &&
          Object.keys(infoModalBoxData).length > 0 && (
            <InfoModalBox
              show={true}
              title="Information Room Service Order"
              disabled={true}
              getListing={getListing}
              selectedData={infoModalBoxData}
              close={handleCloseModal}
              setSelectedId={setSelectedId}
              getInfoDetail={getInfoDetail}
            />
          )}

        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <RequestListing
              length={length}
              start={start}
              listingData={listingData}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
              // handleNewModal={handleNewModal}
              actionValue={actionValue}
              // createAble={handleNewModal}
              handleActionChange={handleActionChange}
              getListing={getListing}
              setHandleApplyFilter={setHandleApplyFilter}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
