import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";

//component
import UserListing from "./component/listing";
import CreateModalBox from "./component/createmodalbox";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import useWindowDimensions from "../../components/common/window-dimension";
import apiServices from "../../services/api.services";

export default function Banner() {
  const [loader, setLoader] = useState(false);

  const [openSideBar, setOpenSideBar] = useState(true);
  //data for api
  const [type, setType] = useState("user"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("selectedId");
  const [infoModalBoxData, setInfoModalBoxData] = useState({});
  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.bannerSetting();
    if (response) {
      setLoader(false);
      setListingData(response.data);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const createAble = () => {
    setCreateModal(!createModal);
  };

  useEffect(() => {
    // setLoader(true);
    getListing();
  }, [length, start]);

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />

        {createModal && (
          <CreateModalBox
            show={true}
            getListing={getListing}
            title="Create New User"
            close={createAble}
          />
        )}
        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <UserListing
              createAble={createAble}
              listingData={listingData}
              length={length}
              getListing={getListing}
              start={start}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
