import { useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//primereact
import { Image } from "primereact/image";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import InfoTemplate from "../../../components/modal/info.template";
import CreateServiceModalBox from "./create.service.modalbox";
import CreateItemModalBox from "./create.item.modalbox";
import UpdateItemModalBox from "./update.item.modalbox";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import { ConfirmPopup } from "primereact/confirmpopup";

export default function InfoModalBox({
  show,
  close,
  selectedData,
  getInfoDetail,
}) {
  const [createItemModalBox, setCreateItemModalBox] = useState(false);
  const [createServiceModalBox, setCreateServiceModalBox] = useState(false);
  const [updateServiceModalBox, setUpdateServiceModalBox] = useState(false);
  const [updateServiceModalData, setUpdateServiceModalData] = useState({});
  const [updateItemModalBox, setUpdateItemModalBox] = useState(false);
  const [updateItemModalData, setUpdateItemModalData] = useState({});
  const [itemModalData, setitemModalData] = useState("");
  const [serviceModalData, setServiceModalData] = useState("");

  const handleCreateItemModal = (id) => {
    setCreateItemModalBox(!createItemModalBox);
    setitemModalData(id);
  };

  // const handleCreateServiceModal = (id) => {
  //   setCreateServiceModalBox(!createServiceModalBox);
  //   setServiceModalData(id);
  // };

  const handleUpdateItemModal = (data) => {
    setUpdateItemModalBox(!updateItemModalBox);
    setUpdateItemModalData(data);
  };

  return (
    <div>
      <MDBModal staticBackdrop tabIndex="-1" show={show}>
        <MDBModalDialog scrollable size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                Create Service - {selectedData.title}{" "}
                <Icon
                  icon="fluent:add-square-multiple-20-filled"
                  width="25"
                  onClick={() => {
                    setServiceModalData(selectedData.id);
                    setCreateServiceModalBox(true);
                  }}
                />
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => close()}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <ConfirmPopup />

              {selectedData.room_service_category_services.map(
                (item, index) => (
                  <div key={index}>
                    <InfoTemplate
                      close={close}
                      item={item}
                      selectedData={selectedData}
                      getInfoDetail={getInfoDetail}
                      setUpdateServiceModalBox={setUpdateServiceModalBox}
                      setUpdateServiceModalData={setUpdateServiceModalData}
                      handleCreateItemModal={handleCreateItemModal}
                      handleUpdateItemModal={handleUpdateItemModal}
                    />
                  </div>
                )
              )}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {updateItemModalBox && (
        <UpdateItemModalBox
          show={true}
          getInfoDetail={getInfoDetail}
          selectedData={updateItemModalData}
          close={handleUpdateItemModal}
        />
      )}
      {createItemModalBox && (
        <CreateItemModalBox
          show={true}
          getInfoDetail={getInfoDetail}
          selectedData={itemModalData}
          serviceId={selectedData.id}
          close={handleCreateItemModal}
        />
      )}
      {createServiceModalBox && (
        <CreateServiceModalBox
          show={true}
          type={"create"}
          getInfoDetail={getInfoDetail}
          selectedData={serviceModalData}
          close={() => {
            setCreateServiceModalBox(false);
          }}
        />
      )}
      {updateServiceModalBox &&
        Object.keys(updateServiceModalData).length > 0 && (
          <CreateServiceModalBox
            show={true}
            type={"update"}
            getInfoDetail={getInfoDetail}
            selectedData={updateServiceModalData}
            close={() => setUpdateServiceModalBox(false)}
          />
        )}
    </div>
  );
}
