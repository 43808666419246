import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const sweetAlertHelper = (element) => {
  const MySwal = withReactContent(Swal);

  return MySwal.fire({
    ...element,
    customClass: {
      title: "...",
      text: "...",
      icon: "...",
      button: "...",
      color: "...",
    },
  }).then((result) => {});
};

export default {
  sweetAlertHelper,
};
