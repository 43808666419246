import { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Calendar } from "primereact/calendar";
import { ColorPicker } from "primereact/colorpicker";

//element component
import Button from "../../../components/element/button";
import Select from "../../../components/element/select";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import DateRange from "../../../components/element/dateRange";
import DateMultiSelect from "../../../components/element/dateMultiSelect";
import CheckBox from "../../../components/element/checkbox";

export default function CreateFormStep3({
  handleNextStep,
  allData,
  setAllData,
  marchantList,
  disabled,
}) {
  const [dateRange, setDateRange] = useState("");
  const [specificDate, setSpecificDate] = useState("");
  const date_type = [
    {
      label: "Date Range",
      id: "date range",
    },
    {
      label: "Specific Date",
      id: "specific date",
    },
  ];

  const package_type = [
    {
      label: "Simple Type",
      id: "standard",
    },
    {
      label: "Advance Type",
      id: "additional service",
    },
  ];

  const [data, setData] = useState({
    ticket_type: "ticket_package",
    type: allData ? allData.type : "",
    title: "",
    ticket_id: allData ? allData.id : "",
    display_original_price: "35",
    display_current_price: "15",
    method: "POST",
    package_title: "",
    is_activate_require: false,
    advance_booking_day: 0,
    is_select_date_require: false,
    availability_date_type: "",
    package_pattern: "",
    merchant: "",
    tag: [{ text: "", color: "e8e9e9" }],
    tnc: [],
  });

  const useSchema = Yup.object({
    package_title: Yup.string().required("required"),
    title: Yup.string().required("required"),
    availability_date_type: Yup.string().required("required"),
    package_pattern: Yup.string().required("required"),
    merchant: Yup.string().required("required"),
    tnc: Yup.array().min(1, "required").required("required"),
    advance_booking_day: Yup.string(),
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    // setAllData(values);
    // handleNextStep();
    try {
      const response = await apiServices.createTicketForm({
        payload: values,
      });
      if (response) {
        setAllData(response);
        sweetAlertHelper({
          title: "Success",
          text: "Create Ticket Step 3 Success",
          icon: "success",
        });
        handleNextStep();
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={data}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue, errors, isSubmitting }) => (
        <Form className="modal-form">
          <MDBRow>
            <Input name="package_title" label="Package Title" />
            <Input name="title" label="Package Name" />
            <FieldArray name="tag">
              {({ insert, remove, push }) => (
                <MDBRow className="form-group">
                  <MDBCol className="col-12 col-md-3 form-label">
                    <label>TAG for this package</label>
                  </MDBCol>
                  <MDBCol>
                    {values.tag.length > 0 &&
                      values.tag.map((tag, index) => (
                        <MDBRow className="p-0">
                          <MDBCol className="col ">
                            <Input
                              placeholder="Text"
                              name={`tag.${index}.text`}
                            />
                          </MDBCol>
                          <MDBCol className="col d-flex align-items-center">
                            {/* <ColorPicker
                              value={values.tag[index].color}
                              onChange={(e) =>
                                setFieldValue(`tag.${index}.color`, e.value)
                              }
                            ></ColorPicker> */}
                            <Input
                              label="Tag Color"
                              placeholder="Color"
                              name={`tag.${index}.color`}
                              onChange={(e) => {
                                setFieldValue(`tag.${index}.color`, e.value);
                              }}
                            />
                          </MDBCol>
                          <MDBCol className="button-col col-2">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                      ))}
                    <button
                      type="button"
                      className="form-group add-button"
                      onClick={() => push({ text: "", color: "e8e9e9" })}
                    >
                      Add Tag
                    </button>
                  </MDBCol>
                </MDBRow>
              )}
            </FieldArray>
            <Select
              name="availability_date_type"
              label="Package Date Type"
              options={date_type}
              placeholder="- Please Select -"
              setSpecificDate={setSpecificDate}
              setDateRange={setDateRange}
            />
            {values.availability_date_type == "date range" ? (
              <DateRange
                name="range_date"
                label="Range Date"
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            ) : values.availability_date_type == "specific date" ? (
              <DateMultiSelect
                name="specific_date"
                label="Specific Date"
                specificDate={specificDate}
                setSpecificDate={setSpecificDate}
              />
            ) : (
              ""
            )}
            {values.availability_date_type == "date range" ? (
              <>
                {" "}
                <CheckBox name="is_activate_require" label="Advance Booking" />
                {values.is_activate_require == true ? (
                  <Input
                    name="advance_booking_day"
                    type="number"
                    label="No. of Advance Booking day"
                  />
                ) : (
                  ""
                )}
              </>
            ) : values.availability_date_type == "date range" ? (
              <DateMultiSelect
                name="specific_date"
                label="Specific Date"
                specificDate={specificDate}
                setSpecificDate={setSpecificDate}
              />
            ) : (
              ""
            )}
            <FieldArray name="tnc">
              {({ insert, remove, push }) => (
                <MDBRow className="form-group">
                  <MDBCol className="col-12 col-md-3 form-label">
                    <label>Term & Condition</label>
                  </MDBCol>
                  <MDBCol>
                    {values.tnc.length > 0 &&
                      values.tnc.map((tag, index) => (
                        <MDBRow key={index} className="m-0">
                          <MDBCol className="p-0">
                            <Input placeholder="Text" name={`tnc.${index}`} />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                      ))}
                    <button
                      type="button"
                      className="form-group add-button"
                      onClick={() => push()}
                    >
                      Add Term & Condition
                    </button>
                  </MDBCol>
                  {errors && errors["tnc"] ? (
                    <div className="text-danger text-right no-padding error-message">
                      {errors["tnc"]}
                    </div>
                  ) : null}
                </MDBRow>
              )}
            </FieldArray>
            <Select
              name="merchant"
              label="Related Merchant"
              options={marchantList}
            />
            <Select
              name="package_pattern"
              label="Package Pattern"
              options={package_type}
              placeholder="- Please Select -"
            />
          </MDBRow>
          <Button name="Next" type="submit" isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}
