import { useState } from "react";
import moment from "moment";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function AgentListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const actionOptions = ["Wallet Log", "Ticket Log"];
  const [date, setDate] = useState(new Date());

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userStatusToggle({ userId });
      if (response == 200) {
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="Active"
        falseValue="Inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const phoneBodyTemplate = ({ phone }) => {
    if (phone) {
      return (
        <span>
          {phone.countryCode}
          {phone.phoneNumber}
        </span>
      );
    }
  };

  const dateBodyTemplate = ({ lastLoginedAt }) => {
    if (lastLoginedAt) {
      return <span>{moment(lastLoginedAt).format("DD/MM/YYYY")}</span>;
    }
  };

  const actionBodyTemplate = (options) => {
    return (
      <Dropdown
        data={actionValue}
        options={actionOptions}
        optionLabel="name"
        placeholder="Action"
        handleActionChange={(event) => handleActionChange(event, options)}
      />
    );
  };

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(e.value[0]);
          setEndDate(e.value[1]);
          // options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  // inline edit complete function //
  const onRowEditComplete = async (e) => {
    const payload = {
      username: e.newData.username,
      password: e.newData.password,
      role_name: e.newData.role_name,
      method: "PUT",
    };
    try {
      const response = await apiServices.updateListing({
        id: e.newData.id,
        payload: payload,
      });

      if (response === 200) {
        getListing();
      }
    } catch (ex) {}
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    {
      field: "created_at",
      header: "Register Date",
      filterElement: filterDateTemplate,
    },
    { field: "username", header: "Email" },
    {
      field: "status",
      header: "Status",
      body: statusBodyTemplate,
    },
    { field: "gender", header: "Gender" },
    { field: "downline", header: "Referral" },
    { field: "credit_balance", header: "Balance" },
  ];
  // { field: "", rowEditor: true, headerStyle: "1em" },
  // {
  //     field: "created_at",
  //     header: "Register Date",
  //     filterElement: filterDateTemplate,
  //   },
  // { field: "upline", header: "Upline" },

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Agent Listing"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
        onRowEditComplete={onRowEditComplete}
      />
    </div>
  );
}
