import { useState } from "react";
import moment from "moment";
import { MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function AdminListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const [date, setDate] = useState(new Date());

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userStatusToggle({ userId });
      if (response == 200) {
        sweetAlertHelper({
          title: "Success",
          text: "Edit Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="Active"
        falseValue="Inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const phoneBodyTemplate = ({ phone }) => {
    if (phone) {
      return (
        <span>
          {phone.countryCode}
          {phone.phoneNumber}
        </span>
      );
    }
  };

  const actionBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => handleActionChange(options)}
        >
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  // inline edit complete function //
  const onRowEditComplete = async (e) => {
    const payload = {
      username: e.newData.username,
      phone_number: e.newData.phone_number,
      role_name: e.newData.role_name,
      method: "PUT",
    };
    try {
      const response = await apiServices.updateListing({
        id: e.newData.id,
        payload: payload,
      });
      if (response) {
        getListing();
        sweetAlertHelper({
          title: "Success",
          text: "Edit Admin Success",
          icon: "success",
        });
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";

      if (errors && Object.keys(errors).length > 0) {
        Object.keys(errors).map((item, i) => {
          //   setFieldError(item, errors[item]);
          errorMsg = errors[item];
        });

        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(e.value[0]);
          setEndDate(e.value[1]);
          // options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },

    {
      field: "created_at",
      header: "Create At ",
      // filterElement: filterDateTemplate,
    },
    { field: "username", header: "Username" },
    { field: "role_name", header: "Department" },
    {
      field: "status",
      header: "Status",
      body: statusBodyTemplate,
    },
    // { field: "last_login_at", header: "Last Log in" },

    { field: "phone_number", header: "Phone Number" },

    { field: "", header: "Action", body: actionBodyTemplate },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Operation Listing"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
        onRowEditComplete={onRowEditComplete}
      />
    </div>
  );
}
