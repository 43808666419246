import { useDebugValue, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import Select from "../../../components/element/select";

export default function EditModalBox({
  show,
  close,
  getInfoDetail,
  getListing,
  day,
}) {
  const type = [{ label: "peak" }, { label: "superpeak" }];
  const [data, setData] = useState({
    type: day.type ? day.type : "",
    date: day.date ? day.date : "",
    id: day ? day.id : "",
    update_type: "update",
  });
  const useSchema = Yup.object({
    id: Yup.string(),
    type: Yup.string().required("Please select your type"),
    date: Yup.string(),
    update_type: Yup.string().required("Please select your type"),
  });

  const handleSubmit = async (values, setFieldError, type) => {
    try {
      const response = await apiServices.updateDay({
        id: day.id,
        payload: values,
      });
      if (response) {
        close(false);
        getListing();
        sweetAlertHelper({
          title: "Success",
          text: "Update Day Success",
          icon: "success",
        });
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };
  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="create-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Day</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, errors }) => (
                <Form className="modal-form">
                  <Input label="ID" name="id" disabled />
                  <Select
                    name="type"
                    label="Type"
                    options={type}
                    placeholder="- Please Select -"
                    errors={errors}
                  />
                  {/* <Select
                    name="update_type"
                    label="Update"
                    options={updateType}
                    placeholder="- Please Select -"
                    errors={errors}
                  /> */}
                  <Input label="Date" name="date" type="date" />
                  <Button label="submit" name="Submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
