import { MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Field, ErrorMessage } from "formik";

export default function Button({
  type,
  label,
  name,
  isSubmitting,
  handleSubmit,
  errors,
  disabled,
  placeholder,
  classNameButton,
}) {
  return (
    <MDBRow className="form-group">
      {label && (
        <MDBCol className="col-12 col-md-3 form-label">
          <label htmlFor={name}>{label}</label>
        </MDBCol>
      )}
      <MDBCol className={classNameButton ? classNameButton : "text-right"}>
        <MDBBtn type={type} className="submit-button" disabled={isSubmitting}>
          {name}
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  );
}
