import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function SubSideBar({ item, isOpen }) {
  const { pathname } = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const role = localStorage.getItem("roles");
  let subMenuActive = false;

  useEffect(() => {
    if (item.group) {
      for (var i = 0; i < item.group.length; i++) {
        if (item.group[i].path == pathname) {
          setSubMenuOpen(true);
          break;
        }
      }
      if (item.path == pathname) {
        setSubMenuOpen(true);
      }
    }
  }, [item.group]);

  useEffect(() => {
    if (isOpen == false) {
      setSubMenuOpen(false);
    }
  }, [isOpen]);

  return (
    <div
      className={
        subMenuOpen
          ? isOpen
            ? "menu-item active"
            : "menu-item isOpen-active"
          : pathname == item.path
          ? "menu-item active"
          : "menu-item"
      }
    >
      <Link to={item.path} className="link" activeclassname="active">
        <MDBRow className="item-row">
          <MDBCol className="col-2 p-0">
            <Icon icon={item.icon} width="30" height="28" />
          </MDBCol>
          <div
            style={{ display: isOpen ? "block" : "none" }}
            className="link_text"
            onClick={() => (item.group ? setSubMenuOpen(!subMenuOpen) : {})}
          >
            {item.name}
          </div>
        </MDBRow>
      </Link>

      {item.group ? (
        <div
          className={"sub-menu"}
          style={{
            display: subMenuOpen ? "block" : "none",
          }}
        >
          {item.group.map((sub_menu, index) => (
            <Link
              id="RouterNavLink"
              to={sub_menu.path}
              key={index}
              className="link"
              activeclassname="active"
            >
              <MDBRow
                className={
                  pathname == sub_menu.path
                    ? "sub-menu-row active m-0"
                    : "sub-menu-row m-0"
                }
              >
                <MDBCol className="col-2 p-0">
                  <Icon icon={sub_menu.icon} width="30" height="28" />
                </MDBCol>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {sub_menu.name}
                </div>
              </MDBRow>
            </Link>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
