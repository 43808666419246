import { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { ColorPicker } from "primereact/colorpicker";
import { Rating } from "primereact/rating";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Calendar } from "primereact/calendar";

//element component
import Button from "../../../components/element/button";
import Select from "../../../components/element/select";
import Input from "../../../components/element/input";
import FileUpload from "../../../components/element/file";
import DateRange from "../../../components/element/dateRange";
import DateMultiSelect from "../../../components/element/dateMultiSelect";
import DatePicker from "react-multi-date-picker";

//services
import apiServices from "../../../services/api.services";
import moment from "moment";

export default function CreateFormStep2({
  handleNextStep,
  marchantList,
  setPackageType,
  ticketType,
  setAllData,
  disabled,
}) {
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const [dateRange, setDateRange] = useState("");
  const [specificDate, setSpecificDate] = useState("");
  const date_type = [
    {
      label: "Date Range",
      id: "date range",
    },
    {
      label: "Specific Date",
      id: "specific date",
    },
  ];

  const [data, setData] = useState({
    method: "POST",
    ticket_type: ticketType ? ticketType.ticket_type : "",
    type: ticketType ? ticketType.type : "",
    title: "",
    image: null,
    open_time: ticketType.type == "Promotion" ? "9.00am" : "",
    close_time: ticketType.type === "Promotion" ? "10.00pm" : "",
    rate: ticketType.type == "Activity" || ticketType == "Place" ? "" : 0,
    availability_date_type: "date range",
    detail: [],
    description: [],
    tag: [{ text: "", color: "e8e9e9" }],
    display_current_price:
      ticketType.type === "Promotion" || ticketType.type === "Activity"
        ? ""
        : "100",
    display_original_price:
      ticketType.type === "Promotion" || ticketType.type === "Activity"
        ? ""
        : "100",
    expectation: [],
    distance: ticketType.type == "Place" ? "" : 2,
    history: "",
    content: "",
  });

  const useSchema = Yup.object({
    title: Yup.string().required("required"),
    image: Yup.mixed().required("required"),
    // open_time: Yup.string().required("required"),
    // close_time: Yup.string().required("required"),
    rate: Yup.string().required("required"),
    availability_date_type: Yup.string().required("required"),
    description: Yup.array().min(1, "required").required("required"),
    display_current_price: Yup.string().required("required"),
    display_original_price: Yup.string().required("required"),
  });

  const facility = [
    {
      value: "tabler:speakerphone",
    },
    {
      value: "icon-park-outline:parking",
    },
  ];

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    const formData = new FormData();
    for (let key in values) {
      if (key == "description") {
        values.description.map((item) => {
          if (item == "" || item == null) {
          } else {
            formData.append("description[]", item);
          }
        });
      } else if (key == "rate") {
        formData.append("properties[0][rating][0][extreme]", values[key]);
      }
      // else if (key == "rate" && ticketType == "Place") {
      //   formData.append("properties[0][rating][0][google_review]", values[key]);
      // }
      else if (key == "tnc") {
        values.tnc.map((item) => formData.append("tnc[]", item));
      } else if (key == "availability_date") {
        values.availability_date.map((item) =>
          formData.append("availability_date[]", item)
        );
      } else if (key == "tag") {
        values.tag.map((item, index) => {
          if (item.text == "" || item.text == null) {
          } else {
            formData.append(`tag[${index}][text]`, item.text);
            formData.append(`tag[${index}][color]`, item.color);
          }
        });
        // values.tag.map((item, index) => {
        //   if (item.color == "" || item.color == null) {
        //   } else {
        //   }
        // });
      } else if (key == "expectation") {
        values.expectation.map((item, index) => {
          for (var key in item) {
            if (item[key] == "" || item[key] == null) {
            } else {
              if (key == "text") {
                formData.append(`expectation[${index}][text]`, item.text);
              } else if (key == "image") {
                formData.append(`expectation_image[]`, item.image);
              }
            }
          }
        });
      } else if (key == "history") {
        if (values.history.length > 0) {
          values.history.map((item) => {
            formData.append(`history[]`, values.history);
          });
        } else {
        }
      } else if (key == "detail") {
        values.detail.map((item, index) => {
          for (var key in item) {
            if (key == "title") {
              formData.append(`detail[${index}][title]`, item.title);
            }
            if (key == "text") {
              formData.append(`detail[${index}][text]`, item.text);
            }
            if (key == "icon") {
              formData.append(`detail[${index}][icon]`, item.icon);
            }
          }
        });
      } else if (key == "availability_end_date") {
        formData.append(
          key,
          moment(new Date(values[key])).format("YYYY-MM-DD hh:mm")
        );
      } else {
        formData.append(key, values[key]);
      }
    }
    try {
      const response = await apiServices.createTicketForm({
        payload: formData,
      });
      if (response) {
        setAllData(response);
        sweetAlertHelper({
          title: "Success",
          text: "Create Ticket Step 2 Success",
          icon: "success",
        });
        handleNextStep();
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={data}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue, errors, isSubmitting }) => (
        <Form className="modal-form">
          <Input name="title" label="(Title) Ticket" />
          <FileUpload label="Display Image" name="image" />
          <FieldArray name="detail">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>(Detail) Information</label>
                </MDBCol>
                <MDBCol>
                  {values.detail.length > 0 &&
                    values.detail.map((tag, index) => (
                      <MDBRow className="p-0" key={index}>
                        <MDBRow className="m-0">
                          <MDBCol className="p-0">
                            <MDBCol className="icon-container">
                              {facility.map((item) => (
                                <label>
                                  <Field
                                    type="radio"
                                    name={`detail.${index}.icon`}
                                    value={item.value}
                                  />
                                  <Icon icon={item.value} width="25" />
                                </label>
                              ))}
                            </MDBCol>
                            <Input
                              placeholder="Title"
                              name={`detail.${index}.title`}
                            />
                            <Input
                              placeholder="text"
                              name={`detail.${index}.text`}
                            />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push()}
                  >
                    Add Detail
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <FieldArray name="description">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>(Detail) Description</label>
                </MDBCol>
                <MDBCol>
                  {values.description.length > 0 &&
                    values.description.map((tag, index) => (
                      <MDBRow key={index} className="m-0">
                        <MDBCol className="p-0">
                          <Input
                            // placeholder="Title"
                            name={`description[${index}]`}
                          />
                        </MDBCol>
                        <MDBCol className="col-1 button-col">
                          <button
                            type="button"
                            className="form-group remove-button"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </MDBCol>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push()}
                  >
                    Add Description
                  </button>
                </MDBCol>
                {errors && errors["description"] ? (
                  <div className="text-danger text-right no-padding error-message">
                    {errors["description"]}
                  </div>
                ) : null}
              </MDBRow>
            )}
          </FieldArray>
          {/* {errors && errors["description"] ? (
            <div className="text-danger text-right no-padding error-message">
              {errors["description"]}
            </div>
          ) : null} */}
          {ticketType.type === "place" ? (
            <>
              <FieldArray name="history">
                {({ insert, remove, push }) => (
                  <MDBRow className="form-group">
                    <MDBCol className="col-12 col-md-3 form-label">
                      <label>(Detail) History</label>
                    </MDBCol>
                    <MDBCol>
                      {values.history.length > 0 &&
                        values.history.map((tag, index) => (
                          <MDBRow key={index} className="m-0">
                            <MDBCol className="p-0">
                              <Input
                                placeholder="Title"
                                name={`history.${index}`}
                              />
                            </MDBCol>
                            <MDBCol className="col-1 button-col">
                              <button
                                type="button"
                                className="form-group remove-button"
                                onClick={() => remove(index)}
                              >
                                X
                              </button>
                            </MDBCol>
                          </MDBRow>
                        ))}
                      <button
                        type="button"
                        className="form-group add-button"
                        onClick={() => push()}
                      >
                        Add History
                      </button>
                    </MDBCol>
                  </MDBRow>
                )}
              </FieldArray>
            </>
          ) : (
            ""
          )}
          {ticketType.type === "event" ? (
            <>
              {/* <Input name="open_time" label="Operation Start" /> */}
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Operation Start</label>
                </MDBCol>
                <MDBCol>
                  <DatePicker
                    disableDayPicker
                    format="hh:mm A"
                    plugins={[<TimePicker hideSeconds />]}
                    onChange={(date) => {
                      setFieldValue("open_time", date);
                    }}
                  />
                </MDBCol>
                {errors && errors["open_time"] ? (
                  <div className="text-danger text-right no-padding error-message">
                    {errors["open_time"]}
                  </div>
                ) : null}
              </MDBRow>
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Operation Close</label>
                </MDBCol>
                <MDBCol>
                  <DatePicker
                    disableDayPicker
                    format="hh:mm A"
                    plugins={[<TimePicker hideSeconds />]}
                    onChange={(date) => {
                      setFieldValue("close_time", date);
                    }}
                  />
                  {errors && errors["close_time"] ? (
                    <div className="text-danger text-right no-padding error-message">
                      {errors["close_time"]}
                    </div>
                  ) : null}
                </MDBCol>
              </MDBRow>
              {/* <Input name="close_time" label="Operation Close" /> */}
              <Input name="content" label="(Listing) Display Content" />
            </>
          ) : (
            ""
          )}
          {ticketType.type == "activity" || ticketType.type == "place" ? (
            <>
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Operation Start</label>
                </MDBCol>
                <MDBCol>
                  <DatePicker
                    disableDayPicker
                    format="hh:mm A"
                    plugins={[<TimePicker hideSeconds />]}
                    onChange={(date) => {
                      setFieldValue("open_time", date);
                    }}
                  />
                  {errors && errors["open_time"] ? (
                    <div className="text-danger text-right no-padding error-message">
                      {errors["open_time"]}
                    </div>
                  ) : null}
                </MDBCol>
              </MDBRow>
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Operation Close</label>
                </MDBCol>
                <MDBCol>
                  <DatePicker
                    disableDayPicker
                    format="hh:mm A"
                    plugins={[<TimePicker hideSeconds />]}
                    onChange={(date) => {
                      setFieldValue(
                        "close_time",
                        date
                        // moment(date).format("hh:mm A")
                      );
                    }}
                  />
                  {errors && errors["close_time"] ? (
                    <div className="text-danger text-right no-padding error-message">
                      {errors["close_time"]}
                    </div>
                  ) : null}
                </MDBCol>
              </MDBRow>
            </>
          ) : (
            ""
          )}
          <FieldArray name="tag">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>TAG for this ticket</label>
                </MDBCol>
                <MDBCol>
                  {values.tag.length > 0 &&
                    values.tag.map((tag, index) => (
                      <MDBRow className="p-0" key={index}>
                        <MDBCol className="col">
                          <Input
                            placeholder="Text"
                            name={`tag.${index}.text`}
                          />
                        </MDBCol>
                        <MDBCol className="d-flex align-items-center">
                          {/* <ColorPicker
                            value={values.tag[index].color}
                            onChange={(e) =>
                              setFieldValue(`tag.${index}.color`, e.value)
                            }
                          ></ColorPicker> */}
                          <Input
                            label="Tag Color"
                            placeholder="Color"
                            name={`tag.${index}.color`}
                            onChange={(e) => {
                              setFieldValue(`tag.${index}.color`, e.value);
                            }}
                          />
                        </MDBCol>
                        <MDBCol className="button-col col-2">
                          <button
                            type="button"
                            className="form-group remove-button"
                            onClick={() => remove(index)}
                          >
                            X
                          </button>
                        </MDBCol>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() => push({ text: "", color: "e8e9e9" })}
                  >
                    Add Tag
                  </button>
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          {ticketType.type === "promotion" ? (
            <>
              <Input name="content" label="(Listing) Display Content" />

              <Input
                name="display_original_price"
                label="Display Original Price"
              />
              <Input
                name="display_current_price"
                label="Display Current Price"
              />
            </>
          ) : ticketType.type === "activity" ? (
            <>
              <Input
                name="display_original_price"
                label="Display Original Price"
              />
              <Input
                name="display_current_price"
                label="Display Current Price"
              />
            </>
          ) : (
            ""
          )}
          {ticketType.type == "activity" ? (
            // <Input name="rate" label="Display Rating" />
            <MDBRow className="form-group">
              <MDBCol className="col-12 col-md-3 form-label">
                <label>Display Rating</label>
              </MDBCol>
              <MDBCol>
                <Rating
                  value={values.rate}
                  cancel={false}
                  onChange={(e) => setFieldValue("rate", e.value)}
                />
              </MDBCol>
            </MDBRow>
          ) : (
            ""
          )}
          {ticketType.type == "place" ? (
            <>
              {/* <Input name="rate" label="Display Rating" /> */}
              <Input name="content" label="(Listing) Display Content" />

              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>Display Rating</label>
                </MDBCol>
                <MDBCol>
                  <Rating
                    value={values.rate}
                    cancel={false}
                    onChange={(e) => setFieldValue("rate", e.value)}
                  />
                </MDBCol>
              </MDBRow>
              <Input name="distance" label="Distance far from morib" />
            </>
          ) : (
            ""
          )}
          <FieldArray name="expectation">
            {({ insert, remove, push }) => (
              <MDBRow className="form-group">
                <MDBCol className="col-12 col-md-3 form-label">
                  <label>What to Expect</label>
                </MDBCol>
                <MDBCol>
                  {values.expectation.length > 0 &&
                    values.expectation.map((tag, index) => (
                      <MDBRow className="p-0" key={index}>
                        <MDBRow className="m-0">
                          <MDBCol className="p-0">
                            <Input
                              placeholder="Title"
                              name={`expectation[${index}].text`}
                            />
                          </MDBCol>
                          <MDBCol className="col-1 button-col">
                            <button
                              type="button"
                              className="form-group remove-button"
                              onClick={() => remove(index)}
                            >
                              X
                            </button>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="m-0 p-0">
                          <MDBCol>
                            <FileUpload
                              name={`expectation[${index}].image`}
                              index={index}
                            />
                            {errors &&
                            errors["expectation_image"] &&
                            errors["expectation_image"][index] ? (
                              <div className="text-danger text-right no-padding error-message">
                                {errors["expectation_image"]}
                              </div>
                            ) : null}
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  <button
                    type="button"
                    className="form-group add-button"
                    onClick={() =>
                      push({
                        text: "",
                        image: "",
                      })
                    }
                  >
                    Add Expectation
                  </button>
                  {errors && errors["expectation"] ? (
                    <div className="text-danger text-right no-padding error-message">
                      {errors["expectation"]}
                    </div>
                  ) : null}
                </MDBCol>
              </MDBRow>
            )}
          </FieldArray>
          <MDBRow className="form-group">
            <MDBCol className="col-12 col-md-3 form-label">
              <label>Ticket Auto Close At </label>
            </MDBCol>
            <MDBCol>
              <DatePicker
                value={values.availability_end_date}
                minDate={new Date()}
                onChange={(date) => {
                  setFieldValue(
                    "availability_start_date",
                    moment(new Date()).format("YYYY-MM-DD hh:mm")
                  );
                  setFieldValue("availability_end_date", date);
                }}
              />
            </MDBCol>
            {errors && errors["availability_date_type"] ? (
              <div className="text-danger text-right no-padding error-message">
                required
              </div>
            ) : null}
          </MDBRow>
          <Button name="Next" type="submit" isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}
