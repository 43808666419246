import { useState, useEffect } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ref } from "yup";

//element component
import Button from "../../../components/element/button";
import Input from "../../../components/element/input";
import Select from "../../../components/element/select";
import apiServices from "../../../services/api.services";

export default function CreateModalBox({
  title,
  show,
  values,
  close,
  getListing,
  disabled,
  type,
}) {
  const [data, setData] = useState({
    username: "",
    phone_number: "",
    name: "",
    last_name: "",
    roles_id: "14",
    password: "",
    confirm_password: "",
    company_name: "",
  });

  const useSchema = Yup.object({
    username: Yup.string().required("Please enter your username"),
    phone_number: Yup.string()
      .required("required")
      .min(10, "Please enter more than or equal to 10 character")
      .max(13, "Must be exactly 13 digits")
      .matches(/^([6]{1}|\+?[0]{1})([0-9]{9,11})$/g, "Must be phone format"),
    roles_id: Yup.string().required("Please enter your roles id"),
    password: Yup.string().required("Please enter your password"),
    confirm_password: Yup.string()
      .required("Please confirm your password")
      .oneOf([ref("password")], "Passwords do not match"),
    name: Yup.string().required("required"),
    last_name: Yup.string().required("required"),
  });

  const handleSubmit = async (values, setFieldError, type) => {
    try {
      const response = await apiServices.createUser({
        type: "other",
        payload: values,
      });
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Create Success",
          icon: "success",
        });
        close(false);
        getListing();
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  const [roleList, setRoleList] = useState([]);

  const getRoleListing = async () => {
    const response = await apiServices.getRoleListing();
    if (response) {
      setRoleList(response);
    }
  };

  useEffect(() => {
    getRoleListing();
  }, []);

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close((prevState) => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, errors }) => (
                <Form className="modal-form">
                  <Input label="User Name" name="username" />
                  <Input label="First Name" name="name" />
                  <Input label="Last Name" name="last_name" />
                  <Input label="Phone Number" name="phone_number" />
                  <Input label="Company Name" name="company_name" />
                  <Input label="Password" name="password" type="password" />
                  <Input
                    label="Confirm Password"
                    name="confirm_password"
                    type="password"
                  />
                  <Button name="Submit" type="submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
