//component
import ListingTable from "../../../components/modal/listingTable";
import { Icon } from "@iconify/react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Listing({
  length,
  start,
  listingData,
  totalRecords,
  displayRecords,
  setOrder,
  setLength,
  setStart,
  setSearch,
  actionValue,
  handleActionChange,
}) {
  const labelBodyTemplate = ({ title }) => {
    return (
      <MDBCol>
        <div className="template-group label-listing">{title}</div>
      </MDBCol>
    );
  };
  const badgeBodyTemplate = (rowData) => {
    let status = "";
    if (rowData.ticket_status == "waiting for payment") {
      status = "W.Payment";
    } else if (rowData.ticket_status == "pending") {
      status = "pending";
    } else if (rowData.ticket_status == "pending") {
      status = "pending";
    } else if (rowData.ticket_status == "use now") {
      status = "Use Now";
    } else if (rowData.ticket_status == "waiting to use ") {
      status = "Reserved";
    } else if (rowData.ticket_status == "used") {
      status = "Used";
    } else if (rowData.ticket_status == "expired") {
      status = "Expired";
    }
    return <span className={`custom-badge status-${status}`}>{status}</span>;
  };
  const actionBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => {
            handleActionChange("information", options);
          }}
        >
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };
  {
  }
  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "id", header: "Order ID" },
    { field: "transaction_id", header: "Transaction ID" },
    { field: "booking_id", header: "Booking ID" },
    { field: "ticket_type", header: "Ticket Type" },
    {
      field: "ticket_name",
      header: "Ticket Name",
      headerStyle: "5em",
      // body: labelBodyTemplate,
    },
    { field: "created_at", header: "Order Date & Time" },
    {
      field: "selected_at",
      header: "Participation Date",
      //   filterElement: filterDateTemplate,
    },
    {
      field: "ticket_status",
      header: "Claim Progress",
      body: badgeBodyTemplate,
      //   filterElement: filterDateTemplate,
    },
    // { field: "marchant_id", header: "Sales Merchant" },
    {
      field: "",
      header: "Action",
      body: actionBodyTemplate,
      headerStyle: "1em",
    },
  ];

  return (
    <div className="listing-container">
      <ListingTable
        title="Claimed Listing"
        data={listingData}
        columns={columns}
        setSearchValue={setSearch}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
