//component
import ListingTable from "../../../components/modal/listingTable";
import { Icon } from "@iconify/react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Listing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const labelBodyTemplate = ({ title }) => {
    return (
      <MDBCol>
        <div className="template-group label-listing">{title}</div>
      </MDBCol>
    );
  };

  const badgeBodyTemplate = (listingData) => {
    return <span>{parseFloat(listingData.amount).toFixed(2)}</span>;
  };

  {
  }
  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "no", header: "No " },
    { field: "paid_at", header: "Paid on " },
    { field: "reference_no", header: "Booking ID" },
    {
      field: "username",
      header: "Username",
    },
    ,
    { field: "payment_name", header: "Payment By" },
    { field: "amount", header: "Amount", body: badgeBodyTemplate },
  ];

  return (
    <div className="listing-container">
      <ListingTable
        title="Ticket Transaction"
        data={listingData}
        columns={columns}
        setSearchValue={setSearch}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
