import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";

//component
import GuestListing from "./component/listing";
import CreateModalBox from "./component/create.modalbox";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import useWindowDimensions from "../../components/common/window-dimension";
import apiServices from "../../services/api.services";

export default function Guest() {
  const [loader, setLoader] = useState(false);

  const [openSideBar, setOpenSideBar] = useState(true);
  //data for api
  const [type, setType] = useState(""); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("selectedId");
  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.getGuestListing({
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
    });
    if (response) {
      setLoader(false);
      setListingData(response.aaData);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  //   const getInfoDetail = async () => {
  //     const response = await apiServices.getUserInfo(selectedId);
  //     if (response) {
  //       setInfoModalBoxData(response);
  //     }
  //   };

  useEffect(() => {
    getListing();
    if (search === "") {
      // setLoader(true);
    }
  }, [search, length, handleApplyFilter, start]);

  //   useEffect(() => {
  //     if (selectedId >= 0) {
  //       getInfoDetail();
  //     }
  //   }, [selectedId]);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  //   const handleCloseModal = () => {
  //     setInfoModalBoxData({});
  //     setActionValue(false);
  //     setSelectedId("selectedId");
  //   };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    setActionValue(selectedOption);
    setSelectedId(selectedDataRow.id);
  };

  const createAble = () => {
    setCreateModal(!createModal);
  };

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {createModal && (
          <CreateModalBox
            show={true}
            getListing={getListing}
            title="Create Guest"
            close={createAble}
          />
        )}
        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <GuestListing
              length={length}
              start={start}
              createAble={createAble}
              listingData={listingData}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
              actionValue={actionValue}
              handleActionChange={handleActionChange}
              getListing={getListing}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
