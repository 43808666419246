import { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
//primereact
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import EditModalForm1 from "./edit.form.step1";
import EditModalForm2 from "./edit.form.step2";
import EditModalForm3 from "./edit.form.step3";
import EditModalForm4 from "./edit.form.setp4";
import { useRef } from "react";

export default function EditModalBox({
  show,
  close,
  getInfoDetail,
  selectedData,
  getListing,
  selectedId,
}) {
  const toast = useRef(null);
  const [step, setStep] = useState(0);
  const [allData, setAllData] = useState({});
  const [marchantList, setMarchantList] = useState("");
  const [packageType, setPackageType] = useState("additional service");

  const items = [
    {
      label: "Ticket Type",
      command: (event) => {
        setStep(0);
      },
    },
    {
      label: "Ticket Details",
      command: (event) => {
        setStep(1);
      },
    },
    {
      label: "Package & Term",
      command: (event) => {
        selectedData.is_ticket_package_valid
          ? setStep(2)
          : toast.current.show({
              severity: "info",
              summary: "Save step 2 to coutinue",
              detail: event.item.label,
            });
      },
    },
    {
      label: "Package type",
      command: (event) => {
        selectedData.is_ticket_package_type_valid
          ? setStep(3)
          : toast.current.show({
              severity: "info",
              summary: `Save step 
              ${selectedData.is_ticket_package_valid ? "2" : "3"} to coutinue`,
              detail: event.item.label,
            });
      },
    },
  ];

  const getMarchantList = async () => {
    const response = await apiServices.getAllListing({
      api_name: "user",
      type: "merchant",
      search: "",
      order: "desc",
      startDate: "",
      endDate: "",
      length: "9999",
      start: "0",
      columns: "",
    });
    if (response) {
      setMarchantList(response.aaData);
    }
  };
  useEffect(() => {
    getMarchantList();
  }, []);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="create-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Edit Ticket</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                close();
                getListing();
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer breakpoint="none" className="">
              <Steps model={items} activeIndex={step} readOnly={false} />
            </MDBContainer>
            {step == 0 ? (
              <EditModalForm1
                selectedData={selectedData}
                marchantList={marchantList}
                getListing={getListing}
                selectedId={selectedId}
                handleNextStep={handleNextStep}
              />
            ) : step == 1 ? (
              <EditModalForm2
                selectedData={selectedData}
                marchantList={marchantList}
                setPackageType={setPackageType}
                getListing={getListing}
                getInfoDetail={getInfoDetail}
                selectedId={selectedId}
                handleNextStep={handleNextStep}
              />
            ) : step == 2 ? (
              <EditModalForm3
                selectedData={selectedData}
                marchantList={marchantList}
                getListing={getListing}
                selectedId={selectedId}
                getInfoDetail={getInfoDetail}
                setAllData={setAllData}
                handleNextStep={handleNextStep}
              />
            ) : step == 3 ? (
              <EditModalForm4
                allData={allData}
                selectedData={selectedData}
                getListing={getListing}
                selectedId={selectedId}
                handleNextStep={handleNextStep}
                packageType={packageType}
                close={close}
              />
            ) : (
              ""
            )}
            <Toast ref={toast}></Toast>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
