//primereact
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import {
  MDBCard,
  MDBContainer,
  MDBNavbar,
  MDBRow,
  MDBCol,
  MDBCardBody,
} from "mdb-react-ui-kit";
import config from "../../config.json";

export default function ToolbarTable({
  exportCSV,
  filtersKey,
  value,
  onGlobalFilterChange,
  createAble,
  selectedListing,
  bookingList,
  changeDate,
  userData,
  dateLabel,
  user,
}) {
  const role = localStorage.getItem("roles_id");
  const leftToolbarTemplate = () => {
    return (
      <div>
        {createAble && (
          <Button
            label={user ? "Create New User" : "Create"}
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => createAble()}
          />
        )}
        {!config.operation_roles.includes(role) && (
          <Button
            label="CSV"
            className="p-button-secondary"
            onClick={() => {
              selectedListing && selectedListing.length > 0
                ? exportCSV(true)
                : exportCSV(false);
            }}
          />
        )}
        {bookingList && (
          <Button
            label={dateLabel}
            className="p-button-date"
            onClick={() => changeDate()}
          />
        )}
        {bookingList && (
          <>
            <MDBRow>
              <MDBCol className={"card col-5"}>
                <MDBRow>
                  <label className="title">
                    Total Expected
                    <br />
                    Incoming Rooms Today
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="number">
                    {userData.no_today_check_in_people}
                  </label>
                </MDBRow>
              </MDBCol>

              <MDBCol className={"card col-5"}>
                <MDBRow>
                  <label className="title">
                    Expected Remainning Check Out Today
                  </label>
                </MDBRow>
                <MDBRow>
                  <label className="number">
                    {" "}
                    {userData.no_today_check_out_people}
                  </label>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </>
        )}

        {/* <Button label="Excel" className="p-button-secondary"  onClick={() => {}} /> */}
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div>
        <span className="p-input-icon-left">
          {!config.operation_roles.includes(role) && (
            <>
              <i
                className={bookingList ? "pi pi-search after" : "pi pi-search"}
              />
              <InputText
                type="search"
                value={value || ""}
                onChange={(e) => onGlobalFilterChange(e, filtersKey)}
                placeholder="Search..."
              />
            </>
          )}
          {bookingList && (
            <MDBRow>
              <MDBCol className={"card col-5"}>
                <MDBRow>
                  <label className="title">Arrived Today</label>
                </MDBRow>
                <MDBRow>
                  <label className="number">
                    {userData.no_today_checked_in_people}
                  </label>
                </MDBRow>
              </MDBCol>

              <MDBCol className={"card col-5"}>
                <MDBRow>
                  <label className="title">Check Out Today</label>
                </MDBRow>
                <MDBRow>
                  <label className="number">
                    {userData.no_today_checked_out_people}
                  </label>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          )}
        </span>
      </div>
    );
  };

  return (
    <div className="pb-2 pl-0 m-0">
      <Toolbar
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
    </div>
  );
}
