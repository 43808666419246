import { useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTextArea,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Image } from "primereact/image";

//element component
import Button from "../../../components/element/button";
import InfoTemplate from "../../../components/modal/info.template";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function CreateServiceModalBox({
  show,
  type,
  close,
  selectedData,
  getInfoDetail,
}) {
  const [data, setData] = useState({
    title: selectedData.title ? selectedData.title : "",
    tnc: selectedData.tnc ? selectedData.tnc : "",
  });

  const useSchema = Yup.object({
    title: Yup.string().required("required"),
    tnc: Yup.string().required("required"),
  });

  const handleSubmit = async (values, setFieldError) => {
    try {
      if (type == "update") {
        const payload = {
          type: "room_service_category_services",
          id: selectedData.id,
          title: values.title,
          tnc: values.tnc,
        };

        const response = await apiServices.updateServiceCategory({
          api_name: "room_service_category",
          id: selectedData.id,
          payload: payload,
        });
        if (response) {
          close();
          getInfoDetail();
          sweetAlertHelper({
            title: "Success",
            text: "Edit Category Success",
            icon: "success",
          });
        }
      } else {
        const payload = {
          type: "room_service_category_services",
          id: selectedData,
          title: values.title,
          tnc: values.tnc,
        };
        const response = await apiServices.createService({
          api_name: "room_service_category",
          payload: payload,
        });
        if (response) {
          close();
          getInfoDetail();
          sweetAlertHelper({
            title: "Success",
            text: "Add Service Success",
            icon: "success",
          });
        }
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="small-modal" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>
              {type == "update"
                ? "Update Service Information"
                : "Create Service Information"}
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, values, errors, setFieldValue }) => (
                <Form className="modal-form">
                  <Input name="title" label="Title" />
                  <MDBRow className="form-group">
                    <MDBCol className="col-12 col-md-3 form-label">
                      <label>Term & Condition</label>
                    </MDBCol>
                    <MDBCol>
                      <MDBTextArea
                        id="tnc"
                        rows={4}
                        value={values.tnc}
                        onChange={(e) => {
                          setFieldValue("tnc", e.target.value);
                        }}
                      />
                      {errors && errors["tnc"] ? (
                        <div className="text-danger text-right no-padding error-message">
                          {errors["tnc"]}
                        </div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>
                  <Button name="submit" type="submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
