import { useState } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import { MDBRadio, MDBRow } from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Dropdown } from "primereact/dropdown";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";

export default function CreateFormStep2({
  handleNextStep,
  close,
  getListing,
  roomId,
}) {
  const [room, setRoom] = useState("");
  const [data, setData] = useState({
    step: 2,
    method: "PUT",
    original_price: "",
    current_price: "",
    room_costing: "",
    peak_price: "",
    nonpeak_price: "",
    superpeak_price: "",
    quantity: "",

    // agent_max_commission_payout_price_type_0: "",
    // agent_max_commission_payout_price_type_1: "",
    // agent_max_commission_payout_price_type_2: "",
    // agent_max_commission_payout_amount_0: "",
    // agent_max_commission_payout_amount_1: "",
    // agent_max_commission_payout_amount_2: "",
  });

  const useSchema = Yup.object({
    name: Yup.string(),
    current_price: Yup.string(),
    nonpeak_price: Yup.string(),
    peak_price: Yup.string(),
    supsuperpeak_priceer_peak_session: Yup.string(),
  });

  const handleSubmit = async (values, setFieldError) => {
    try {
      const response = await apiServices.updateRoom({
        payload: values,
        id: roomId,
      });
      if (response) {
        getListing();
        close();
        sweetAlertHelper({
          title: "Success",
          text: "Create Room Success",
          icon: "success",
        });
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={data}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
        // resetForm({ values: ""})
      }}
    >
      {({ handleReset, values, setFieldValue }) => (
        <Form className="modal-form">
          <Input label="Original Price" name="original_price" />
          <MDBRow>
            <Input label="Display Price" name="current_price" />
          </MDBRow>
          <MDBRow>
            <Input label="Room Costing" name="room_costing" />
          </MDBRow>
          <MDBRow>
            <Input label="Quantity" name="quantity" />
          </MDBRow>
          <MDBRow>
            <Input label="Normal" name="nonpeak_price" />
          </MDBRow>
          <MDBRow>
            <Input label="Peak Session" name="peak_price" />
          </MDBRow>
          <MDBRow>
            <Input label="Super Peak Session" name="superpeak_price" />
          </MDBRow>

          <Button name="Next" type="submit" />
        </Form>
      )}
    </Formik>
  );
}
