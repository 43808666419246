import { useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { ref } from "yup";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import Input from "../../../components/element/input";
import apiServices from "../../../services/api.services";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import Select from "../../../components/element/select";

export default function EditModalBox({
  getListing,
  show,
  close,
  selectedData,
}) {
  const [data, setData] = useState({
    user_id: selectedData.id ? selectedData.id : "",
    username: selectedData.username ? selectedData.username : "",
    name: selectedData.first_name ? selectedData.first_name : "",
    last_name: selectedData.last_name ? selectedData.last_name : "",
    phone_number: selectedData.phone_number ? selectedData.phone_number : "",
    company_name: selectedData.company_name ? selectedData.company_name : "",
    new_password: "",
    confirm_new_password: "",
  });

  const useSchema = Yup.object({
    username: Yup.string()
      .email("Invalid Email Address Format")
      .required("Required"),
    name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    phone_number: Yup.string().required("Required"),
    new_password: Yup.string(),
    confirm_new_password: Yup.string().oneOf(
      [ref("new_password")],
      "Passwords do not match"
    ),
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    if (values.confirm_new_password !== "") {
      try {
        const response = await apiServices.changePassword({
          payload: {
            user_id: values.user_id,
            new_password: values.new_password,
            confirm_new_password: values.confirm_new_password,
          },
        });
        if (response) {
          getListing();
        }
      } catch (ex) {
        setSubmitting(false);
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
            }
          }
        }
      }
    }
    try {
      const response = await apiServices.updateUser({
        id: selectedData.id,
        payload: values,
      });
      if (response) {
        getListing();
        close();
        sweetAlertHelper({
          title: "Success",
          text: "Update User Success",
          icon: "success",
        });
      }
    } catch (ex) {
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Information - {selectedData.username}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close((prevState) => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
              }}
            >
              {({
                handleReset,
                values,
                setFieldValue,
                isSubmitting,
                errors,
              }) => (
                <Form className="modal-form">
                  <Input name="user_id" label="User Id" disabled />
                  <Input name="username" label="Username" disabled={false} />
                  <Input name="name" label="First Name" disabled={false} />
                  <Input name="last_name" label="Last Name" disabled={false} />
                  <Input
                    name="phone_number"
                    label="Phone Number"
                    disabled={false}
                  />
                  <Input
                    name="company_name"
                    label="Company Name"
                    disabled={false}
                  />
                  <Input
                    name="new_password"
                    label="New Password"
                    type="password"
                    disabled={false}
                  />
                  <Input
                    name="confirm_new_password"
                    label="Confirm Password"
                    type="password"
                    disabled={false}
                  />

                  <Button
                    type="submit"
                    name="Submit"
                    isSubmitting={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
