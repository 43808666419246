import { useState, useEffect } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ref } from "yup";

//element component
import Button from "../../../components/element/button";
import Input from "../../../components/element/input";
import Select from "../../../components/element/select";
import apiServices from "../../../services/api.services";
import DateRange from "../../../components/element/dateRange";
export default function CreateModalBox({
  title,
  show,
  values,
  close,
  disabled,
  getListing,
}) {
  //   const [type, settype] = useState(["peak", "superpeak"]);
  const type = [{ label: "peak" }, { label: "superpeak" }];
  const [data, setData] = useState({
    type: "",
    date: "",
  });

  const useSchema = Yup.object({
    type: Yup.string().required("Please select your type"),
    date: Yup.string(),
  });

  const handleSubmit = async (values, setFieldError, type) => {
    try {
      const response = await apiServices.createDay({
        payload: values,
      });
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
        close(false);
        getListing();
      }
    } catch (ex) {
      let errorMsg = "";

      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close((prevState) => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, errors }) => (
                <Form className="modal-form">
                  <Select
                    name="type"
                    label="Type"
                    options={type}
                    placeholder="- Please Select -"
                    errors={errors}
                  />
                  <Input label="Date" name="date" type="date" />

                  <Button label="submit" name="Submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
