import { useState } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import apiServices from "../../../services/api.services";

export default function CreaditListing({
  length,
  start,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const actionOptions = ["Wallet Log", "Ticket Log"];
  const [date, setDate] = useState(new Date());

  const badgeBodyTemplate = (rowData) => {
    let description = "";
    if (rowData.description == "To app ticket booking") {
      description = "To app ticket booking";
    } else if (rowData.description == "To room booking") {
      description = "To room booking";
    } else if (rowData.description == "To room service order") {
      description = "To room service order";
    } else if (rowData.description == "T.SUCCESS") {
      description = "Success";
    }
    return (
      <span className={`custom-badge description-${description}`}>
        {description}
      </span>
    );
  };

  const actionBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => handleActionChange("information", options)}
        >
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "no", header: "No " },
    { field: "paid_at", header: "Paid on " },
    {
      field: "username",
      header: "Username",
    },
    {
      field: "in",
      header: "IN",
    },
    {
      field: "out",
      header: "OUT",
    },
    ,
    { field: "wallet", header: "Wallet" },
    { field: "description", header: "Descretion" },
    { field: "merchant", header: "Merchant" },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Wallet Transaction Report"
        data={listingData}
        columns={columns}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
