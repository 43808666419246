import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Calendar } from "primereact/calendar";
import React, { useState, useRef } from "react";
import moment from "moment/moment";

export default function CheckBox({ label, name }) {
  const [toggle, setToggle] = useState(false);
  const { values, errors, setFieldValue } = useFormikContext();
  return (
    <MDBRow className="form-group">
      <MDBCol className="col-12 col-md-3 form-label">
        <label htmlFor={name}>{label}</label>
      </MDBCol>
      <MDBCol>
        <label>
          <Field
            type="checkbox"
            name={name}
            checked={values[name]}
            onChange={(e) => {
              const checkbox = e.target.checked;
              setToggle(!toggle);
              // if (checkbox == false) {
              setFieldValue(name, e.target.checked);
              // } else {
              //   setFieldValue(name, true);
              // }
            }}
          />
          {values[name] == false ? " No" : " Yes"}
        </label>
        {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null}
      </MDBCol>
    </MDBRow>
  );
}
