import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";

//primereact
import { Steps } from "primereact/steps";
import { useState, useRef, useEffect } from "react";
import CreateFormStep1 from "./create.form.step1";
import CreateFormStep2 from "./create.form.step2";

export default function CreateModalBox({
  title,
  show,
  close,
  getListing,
  selectedId,
}) {
  const [roomId, setRoomId] = useState("");
  const [step, setStep] = useState(0);
  const items = [
    { label: "Room Display Details" },
    { label: "Room Pricing Details" },
  ];

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };
  const modalTopRef = useRef();
  const scrollToTop = () => {
    modalTopRef.current?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    });
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 500);
  }, []);
  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="create-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close((prevState) => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div ref={modalTopRef}></div>
            <MDBContainer breakpoint="none" className="">
              <Steps model={items} activeIndex={step} />
            </MDBContainer>
            <MDBContainer>
              {step == 0 ? (
                <CreateFormStep1
                  handleNextStep={handleNextStep}
                  getListing={getListing}
                  setRoomId={setRoomId}
                />
              ) : step == 1 ? (
                <CreateFormStep2
                  handleNextStep={handleNextStep}
                  getListing={getListing}
                  selectedId={selectedId}
                  roomId={roomId}
                  close={close}
                />
              ) : (
                ""
              )}
            </MDBContainer>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
