import { useState } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";
import { MDBRow } from "mdb-react-ui-kit";

export default function Listing({
  length,
  start,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const actionOptions = ["Wallet Log", "Ticket Log"];
  const [date, setDate] = useState(new Date());

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userStatusToggle({ userId });
      if (response == 200) {
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="active"
        falseValue="inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const actionBodyTemplate = (options) => {
    return (
      <MDBRow className="action-button">
        <div onClick={() => handleActionChange("information", options)}>
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(moment(e.value[0]).format("YYYY-MM-DD"));
          setEndDate(moment(e.value[1]).format("YYYY-MM-DD"));
          // options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "username", header: "Username" },
    { field: "room_number", header: "Room No" },
    { field: "quantity", header: "Quantity" },
    { field: "title", header: "Request" },
    { field: "department", header: "Department" },
    { field: "visit_time", header: "Order Time" },
    {
      field: "completed_at",
      header: "Complete Date",
      // filterElement: filterDateTemplate,
    },
    { field: "accept_by", header: "Accept By" },
    { field: "", header: "Action", body: actionBodyTemplate },
  ];

  return (
    <div className="listing-container">
      <ListingTable
        title="Complete Order"
        data={listingData}
        columns={columns}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
