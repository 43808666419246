import { useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Calendar } from "primereact/calendar";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//primereact
import { Image } from "primereact/image";

//element component
import Button from "../../../components/element/button";
import Input from "../../../components/element/input";
import apiServices from "../../../services/api.services";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import DatePicker from "../../../components/element/datePicker";
import Select from "../../../components/element/select";

export default function InfoModalBox({
  getListing,
  show,
  close,
  selectedData,
}) {
  const [genderList, setGenderList] = useState([
    {
      id: "female",
      label: "Female",
    },
    {
      id: "male",
      label: "Male",
    },
  ]);
  const [data, setData] = useState({
    first_name: selectedData.first_name ? selectedData.first_name : "",
    last_name: selectedData.last_name ? selectedData.last_name : "",
    dob: selectedData.dob ? new Date(selectedData.dob) : "",
    gender: selectedData.gender ? selectedData.gender : "",
    email: selectedData.username ? selectedData.username : "",
    phone_number: selectedData.phone_number ? selectedData.phone_number : "",
    credit_balance: selectedData.credit_balance
      ? selectedData.credit_balance
      : "",
    point_balance: selectedData.point_balance ? selectedData.point_balance : "",
    created_at: selectedData.created_at ? selectedData.created_at : "",
    last_login_at: selectedData.last_login_at ? selectedData.last_login_at : "",
  });

  const useSchema = Yup.object({
    first_name: Yup.string().required("required"),
    last_name: Yup.string().required("required"),
    dob: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    email: Yup.string().required("required"),
    phone_number: Yup.string()
      .required("required")
      .min(10, "Please enter more than or equal to 10 character")
      .max(13, "Must be exactly 13 digits")
      .matches(/^([6]{1}|\+?[0]{1})([0-9]{9,11})$/g, "Must be phone format"),
    credit_balance: Yup.string(),
    point_balance: Yup.string(),
    created_at: Yup.string(),
    last_login_at: Yup.string(),
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    const payload = {
      name: values.first_name,
      last_name: values.last_name,
      gender: values.gender,
      email: values.email,
      phone_number: values.phone_number,
      // credit_balance: values.credit_balance,
      // point_balance: values.point_balance,
      dob: moment(values.dob).format("YYYY-MM-DD"),
    };
    try {
      const response = await apiServices.updateUser({
        id: selectedData.id,
        payload: payload,
      });
      if (response) {
        getListing();
        close();
        sweetAlertHelper({
          title: "Success",
          text: "Update User Success",
          icon: "success",
        });
      }
    } catch (ex) {
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Information - {selectedData.username}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close((prevState) => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
              }}
            >
              {({
                handleReset,
                values,
                setFieldValue,
                isSubmitting,
                errors,
              }) => (
                <Form className="modal-form">
                  <Input
                    name="first_name"
                    label="First Name"
                    disabled={false}
                  />
                  <Input name="last_name" label="Last Name" disabled={false} />
                  <DatePicker name="dob" label="Date of Birth" />
                  {/* <Input name="dob" label="Day of Birth" disabled={false} /> */}
                  <Select
                    name="gender"
                    label="Gender"
                    options={genderList}
                    placeholder="- Please Select -"
                    errors={errors}
                  />
                  <Input name="email" label="Username" disabled={false} />
                  <Input
                    name="phone_number"
                    label="Phone Number"
                    disabled={false}
                  />
                  <Input
                    name="credit_balance"
                    label="Credit Balance"
                    disabled={true}
                  />
                  <Input
                    name="point_balance"
                    label="Point Balance"
                    disabled={true}
                  />
                  <Input name="created_at" label="Create At" disabled={true} />
                  {/* <Input
                    name="last_login_at"
                    label="Last Login At"
                    disabled={true}
                  /> */}
                  <Button
                    type="submit"
                    name="Submit"
                    isSubmitting={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
