import { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import CheckBox from "../../../components/element/checkbox";
import Select from "../../../components/element/select";
import DateRange from "../../../components/element/dateRange";
import DateMultiSelect from "../../../components/element/dateMultiSelect";
import FileUpload from "../../../components/element/file";

export default function EditModalForm1({
  getInfoDetail,
  selectedData,
  handleNextStep,
  marchantList,
  getListing,
  selectedId,
}) {
  const type = [
    { label: "Event", id: "event" },
    { label: "Activity", id: "activity" },
    { label: "Promotion", id: "promotion" },
    { label: "Place", id: "place" },
  ];

  const [data, setData] = useState({
    ticket_type: "ticket",
    method: "PUT",
    id: selectedId,
    type: selectedData ? selectedData.type : "",
  });

  const useSchema = Yup.object({
    type: Yup.string().required("Please select your type"),
  });

  const handleSubmit = async (values, setFieldError) => {
    handleNextStep();
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={data}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue }) => (
        <Form className="modal-form">
          <Select
            name="type"
            label="Choose Ticket Type"
            options={type}
            disabled={true}
            placeholder="- Please Select -"
          />
          <Button name="Next" type="submit" />
        </Form>
      )}
    </Formik>
  );
}
