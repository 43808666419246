import { useEffect, useState } from "react";

//framework
import { MDBBtn, MDBContainer, MDBInput, MDBRow } from "mdb-react-ui-kit";

//formik
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

//services
import authServices from "../../services/auth.services";

//helper
import { sweetAlertHelper } from "../../helper/sweetalert.helper";

import OneSignal from "react-onesignal";

export default function Login() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const useSchema = Yup.object({
    username: Yup.string().required("Enter username"),
    password: Yup.string().required("Enter password"),
  });

  async function handleSubmit(data, setFieldError, navigate, setSubmitting) {
    try {
      const response = await authServices.login(data);
      if (response) {
        navigate("/dashboard");
        sweetAlertHelper({
          title: "Success",
          text: "Login Success",
          icon: "success",
        });
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  }

  return (
    <MDBContainer className="login-container">
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={data}
        validationSchema={useSchema}
        onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
          handleSubmit(values, setFieldError, navigate, setSubmitting);
          resetForm({ values: "" });
        }}
      >
        {({ setFieldValue, errors, isSubmitting, isValid }) => (
          <Form className="login-form">
            <MDBRow className="login-label">
              <label>Sign in to start your session</label>
            </MDBRow>
            <MDBRow className="p-2">
              <Field
                as={MDBInput}
                name="username"
                type="input"
                label="Username"
              />
              {errors["username"] ? (
                <div className="text-danger text-right no-padding error-message">
                  {errors["username"]}
                </div>
              ) : null}
            </MDBRow>
            <MDBRow className="p-2">
              <Field
                className="key"
                as={MDBInput}
                name="password"
                type="password"
                label="Password"
              />
              {errors["password"] ? (
                <div className="text-danger text-right no-padding error-message">
                  {errors["password"]}
                </div>
              ) : null}
            </MDBRow>
            <MDBRow className="p-2">
              <MDBBtn type="submit">Sign in</MDBBtn>
            </MDBRow>
          </Form>
        )}
      </Formik>
    </MDBContainer>
  );
}
