import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useState } from "react";
import useWindowDimensions from "../../components/common/window-dimension";
import { Icon } from "@iconify/react";
import LogoMainRound from "../../assets/fonts/image/main-logo-round.png";
import Header from "../../components/header/header-one";

//common component

export default function ErrorPage() {
  const [openSideBar, setOpenSideBar] = useState(true);
  const { height, width } = useWindowDimensions();
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  const [payment, setPayment] = useState("11");

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container-maintenance">
        <div className="page-container-maintenance">
          {/* <Header /> */}
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <img src={LogoMainRound} className="logo" /> &nbsp;
                <label className="title">gcmadmin.ax-tech.co</label>
              </MDBCol>
            </MDBRow>
            <Icon icon="mdi:scheduled-maintenance" className="icon" />
            <MDBRow>
              <label>The site is currently down for maintenance</label>
            </MDBRow>
            <p>We'll be back up and running again shortly</p>
          </MDBContainer>
        </div>
      </div>
    </MDBContainer>
  );
}
