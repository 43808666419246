//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import InputFieldArray from "../../../components/element/fieldArray";
import FileUpload from "../../../components/element/file";
import Input from "../../../components/element/input";

export default function TicketModalBoxStep2({
  handleNextStep,
  tempData,
  disabled,
}) {
  const useSchema = Yup.object({
    type: Yup.string().required("required"),
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {};

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={tempData}
      validationSchema={useSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue, errors, isSubmitting }) => (
        <Form>
          <Input name="title" label="Title" disabled={disabled} />
          <FileUpload label="Display Image" name="image" />
          <InputFieldArray
            label={"Description"}
            values={values.description}
            arrayItem={
              values.description.length > 0 &&
              values.description.map((tag, index) => (
                <Input name={`description${index}`} />
              ))
            }
          />
        </Form>
      )}
    </Formik>
  );
}
