import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import config from "../../config.json";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";
import apiServices from "../../services/api.services";
import InfoModalBox from "./component/info.modal.box";
import Listing from "./component/listing";
import QrCodeScanner from "../../components/modal/qr.code.scanner";
import Gif from "../../assets/fonts/loader/loader.gif";

//service
import useWindowDimensions from "../../components/common/window-dimension";

//socket
import Pusher from "pusher-js";

export default function TicketBooking() {
  const [qr, setQr] = useState({ status: false, type: "" });
  const [array, setArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const [openSideBar, setOpenSideBar] = useState(true);
  //data for api
  const [type, setType] = useState("all"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("selectedId");
  const [infoModalBoxData, setInfoModalBoxData] = useState({});
  const typeOption = ["Event", "Activity", "Place", "Promotion"];
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  const [modalBox, setModalBox] = useState([]);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);
  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  const getListing = async () => {
    const response = await apiServices.getAllListing({
      api_name: "my_planning",
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
      ticket_status: "",
    });
    if (response) {
      setLoader(false);
      setListingData(response.aaData);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  const getInfoDetail = async () => {
    const response = await apiServices.getPlanningInfo(selectedId);
    if (response) {
      setInfoModalBoxData(response);
    }
  };

  const getScanInfoDetail = async () => {
    const response = await apiServices.getScanPlanningInfo(
      infoModalBoxData.booking_id
    );
    if (response) {
      setInfoModalBoxData(response);
    }
  };

  const handleOpenQrCodeScan = () => {
    setOpenQrCodeScan(!openQrCodeScan);
  };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    if (selectedDataRow) {
      setInfoModalBoxData(selectedDataRow);
      setSelectedId(selectedDataRow.id);
      setActionValue(selectedOption);
    }
  };

  useEffect(() => {
    if (search === "") {
      // setLoader(true);
    }
    getListing();
  }, [search, length, start]);

  useEffect(() => {
    if (actionValue == "information" && selectedId >= 0) {
      // getInfoDetail();
      getScanInfoDetail();
    }
  }, [selectedId]);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
      cluster: "ap1",
      authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      },
    });

    const channel = pusher.subscribe("presence-user-" + userId);
    var socketId = null;
    pusher.connection.bind("connected", () => {
      socketId = pusher.connection.socket_id;
    });
    channel.bind("my-planning-event", (data) => {
      if (data.status == "success") {
        getListing();
      }
    });

    return () => {
      channel.unbind("my-planning-event");
    };
  }, []);

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {openQrCodeScan && (
          <QrCodeScanner
            show={true}
            title="Scan Ticket Qr"
            close={handleOpenQrCodeScan}
            getListing={getListing}
            setSearch={setSearch}
            listingData={listingData}
            handleActionChange={handleActionChange}
            setQr={setQr}
            setModalBox={setModalBox}
          />
        )}
        {actionValue == "information" &&
          Object.keys(infoModalBoxData).length > 0 && (
            <InfoModalBox
              show={true}
              data={infoModalBoxData}
              close={() => setActionValue(false)}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              getListing={getListing}
              setInfoModalBoxData={setInfoModalBoxData}
              setSearch={setSearch}
            />
          )}
        <div className="page-container">
          <Header
            handleSideBar={handleOpenSideBar}
            handleOpenQrCodeScan={handleOpenQrCodeScan}
          />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <Listing
              length={length}
              start={start}
              // createAble={createAble}
              listingData={listingData}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
              // handleNewModal={handleNewModal}
              actionValue={actionValue}
              // createAble={handleNewModal}
              handleActionChange={handleActionChange}
              // getListing={getListing}
              // setHandleApplyFilter={setHandleApplyFilter}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
