import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Calendar } from "primereact/calendar";
import React, { useState, useRef } from "react";
import moment from "moment/moment";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

export default function DateMultiSelect({
  label,
  name,
  specificDate,
  setSpecificDate,
}) {
  const { values, errors, setFieldValue } = useFormikContext();
  return (
    <MDBRow className="form-group">
      <MDBCol className="col-12 col-md-3 form-label">
        <label htmlFor={name}>{label}</label>
      </MDBCol>
      <MDBCol>
        {/* <Calendar
          id="multiple"
          value={specificDate}
          defaultValue={defaultValue}
          showTime
          onChange={(e) => {
            let newDate = [];
            e.value.map((array) =>
              newDate.push(moment(array).format("YYYY-MM-DD hh:mm"))
            );
            setSpecificDate(e.value);
            setFieldValue("availability_date", newDate);
          }}
          selectionMode="multiple"
          readOnlyInput
        /> */}
        <DatePicker
          value={specificDate}
          multiple
          onChange={(date) => {
            let newDate = [];
            date.map((array) =>
              newDate.push(moment(new Date(array)).format("YYYY-MM-DD hh:mm"))
            );
            setFieldValue("availability_date", newDate);
          }}
          minDate={new Date()}
          // disableDayPicker={new Date()}
          // plugins={[<TimePicker position="bottom" />, [<DatePanel />]]}
        />
        {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null}
      </MDBCol>
    </MDBRow>
  );
}
