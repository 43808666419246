import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Calendar } from "primereact/calendar";
import React, { useState, useRef } from "react";
import moment from "moment/moment";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

export default function DateRange({
  label,
  name,
  dateRange,
  setDateRange,
  showTime,
}) {
  const { values, errors, setFieldValue } = useFormikContext();
  const format = "MM-DD-YYYY";
  return (
    <MDBRow className="form-group">
      <MDBCol className="col-12 col-md-3 form-label">
        <label htmlFor={name}>{label}</label>
      </MDBCol>
      <MDBCol>
        {/* <Calendar
          // id="range"
          showTime={showTime}
          value={dateRange}
          onChange={(e) => {
            setDateRange(e.value);
            setFieldValue(
              "availability_start_date",
              moment(e.value[0]).format("YYYY-MM-DD hh:mm")
            );
            setFieldValue(
              "availability_end_date",
              moment(e.value[1]).format("YYYY-MM-DD hh:mm")
            );
          }}
          // selectionMode="range"
          readOnlyInput
          dateFormat="dd/mm/yy"
        /> */}
        <DatePicker
          value={dateRange}
          range
          onChange={(date) => {
            setFieldValue(
              "availability_start_date",
              moment(new Date(date[0])).format("YYYY-MM-DD hh:mm")
            );
            setFieldValue(
              "availability_end_date",
              moment(new Date(date[1])).format("YYYY-MM-DD hh:mm")
            );
          }}
          // plugins={[<TimePicker position="bottom" />]}
        />
        {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null}
      </MDBCol>
    </MDBRow>
  );
}
