import { useState, useEffect } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import config from "../../../config.json";
//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//service
import apiServices from "../../../services/api.services";
import useWindowDimensions from "../../../components/common/window-dimension";

//element component
import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import DatePicker from "../../../components/element/datePicker";
import { ToggleButton } from "primereact/togglebutton";
import ToggleSwitch from "../../../components/element/toggleSwitch";
import { configure } from "@testing-library/react";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function InfoModalBox({
  title,
  show,
  selectedData,
  close,
  getListing,
  selectedId,
  disabled,
  getInfoDetail,
  setSelectedId,
}) {
  const roles = localStorage.getItem("roles_id");
  const userID = localStorage.getItem("user_id");
  const { height, width } = useWindowDimensions();

  const [responsive, setResponsive] = useState("mobile");

  const scanQRBooking = async () => {
    try {
      // const response = apiServices.scanPlanning({
      //   payload: {
      //     qr: data.booking_id,
      //   },
      // });
      // if (response) {
      // }
    } catch (ex) {}
  };

  const updateService = async (id, status) => {
    try {
      const response = await apiServices.updateServiceRequest({
        id: id,
        payload: { type: status, id: id },
      });
      if (response) {
        getListing();
        getInfoDetail();
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
        close(false);
      }
    } catch (ex) {
      // getInfoDetail();
      const errors = ex.response.data.errors;
      let errorMsg = "";

      if (errors && Object.keys(errors).length > 0) {
        Object.keys(errors).map((item, i) => {
          //   setFieldError(item, errors[item]);
          errorMsg = errors[item];
        });

        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (width >= "767") {
      setResponsive("desktop");
    } else {
      setResponsive("mobile");
    }
  }, [width]);

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="booking-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => {
                close();
                setSelectedId("");
              }}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <label className="booking-label-bold">
                    R.Service ID:{" "}
                    <span>{selectedData.id ? selectedData.id : ""}</span>
                  </label>
                </MDBCol>
                <MDBCol className="text-end">
                  <label className="booking-label-bold">
                    Room No:{" "}
                    <span>
                      {selectedData.room_number ? selectedData.room_number : ""}
                    </span>
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <label className="booking-label-bold">
                  Customer Email:{" "}
                  <span>
                    {selectedData.username ? selectedData.username : ""}
                  </span>
                </label>
              </MDBRow>
              <MDBRow>
                <label className="booking-label-bold">
                  Department:{" "}
                  <span>
                    {selectedData.department ? selectedData.department : ""}
                  </span>
                </label>
              </MDBRow>
              <MDBRow>
                <label className="booking-label-bold">
                  Order Time:{" "}
                  <span>
                    {selectedData.visit_time ? selectedData.visit_time : ""}
                  </span>
                </label>
              </MDBRow>
              <div className="package-box-container">
                <MDBRow>
                  <label className="booking-label-bold">Request Detail</label>
                </MDBRow>
                <div className="booking-package-box detail">
                  <MDBRow className="m-0">
                    <MDBCol className="col-5">
                      <label className="booking-label-grey">
                        {selectedData.title ? selectedData.title : ""}
                      </label>
                    </MDBCol>
                    <MDBCol>
                      <label>
                        x {selectedData.quantity ? selectedData.quantity : ""}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {/* {data.package_detail
                    ? data.package_detail.map((item, index) => (
                        <MDBRow className="m-0">
                          <MDBCol className="">
                            <label className="booking-label-grey">
                              {item.ticket_package_type_item_name}
                            </label>
                          </MDBCol>
                          <MDBCol>
                            <label>x{item.quantity}</label>
                          </MDBCol>
                        </MDBRow>
                      ))
                    : ""} */}
                  {/* <MDBRow className="m-0">
                    <MDBCol className="">
                      <label className="booking-label-grey">
                        Additional service
                      </label>
                    </MDBCol>
                    <MDBCol>
                      {data.additional_service
                        ? data.additional_service.map((item, index) => (
                            <MDBRow>
                              <label>{item.addon_item_name}</label>
                            </MDBRow>
                          ))
                        : ""}
                    </MDBCol>
                  </MDBRow> */}
                </div>
              </div>
              {roles == selectedData.department_id ? (
                <div className="merchant-action-container">
                  <MDBRow className="booking-label-bold">
                    <label> Action</label>
                  </MDBRow>
                  <MDBCol className="text-end">
                    {selectedData.request_status === "Accept" ? (
                      <button
                        className="merchant-status accept"
                        onClick={() => updateService(selectedData.id, "accept")}
                        disabled={
                          roles == selectedData.department_id ? false : true
                        }
                      >
                        Accept !
                      </button>
                    ) : selectedData.request_status === "In Progress" ? (
                      <button
                        className="merchant-status"
                        onClick={() => updateService(selectedData.id, "update")}
                        disabled={
                          userID == selectedData.accept_by_id ? false : true
                        }
                      >
                        {userID == selectedData.accept_by_id
                          ? "Complete !"
                          : `in progress by ${selectedData.accept_by}`}
                      </button>
                    ) : selectedData.request_status === "Completed" ? (
                      <button className="merchant-status done" disabled={true}>
                        DONE !
                      </button>
                    ) : (
                      " - "
                    )}
                  </MDBCol>
                  {/* <MDBRow className="marchant-action-row">
                    <MDBCol></MDBCol>
                  </MDBRow> */}
                </div>
              ) : (
                <>
                  <div className="merchant-action-container">
                    <MDBRow className="booking-label-bold">
                      <label>Department Action</label>
                    </MDBRow>
                    <MDBRow className="marchant-action-row">
                      <MDBCol>
                        <label>Job Accept</label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        {selectedData.request_status === "Accept" ? (
                          <button
                            className="merchant-status accept"
                            onClick={() =>
                              updateService(selectedData.id, "accept")
                            }
                            disabled={
                              roles == selectedData.department_id ? false : true
                            }
                          >
                            Accept !
                          </button>
                        ) : selectedData.request_status === "In Progress" ? (
                          <button
                            className="merchant-status done"
                            disabled={true}
                          >
                            DONE !
                          </button>
                        ) : selectedData.request_status === "Completed" ? (
                          <button
                            className="merchant-status done"
                            disabled={true}
                          >
                            DONE !
                          </button>
                        ) : (
                          " - "
                        )}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="marchant-action-row">
                      <MDBCol>
                        <label>In Progress / Checking</label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        {selectedData.request_status === "In Progress" ? (
                          <button
                            className="merchant-status"
                            onClick={() => scanQRBooking()}
                            disabled={roles == "merchant" ? false : true}
                          >
                            in progress by {selectedData.accept_by}
                          </button>
                        ) : selectedData.request_status === "Completed" ? (
                          <button
                            className="merchant-status"
                            onClick={() => scanQRBooking()}
                            disabled={roles == "merchant" ? false : true}
                          >
                            in progress by {selectedData.accept_by}
                          </button>
                        ) : (
                          " - "
                        )}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="marchant-action-row">
                      <MDBCol>
                        <label>Complete</label>
                      </MDBCol>
                      <MDBCol className="text-end">
                        {selectedData.request_status === "In Progress" ? (
                          <button
                            className="merchant-status accept"
                            onClick={() =>
                              updateService(selectedData.id, "update")
                            }
                            disabled={
                              userID == selectedData.accept_by_id ? false : true
                            }
                          >
                            Accept !
                          </button>
                        ) : selectedData.request_status === "Completed" ? (
                          <button
                            className="merchant-status completed"
                            disabled={true}
                          >
                            Completed !
                          </button>
                        ) : (
                          " - "
                        )}
                      </MDBCol>
                    </MDBRow>
                  </div>
                  <div className="package-box-container">
                    <MDBRow className="booking-label-bold">
                      <label>Activity</label>
                    </MDBRow>
                    <div className="booking-package-box">
                      <MDBRow className="m-0">
                        <MDBCol className="col-7">
                          <MDBRow>
                            <MDBCol className="col-12 activity">
                              <label className="booking-label-bold">
                                Operation
                              </label>
                            </MDBCol>
                            <MDBCol className="col-12">
                              <label>Job Accept</label>
                            </MDBCol>
                            <MDBCol className="col-12">
                              <label>In Progress / Checking</label>
                            </MDBCol>
                            <MDBCol className="col-12">
                              <label>Complete</label>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                        <MDBCol className={"text-start"}>
                          <MDBRow>
                            <MDBCol className=" activity">
                              <label className="booking-label-bold">
                                Record On
                              </label>
                            </MDBCol>
                            <MDBCol className="col-12 activity-data">
                              <label>
                                {selectedData.accepted_at
                                  ? moment(selectedData.accepted_at).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )
                                  : " - "}
                              </label>
                            </MDBCol>
                            <MDBCol className="col-12 activity-data">
                              <label>
                                {selectedData.accepted_at
                                  ? moment(selectedData.accepted_at).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )
                                  : " - "}
                              </label>
                            </MDBCol>
                            <MDBCol className="col-12 activity-data">
                              <label>
                                {selectedData.completed_at
                                  ? moment(selectedData.completed_at).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )
                                  : " - "}
                              </label>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                </>
              )}
            </MDBContainer>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
