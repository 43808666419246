import { useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";

export default function WalletLog({
  title,
  show,
  values,
  close,
  disabled,
}) {

  const [data, setData] = useState({

  });

  const useSchema = Yup.object({

  });

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close(prevState => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                // handleLogin(values,setFieldError,setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset }) => (
                <Form className="modal-form">
                    <Button label="submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
