import { useEffect, useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import { Calendar } from "primereact/calendar";

//formik
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import Input from "../../../components/element/input";
import Select from "../../../components/element/select";
import apiServices from "../../../services/api.services";

export default function CreateModalBox({ title, show, close, getListing }) {
  const [roleList, setRoleList] = useState([]);
  const [date, setDate] = useState("");

  const getRoleListing = async () => {
    const response = await apiServices.getRoleListing();
    if (response) {
      setRoleList(response);
    }
  };

  useEffect(() => {
    getRoleListing();
  }, []);

  const [data, setData] = useState({
    username: "",
    room_number: "",
    check_in_at: "",
    check_out_at: "",
  });

  const useSchema = Yup.object({
    username: Yup.string().required("required").email("Please enter email"),
    room_number: Yup.string().required("required"),
  });

  const onChangenDate = (e, setFieldValue) => {
    if (String(e.value[0]) == String(e.value[1])) {
    } else {
      setDate(e.value);
      setFieldValue("check_in_at", moment(e.value[0]).format("YYYY-MM-DD"));
      setFieldValue("check_out_at", moment(e.value[1]).format("YYYY-MM-DD"));
    }
  };

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    try {
      const response = await apiServices.createGuest({
        payload: values,
      });
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Create  Success",
          icon: "success",
        });
        close(false);
        getListing();
      }
    } catch (ex) {
      let errorMsg = "";
      setSubmitting(false);
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item];
              setFieldError(item, errors[item]);
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close((prevState) => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, errors, isSubmitting, setFieldValue }) => (
                <Form className="modal-form">
                  <Input label="Username" name="username" type="email" />

                  <Input label="Room Number" name="room_number" />
                  <Calendar
                    minDate={new Date()}
                    id="disableddays"
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    value={date}
                    onChange={(e) => onChangenDate(e, setFieldValue)}
                    inline
                  />
                  <Button
                    name="submit"
                    type="submit"
                    isSubmitting={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
