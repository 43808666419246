import { MDBContainer } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import moment from "moment";
import config from "../../config.json";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";

//component
import BookingListing from "./component/booking.listing";
import InfoModalBox from "./component/info.modalbox";
import CreateModalBox from "./component/create.modal.box";
import QrCodeScanner from "../../components/modal/qr.code.scanner";
import Gif from "../../assets/fonts/loader/loader.gif";

//api service
import useWindowDimensions from "../../components/common/window-dimension";
import apiServices from "../../services/api.services";

//socket
import Pusher from "pusher-js";

export default function Booking() {
  const [loader, setLoader] = useState(false);
  const role = localStorage.getItem("roles_id");
  const token = localStorage.getItem("token");
  const [dateLabel, setdateLabel] = useState("All");
  //data for api
  const [type, setType] = useState("all"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [columns, setColumns] = useState("created_at");
  const [startDate, setStartDate] = useState(""); //filter date
  const [endDate, setEndDate] = useState(""); //filter date
  const [selectedId, setSelectedId] = useState("selectedId");

  //handle modalbox
  const [openQrCodeScan, setOpenQrCodeScan] = useState(false);
  const [actionValue, setActionValue] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  //date for render
  const [listingData, setListingData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  const [infoData, setInfoData] = useState("");
  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);
  const { height, width } = useWindowDimensions();
  const [payment, setPayment] = useState("ddd");

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.getBookingListing({
      type,
      search,
      startDate,
      endDate,
      length,
      start,
      columns,
      order,
    });
    if (response) {
      setLoader(false);
      setListingData(response.aaData);
      setUserData(response.booking_info);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  const handleCloseModal = () => {
    setActionValue(false);
    setSelectedId("selectedId");
  };

  const handleActionChange = (selectedOption, selectedDataRow) => {
    setActionValue(selectedOption);
    setSelectedId(selectedDataRow.id);
    setInfoData(selectedDataRow);
  };

  const createAble = () => {
    setCreateModal(!createModal);
  };

  useEffect(() => {
    if (search === "") {
      // setLoader(true);
    }
    getListing();
  }, [search, length, handleApplyFilter, start, startDate, endDate]);

  const [openSideBar, setOpenSideBar] = useState(true);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };
  const handleOpenQrCodeScan = () => {
    setOpenQrCodeScan(!openQrCodeScan);
  };

  const changeDate = () => {
    if (startDate && endDate === moment(new Date()).format("YYYY-MM-DD")) {
      setStartDate("");
      setEndDate("");
    } else {
      setStartDate(moment(new Date()).format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
      cluster: "ap1",
      authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      },
    });

    const channel = pusher.subscribe("presence-roles-" + role);
    var socketId = null;
    pusher.connection.bind("connected", () => {
      socketId = pusher.connection.socket_id;
    });
    channel.bind("booking-event", (data) => {
      if (data.status == "success") {
        getListing();
      }
    });

    return () => {
      channel.unbind("booking-event");
    };
  }, []);

  let bookingList = "abc";

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />
        {openQrCodeScan && (
          <QrCodeScanner
            show={true}
            title="Scan Room QR"
            close={handleOpenQrCodeScan}
            getListing={getListing}
            setSearch={setSearch}
            listingData={listingData}
            handleActionChange={handleActionChange}
          />
        )}
        {actionValue == "information" &&
          Object.keys(listingData).length > 0 && (
            <InfoModalBox
              show={true}
              selectedData={infoData}
              close={handleCloseModal}
              getListing={getListing}
            />
          )}
        {createModal && (
          <CreateModalBox
            show={true}
            getListing={getListing}
            title="Create New User"
            close={createAble}
          />
        )}
        <div className="page-container">
          <Header
            handleSideBar={handleOpenSideBar}
            handleOpenQrCodeScan={handleOpenQrCodeScan}
          />
          {loader ? (
            <div className="loader">
              <img src={Gif} />
            </div>
          ) : (
            <BookingListing
              length={length}
              start={start}
              createAble={createAble}
              changeDate={changeDate}
              listingData={listingData}
              userData={userData}
              dateLabel={startDate !== "" ? "Today" : "All"}
              totalRecords={totalRecords}
              displayRecords={displayRecords}
              bookingList={bookingList}
              setListingData={setListingData}
              setSearch={setSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setOrder={setOrder}
              setLength={setLength}
              setStart={setStart}
              handleActionChange={handleActionChange}
            />
          )}
        </div>
      </div>
    </MDBContainer>
  );
}
