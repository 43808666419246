import { useState, useRef } from "react";
import moment from "moment";
import { Icon } from "@iconify/react";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

//component
import ListingTable from "../../../components/modal/listingTable";
// import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import Select from "../../../components/element/select";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function TicketListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
  setColumns,
  ticketType,
  setTicketType,
}) {
  const actionOptions = ["Wallet Log", "Ticket Log"];
  const type = [
    { label: "Event", value: "event" },
    { label: "Activity", value: "activity" },
    { label: "Promotion", value: "promotion" },
    { label: "Place", value: "place" },
  ];
  const [date, setDate] = useState(new Date());
  const handleChange = async (values) => {
    try {
      const response = await apiServices.ticketStatusToggle({
        id: values.id,
      });
      if (response == 200) {
        sweetAlertHelper({
          title: "Success",
          text: "Edit Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  const labelBodyTemplate = ({ title }) => {
    return (
      <MDBCol>
        <div className="template-group label-listing">{title}</div>
      </MDBCol>
    );
  };

  const labelPackageNameBodyTemplate = ({ package_name }) => {
    return (
      <MDBCol>
        <div className="template-group label-listing">{package_name}</div>
      </MDBCol>
    );
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="active"
        falseValue="inactive"
        // onClick={() => {}}
        onChange={(e) => handleChange(options)}
      />
    );
  };

  const descriptionBodyTemplate = (options) => {
    return (
      <ul>
        {options.description
          ? options.description.map((item) => <li>{item}</li>)
          : ""}
      </ul>
    );
  };

  const dateBodyTemplate = ({ created_at }) => {
    return <span>{moment(created_at).format("DD MMM YY")}</span>;
  };

  const actionBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => handleActionChange("information", options)}
        >
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const editBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => handleActionChange("edit", options)}
        >
          <Icon icon="prime:pencil" color="#41B1CD" width="25" height="25" />
        </div>
      </MDBRow>
    );
  };

  const deleteTicket = async (id) => {
    const payload = {
      id: id,
      ticket_type: "ticket",
    };
    try {
      const response = await apiServices.DeleteTicket({
        payload,
        id,
      });

      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
        getListing();
      }
    } catch (ex) {
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  const deleteBodyTemplate = (options) => {
    const confirm2 = (event) => {
      confirmPopup({
        target: event.currentTarget,
        message: "Do you want to delete this record?",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept: () => deleteTicket(options.id),
      });
    };
    return (
      <MDBRow className="action-button">
        <div style={{ width: "auto" }} onClick={confirm2}>
          <Icon
            icon="material-symbols:delete-forever-rounded"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const filterDateTemplate = (options, event) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(moment(e.value[0]).format("YYYY-MM-DD"));
          setEndDate(moment(e.value[1]).format("YYYY-MM-DD"));
          setColumns(options.field);
          // options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const filterTypeTemplate = (options, event) => {
    return (
      <Dropdown
        value={ticketType}
        options={type}
        onChange={(e) => setTicketType(e.value)}
        // placeholder={placeholder}
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} readOnly disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "id", header: "Ticket ID" },
    {
      field: "type",
      header: "Ticket Type",
    },
    {
      field: "title",
      header: "Title",
      //  body: labelBodyTemplate
    },
    {
      field: "created_at",
      header: "Created Date",
      filterElement: filterDateTemplate,
      // body: dateBodyTemplate,
    },
    {
      field: "end_at",
      header: "End Date",
      // filterElement: filterDateTemplate,
    },
    {
      field: "package_name",
      header: "Package Name",
      // body: labelPackageNameBodyTemplate,
    },
    { field: "package_type", header: "Package Type" },
    { field: "relate_merchant", header: "Relate Merchant" },
    {
      field: "status",
      header: "Status",
      body: statusBodyTemplate,
    },
    // { field: "", body: actionBodyTemplate, headerStyle: "0.5em" },
    { field: "", body: editBodyTemplate, headerStyle: "1em" },
    {
      field: "",
      body: deleteBodyTemplate,
      headerStyle: "1em",
      header: "Delete",
    },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ConfirmPopup />
      <ListingTable
        title="Ticket"
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
