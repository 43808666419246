import http from "./http.services";
import auth from "./auth.services";
import config from "../config.json";

//get listing for all
async function getAllListing({
  api_name,
  type = "",
  search,
  order,
  columns = "",
  startDate = "",
  endDate = "",
  length,
  start,
  ticket_status,
}) {
  http.setToken(auth.getToken());
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/admin/${api_name}?type=${type}&search=${search}&order=${order}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}&columns=${columns}&ticket_status=${ticket_status}`
  );
  return response.data.data;
}

async function getGuestListing({
 
  search,
  order,
  columns = "",
  startDate = "",
  endDate = "",
  length,
  start,
}) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL +
      `/admin/frontdesk/guest_access?&search=${search}&order=${order}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}&columns=${columns}`
  );
  return response.data;
}

async function getRoleListing() {
  http.setToken(auth.getToken());
  const response = await http.get(process.env.REACT_APP_API_URL + `/admin/roles`);
  return response.data;
}

async function getBookingListing({
  type,
  search,
  order,
  startDate,
  endDate,
  length,
  start,
}) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL +
      `/admin/frontdesk/booking?type=${type}&search=${search}&order=${order}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}`
  );
  return response.data.data;
}

async function getDayListing({
  type,
  search,
  order,
  startDate,
  endDate,
  length,
  start,
}) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL +
      `/admin/setting/day_setting?type=${type}&search=${search}&order=${order}&start_date=${startDate}&end_date=${endDate}&length=${length}&start=${start}`
  );
  return response.data.data;
}

async function createUser({ payload, type }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/user?type=${type}`,
    payload
  );
  return response.data;
}

async function createGuest({ payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/frontdesk/create_guest_access`,
    payload
  );
  return response.data;
}

async function updateDay({ id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/setting/update_day_setting`,
    payload
  );
  return response.status;
}

async function createDay({ payload, type }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/setting/create_day_setting`,
    payload
  );
  return response.data;
}

async function getUserInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(process.env.REACT_APP_API_URL + `/admin/user/${id}`);
  return response.data.data;
}


async function updateUser({id,payload}) {
  http.setToken(auth.getToken());
  const response = await http.put(process.env.REACT_APP_API_URL + `/admin/user/${id}`, payload);
  return response;
}

async function getTicketInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(process.env.REACT_APP_API_URL + `/admin/ticket/${id}`);
  return response.data.data;
}

async function getPlanningInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(process.env.REACT_APP_API_URL + `/admin/my_planning/${id}`);
  return response.data.data;
}

async function getScanPlanningInfo(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(process.env.REACT_APP_API_URL + `/admin/scan_my_planning_info`,{reference_no : payload});
  return response.data.data;
}

async function scanPlanning({payload}) {
  http.setToken(auth.getToken());
  const response = await http.post(process.env.REACT_APP_API_URL + `/admin/merchant/scan_my_planning`,payload);
  return response.data;
}


async function getPlanningHistory({
  type,
  search,
  startDate,
  endDate,
  length,
  start,
}) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL +
      `/admin/merchant/planning_history?type=${type}&search=${search}&start_date=${startDate}&end_date=${endDate}&length=${length}&start=${start}`
  );
  return response.data.data;
}

async function getServiceRequestInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/admin/room_service_request/${id}`
  );
  return response.data.data;
}

async function getServiceCategoryInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/admin/room_service_category/${id}`
  );
  return response.data.data;
}

async function createRoom({ payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/room`,
    payload
  );
  return response.data.data;
}

async function updateRoom({ payload ,id}) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/room_update/${id}`,
    payload
  );
  return response;
}

async function getRoomInfo(id) {
  http.setToken(auth.getToken());
  const response = await http.get(process.env.REACT_APP_API_URL + `/admin/room/${id}`);
  return response.data.data;
}

async function updateTicket({ id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/ticket_update/${id}`,
    payload
  );
  return response.status;
}

async function getWithdrawalListing({
  type,
  search,
  order,
  startDate,
  endDate,
  length,
  start,
}) {
  http.setToken(auth.getToken());
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/admin/withdrawal?type=${type}&search=${search}&order=${order}&start_date=${startDate}&end_date=${endDate}&length=${length}&start=${start}`
  );
  return response.data.data;
}

async function updateWithdrawal({ id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.put(
    process.env.REACT_APP_API_URL + `/admin/withdrawal/${id}`,
    payload,
  );
  return response.status;
}

async function createTicketForm({ payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/ticket`,
    payload
  );
  return response.data.data;
}

async function updateInfo({ api_name, id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/${api_name}/${id}`,
    payload
  );
  return response.data;
}

async function updateServiceCategory({ api_name, payload }) {
  http.setToken(auth.getToken());
  const response = await http.put(
    process.env.REACT_APP_API_URL + `/admin/${api_name}/1`,
    payload
  );
  return response.data;
}

async function updateServiceRequest({ payload ,id}) {
  http.setToken(auth.getToken());
  const response = await http.put(
    process.env.REACT_APP_API_URL + `/admin/room_service_request/${id}`,
    payload 
  );
  return response.data;
}

async function updateRoomCheckIn({ id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/frontdesk/check_in`,
    payload
  );
  return response.status;
}
async function updateRoomCheckout({ id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/frontdesk/check_out`,
    payload
  );
  return response.status;
}

async function updateRoomClean({ id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/frontdesk/booking/${id}`,
    payload
  );
  return response.status;
}

async function createService({ api_name, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/${api_name}`,
    payload
  );
  return response.data;
}

async function updateListing({ id, payload }) {
  http.setToken(auth.getToken());
  const response = await http.put(
    process.env.REACT_APP_API_URL + `/admin/user/${id}`,
    payload
  );
  return response.status;
}

async function userStatusToggle({ userId }) {
  http.setToken(auth.getToken());
  const response = await http.patch(
    process.env.REACT_APP_API_URL + `/admin/user/${userId}`
  );
  return response.status;
}
async function userRoomStatusToggle({ userId }) {
  http.setToken(auth.getToken());
  const response = await http.patch(
    process.env.REACT_APP_API_URL + `/admin/room/${userId}`
    , {id: userId , method: "PATCH" }
  );
  return response.status;
}

async function ticketStatusToggle(payload) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/ticket_update/${payload.id}`,
    { id: payload.id, ticket_type: "ticket", method: "PATCH" }
  );
  return response.status;
}

async function ServiceCategoryStatusToggle({ type, id }) {
  http.setToken(auth.getToken());
  const response = await http.patch(
    process.env.REACT_APP_API_URL + `/admin/room_service_category/1`,
    {
      type: type,
      id: id,
    }
  );
  return response.status;
}

async function scanMyPlanning({ api_name, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/${api_name}/scan_my_planning`,
    payload
  );
  return response.data;
}
async function scanQr({ api_name, payload }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/${api_name}/scan_qr`,
    payload
  );
  return response.data;
}

// report  
async function summaryReport({type = "",
search,
order,
startDate = "",
endDate = "",
length,
start, 
status,
wallet_type,}) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/payment?type=${type}&search=${search}&order=${order}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}&status=${status}&wallet_type=${wallet_type}`
  );
  return response.data.data;
}
async function CreditReport({ type = "",
search,
order,
startDate = "",
endDate = "",
length,
start, }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/credit_transaction_report?type=${type}&search=${search}&order=${order}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}`
  );
  return response.data.data;
}
async function walletReport({ type = "",
search,
order,
startDate = "",
endDate = "",
length,
start,
status,
columns, }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/wallet?type=${type}&search=${search}&order=${order}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}&columns=${columns}&status=${status}`
  );
  return response.data.data;
}
async function RoomServiceReport({type = "",
search,
order,
startDate = "",
endDate = "",
length,
start, }) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/payment?type=${type}&search=${search}&order=${order}&startDate=${startDate}&endDate=${endDate}&length=${length}&start=${start}`
  );
  return response.data.data;
}

//

async function bannerSetting() {
  http.setToken(auth.getToken());
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/setting/banner_setting`
  );
  return response.data;


}
async function CreateBanner({payload}) {
  http.setToken(auth.getToken());
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/admin/setting/create_banner_setting`,payload
  );
  return response;
}
async function DeleteBanner(id) {
  http.setToken(auth.getToken());
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/admin/media/${id}`
  );
  return response;
}
async function DeleteRoom(id) {
  http.setToken(auth.getToken());
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/admin/room/${id}`
  );
  return response;
}
async function DeleteTicket({payload, id}) {
  http.setToken(auth.getToken());
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/admin/ticket/${id}?id=${id}&ticket_type=${payload.ticket_type}`,
    );
  return response;
}
async function DeleteRoomService(id) {
  http.setToken(auth.getToken());
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/admin/room_service_category/${id}`
  );
  return response;
}

async function getVersion() {
  http.setToken(auth.getToken());
  const response = await http.get(process.env.REACT_APP_API_URL + `/api_version`);
  return response.data.data;
}

async function changePassword({payload}) {
  http.setToken(auth.getToken());
  const response = await http.post(process.env.REACT_APP_API_URL + `/admin/admin_change_password` ,payload);
  return response;
}




export default {
  getRoomInfo,
  updateInfo,
  getTicketInfo,
  getPlanningHistory,
  getUserInfo,
  createUser,
  createService,
  getAllListing,
  getGuestListing,
  getRoleListing,
  getDayListing,
  getWithdrawalListing,
  updateDay,
  getBookingListing,
  createTicketForm,
  updateUser,
  userStatusToggle,
  getServiceRequestInfo,
  ticketStatusToggle,
  updateServiceCategory,
  updateServiceRequest,
  getServiceCategoryInfo,
  ServiceCategoryStatusToggle,
  updateListing,
  createDay,
  updateTicket,
  updateWithdrawal,
  updateRoomClean,
  updateRoomCheckIn,
  updateRoomCheckout,
  createRoom,
  updateRoom,
  userRoomStatusToggle,
  getPlanningInfo,
  scanPlanning,
  scanMyPlanning,
  scanQr,
  summaryReport,
  walletReport,
  bannerSetting,
  CreateBanner,
  DeleteBanner,
  createGuest,
  DeleteRoom,
  DeleteTicket,
  DeleteRoomService,
  getVersion,
  CreditReport,
  RoomServiceReport,
  getScanPlanningInfo,
  changePassword,
};
