import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Image } from "primereact/image";
import _ from "lodash";

export default function FileUpload({
  label,
  name,
  disabled,
  placeholder,
  index,
  setChangeImageStatus,
}) {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <MDBRow className="form-group">
      {label ? (
        <MDBCol className="col-12 col-md-3 form-label">
          <label htmlFor={name}>{label}</label>
        </MDBCol>
      ) : (
        ""
      )}
      {name === `expectation[${index}].image` || name === `sub[${index}]` ? (
        <MDBCol>
          {name === `expectation[${index}].image` ? (
            values.expectation[index].image !== "" ? (
              <div className="image-container">
                {/* <div className="background"></div> */}
                <Image
                  src={
                    typeof values.expectation[index].image == "object"
                      ? URL.createObjectURL(values.expectation[index].image)
                      : values.expectation[index].image
                  }
                  width="150"
                  preview
                  // height="100%"
                />
                {/* <button
             type="button"
             className="image-remove-button"
             onClick={() => setFieldValue(name, "")}
           >
             Remove
           </button> */}
              </div>
            ) : (
              <>
                <input
                  className="form-control"
                  type="file"
                  id={name}
                  name={name}
                  accept="image/png, image/jpeg,image/jpg"
                  placeholder={placeholder}
                  onChange={(event) => {
                    setFieldValue(name, event.target.files[0]);
                  }}
                />
                {errors && errors[name] ? (
                  <div className="text-danger text-right no-padding error-message">
                    {errors[name]}
                  </div>
                ) : null}
              </>
            )
          ) : values.sub[index] !== undefined && values.sub[index] !== null ? (
            <div className="image-container">
              {/* <div className="background"></div> */}
              <Image
                src={
                  typeof values.sub[index] === "object"
                    ? URL.createObjectURL(values.sub[index])
                    : values.sub[index]
                }
                width="150"
                preview
                // height="100%"
              />
              {/* <button
             type="button"
             className="image-remove-button"
             onClick={() => setFieldValue(name, "")}
           >
             Remove
           </button> */}
            </div>
          ) : (
            <>
              <input
                className="form-control"
                type="file"
                id={name}
                name={name}
                accept="image/png, image/jpeg"
                placeholder={placeholder}
                onChange={(event) => {
                  setFieldValue(name, event.target.files[0]);
                  // handlerChange(event);
                }}
              />
              {errors && errors[name] ? (
                <div className="text-danger text-right no-padding error-message">
                  {errors[name]}
                </div>
              ) : null}
            </>
          )}
        </MDBCol>
      ) : (
        <MDBCol>
          {values[name] !== null ? (
            <div className="image-container">
              {disabled ? "" : <div className="background"></div>}
              <Image
                src={
                  typeof values[name] == "object"
                    ? URL.createObjectURL(values[name])
                    : values[name]
                }
                width="150"
                preview={disabled ? true : false}
                // height="100%"
              ></Image>
              {disabled ? (
                ""
              ) : (
                <button
                  type="button"
                  className="image-remove-button"
                  onClick={() => {
                    setFieldValue(name, null);
                    setChangeImageStatus(true);
                  }}
                >
                  Remove
                </button>
              )}
            </div>
          ) : (
            <>
              <input
                className="form-control"
                type="file"
                id={name}
                name={name}
                accept="image/png, image/jpeg"
                placeholder={placeholder}
                onChange={(event) => {
                  setFieldValue(name, event.target.files[0]);
                  // handlerChange(event);
                }}
              />
              {errors && errors[name] ? (
                <div className="text-danger text-right no-padding error-message">
                  {errors[name]}
                </div>
              ) : null}
            </>
          )}
        </MDBCol>
      )}
    </MDBRow>
  );
}
