import { MDBContainer } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";

//common component
import Header from "../../components/header/header-one";
import Sidebar from "../../components/sidebar/sidebar-one";

//api service
import apiServices from "../../services/api.services";
import useWindowDimensions from "../../components/common/window-dimension";

//component
import Setting from "./component/agent.setting";

export default function AgentSetting() {
  const [openSideBar, setOpenSideBar] = useState(true);

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar);
  };

  //data for api
  const [type, setType] = useState("user"); //control role
  const [start, setStart] = useState(0); //paginate
  const [length, setLength] = useState(10); //paginate
  const [order, setOrder] = useState("desc"); // asc or desc
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(); //filter date
  const [endDate, setEndDate] = useState(); //filter date
  //date for render
  const [listingData, setListingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [displayRecords, setDisplayRecords] = useState(0);
  //handle modalbox
  const [actionValue, setActionValue] = useState(false);

  //handle apply filter
  const [handleApplyFilter, setHandleApplyFilter] = useState(false);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (width >= "767") {
      setOpenSideBar(true);
    } else {
      setOpenSideBar(false);
    }
  }, [width]);

  const getListing = async () => {
    const response = await apiServices.getAllListing({
      api_name: "room",
      type,
      search,
      order,
      startDate,
      endDate,
      length,
      start,
    });
    if (response) {
      setListingData(response.aaData);
      setTotalRecords(response.iTotalRecords);
      setDisplayRecords(response.iTotalDisplayRecords);
    }
  };

  useEffect(() => {
    getListing();
  }, [search, length, handleApplyFilter]);

  return (
    <MDBContainer className="main-container" breakpoint="none">
      <div className="grid-container">
        <Sidebar isOpen={openSideBar} handleSideBar={handleOpenSideBar} />

        <div className="page-container">
          <Header handleSideBar={handleOpenSideBar} />
          <Setting
            length={length}
            start={start}
            listingData={listingData}
            totalRecords={totalRecords}
            displayRecords={displayRecords}
            setListingData={setListingData}
            setSearch={setSearch}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setOrder={setOrder}
            setLength={setLength}
            setStart={setStart}
            getListing={getListing}
            setHandleApplyFilter={setHandleApplyFilter}
          />
        </div>
      </div>
    </MDBContainer>
  );
}
