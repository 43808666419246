import { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import { Rating } from "primereact/rating";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import { Image } from "primereact/image";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import Select from "../../../components/element/select";
import apiServices from "../../../services/api.services";
import FileUpload from "../../../components/element/file";

import Input from "../../../components/element/input";
import { ColorPicker } from "primereact/colorpicker";

export default function EditModalBoxStep2({
  show,
  close,
  selectedData,
  getListing,
  getInfoDetail,
}) {
  const [data, setData] = useState({
    method: "PUT",
    original_price: selectedData.original_price
      ? selectedData.original_price
      : "100",
    operation_time: "1",
    type: "t",
    current_price: selectedData ? selectedData.current_price : "",
    room_costing: selectedData.room_costing ? selectedData.room_costing : "",
    peak_price: selectedData.peak_price ? selectedData.peak_price : "",
    nonpeak_price: selectedData.nonpeak_price ? selectedData.nonpeak_price : "",
    superpeak_price: selectedData.superpeak_price
      ? selectedData.superpeak_price
      : "",
    quantity: selectedData.quantity ? selectedData.quantity : "",
    step: 2,
  });

  const useSchema = Yup.object({
    // original_price: Yup.string().matches(
    //   /^[0-9]+$/,
    //   "Original Price must enter numbers "
    // ),
    // current_price: Yup.string().matches(
    //   /^[0-9]+$/,
    //   "Display Price must enter numbers "
    // ),
    room_costing: Yup.string().matches(
      /^[0-9]+$/,
      "Room Costing must enter numbers "
    ),
    peak_price: Yup.string().matches(
      /^[0-9]+$/,
      "Peak Price must enter numbers "
    ),
    nonpeak_price: Yup.string().matches(
      /^[0-9]+$/,
      "Nonpeak Price must enter numbers "
    ),
    superpeak_price: Yup.string().matches(
      /^[0-9]+$/,
      "Superpeak Price must enter numbers "
    ),
    quantity: Yup.string().matches(/^[0-9]+$/, "quantity must enter numbers "),
  });

  const [changeImageStatus, setChangeImageStatus] = useState(false);
  const [changeImageStatusSub, setChangeImageStatusSub] = useState(false);
  const [changeImageStatusExpecation, setChangeImageStatusExpecation] =
    useState(false);

  const handleSubmit = async (values, setFieldError) => {
    const formData = new FormData();
    for (let key in values) {
      if (values[key]) {
        formData.append(key, values[key]);
      }
    }
    try {
      const response = await apiServices.updateRoom({
        id: selectedData.id,
        payload: formData,
      });
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Edit Room Success",
          icon: "success",
        });
        close();
        getListing();
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };
  const modalTopRef = useRef();
  const scrollToTop = () => {
    modalTopRef.current?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    });
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 500);
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={data}
        validationSchema={useSchema}
        onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
          handleSubmit(values, setFieldError, setSubmitting);
        }}
      >
        {({ handleReset, values, setFieldValue, errors }) => (
          <>
            <Form className="modal-form">
              <Input
                label="Original Price"
                name="original_price"
                type="number"
              />
              <Input label="Display Price" name="current_price" type="number" />
              <Input label="Room Costing" name="room_costing" type="number" />
              <Input label="Quantity" name="quantity" type="number" />
              <Input label="Normal" name="nonpeak_price" type="number" />
              <Input label="Peak Session" name="peak_price" type="number" />
              <Input
                label="Super Peak Session"
                name="superpeak_price"
                type="number"
              />
              <Button name="Submit" type="submit" />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
