import { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

export default function CreateModalBox({ show, close, getListing }) {
  const [data, setData] = useState({
    title: "",
  });

  const useSchema = Yup.object({
    title: Yup.string(),
  });

  const handleSubmit = async (values) => {
    const payload = {
      type: "room_service_categories",
      title: values.title,
    };

    try {
      const response = await apiServices.createService({
        api_name: "room_service_category",
        payload: payload,
      });
      if (response) {
        close();
        getListing();
        sweetAlertHelper({
          title: "Success",
          text: "Add New Categories Success",
          icon: "success",
        });
      }
    } catch {}
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="small-modal create-modal" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Create Category</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, values, setFieldValue }) => (
                <Form className="modal-form">
                  <Input name="title" label="Title" />
                  <Button name="submit" type="submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
