import { useDebugValue, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import Select from "../../../components/element/select";

export default function UpdateModal({
  show,
  close,
  title,
  getInfoDetail,
  getListing,
  usedId,
}) {
  const [data, setData] = useState({
    type: "",
    id: usedId.room_number ? usedId.room_number : "",
  });
  const useSchema = Yup.object({
    // type: Yup.string().required("Please select your type"),
    // type: Yup.string().required("Please select your type"),
  });

  const handleSubmit = async (values, setFieldError) => {
    try {
      const response = await apiServices.updateRoomClean({
        id: usedId.id,
        payload: { id: usedId.id, type: "update" },
      });
      if (response == 200) {
        sweetAlertHelper({
          title: "Success",
          text: "Complete Job Successfully",
          icon: "success",
        });
        getListing();
        close();
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };
  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="create-modalbox" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, errors }) => (
                <Form className="modal-form">
                  <div className="text-center">
                    <h6>Room Number</h6>
                    <h1>{usedId.room_number}</h1>
                    <label style={{ fontSize: "0.8rem" }}>
                      Room Type : {usedId.title}
                    </label>
                  </div>
                  {/* <Input label="Room Number" name="id" disabled /> */}
                  {/* <Select
                    name="type"
                    label="Update"
                    options={type}
                    placeholder="- Please Select -"
                    errors={errors}
                  /> */}
                  <Button
                    classNameButton="text-center pt-5"
                    type="submit"
                    name="Complete"
                  />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
