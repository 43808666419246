import { useState, useEffect } from "react";
import moment from "moment";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function HistoryListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
}) {
  const [date, setDate] = useState(new Date());

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(e.value[0]);
          setEndDate(e.value[1]);
          // options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    { field: "title", header: "Title" },
    { field: "username", header: "User Name" },
    { field: "type", header: "Type" },
    { field: "amount", header: "Amount(RM)" },
    { field: "ticket_status", header: "Status" },
    {
      field: "created_at",
      header: "Created Time",
      //   filterElement: filterDateTemplate,
    },
    {
      field: "expired_at",
      header: "Expired Time",
      //   filterElement: filterDateTemplate,
    },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Planning History"
        data={listingData}
        columns={columns}
        setSearchValue={setSearch}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
// useEffect(() => {
// const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY,){
//   cluster: "ap1",
//   authEndpoint:process.env.REACT_APP_SOCKET_API_URL,
//   auth:{
//     headers:{
//       Accept: "",
//     }
//   }
// }

// const channel = purher.subscribe("prensence-bet-type")+ tableType
//  };
//  ver socketId = null ;
//  pusher.connection.bind("connected"),() => {
//   const result = data.data;
//   const MySwal = withReactContent(Swal);
//   if(result){
//     ""
//   }
//  }
//  )
