import { useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/element/input";

//api
import apiServices from "../../../services/api.services";

export default function InfoModalBox({
  show,
  close,
  selectedData,
  getListing,
}) {
  const [check, setCheck] = useState("");
  const [bookingData, setBookingData] = useState({
    ic: "",
    guest_name: "",
    room_number: "",
    qr: selectedData ? selectedData.payment_info.qr : "",
  });

  const bookingSchema = Yup.object({
    room_number: Yup.string().required("Required"),
    ic: Yup.string().required("Required"),
    guest_name: Yup.string().required("Required"),
  });

  const handleSubmit = async (values, setFieldError, type) => {
    if (check === "post") {
      try {
        const response = await apiServices.updateRoomCheckIn({
          payload: values,
        });
        if (response) {
          sweetAlertHelper({
            title: "Success",
            text: "Check IN  Success",
            icon: "success",
          });
          close(false);
          getListing();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
            }
          }
        }
      }
    }
    if (check === "out") {
      try {
        const response = await apiServices.updateRoomCheckout({
          payload: values.qr,
        });
        if (response) {
          sweetAlertHelper({
            title: "Success",
            text: "Check Out  Success",
            icon: "success",
          });
          close(false);
          getListing();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
            }
          }
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={bookingData}
      validationSchema={bookingSchema}
      onSubmit={(values, { setFieldError, resetForm, setSubmitting }) => {
        handleSubmit(values, setFieldError, setSubmitting);
      }}
    >
      {({ handleReset, values, setFieldValue }) => (
        <Form>
          <MDBModal staticBackdrop tabIndex="-1" show={show}>
            <MDBModalDialog scrollable>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Information-{selectedData.id}</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => close((prevState) => !prevState)}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="booking-body">
                  <MDBRow className="booking-title">
                    <label>Booking ID : {selectedData.order_number}</label>
                  </MDBRow>
                  <MDBRow className="booking-title">
                    <label>Customer Email : {selectedData.email}</label>
                  </MDBRow>
                  <MDBRow className="booking-title">
                    <label>Room Type : {selectedData.title}</label>
                  </MDBRow>
                  <MDBRow className="booking-title">
                    <label>Guest info</label>
                  </MDBRow>
                  <MDBContainer className="booking-container">
                    <MDBRow className="booking-text">
                      {selectedData.guest_name === null ? (
                        <Input label="Guest Name :" name="guest_name" />
                      ) : (
                        <>
                          <MDBCol>
                            <label>Staying Guest Name :</label>
                          </MDBCol>
                          <MDBCol>{selectedData.guest_name}</MDBCol>
                        </>
                      )}
                    </MDBRow>
                    <MDBRow className="booking-text">
                      {selectedData.room_number === null ? (
                        <Input label="Room No :" name="room_number" />
                      ) : (
                        <>
                          <MDBCol>
                            <label>Assigning Room No :</label>
                          </MDBCol>
                          <MDBCol>{selectedData.room_number}</MDBCol>
                        </>
                      )}
                    </MDBRow>
                    <MDBRow className="booking-text">
                      {selectedData.ic === null ? (
                        <Input label="IC /No:" name="ic" />
                      ) : (
                        <>
                          <MDBCol>
                            <label>IC/No:</label>
                          </MDBCol>
                          <MDBCol>{selectedData.ic}</MDBCol>
                        </>
                      )}
                    </MDBRow>

                    <MDBRow className="booking-text">
                      {selectedData.checked_in_at === null ? (
                        <MDBBtn
                          type="submit"
                          className="btt-check"
                          onClick={() => setCheck("post")}
                        >
                          Chenk in
                        </MDBBtn>
                      ) : (
                        <>
                          <MDBCol>
                            <label>Check In At :</label>
                          </MDBCol>
                          <MDBCol>{selectedData.checked_in_at}</MDBCol>
                        </>
                      )}
                    </MDBRow>

                    <MDBRow className="booking-text">
                      {selectedData.checked_in_at !== null ? (
                        selectedData.checked_out_at === null ? (
                          <>
                            <MDBCol>
                              <label>Check Out At :</label>
                            </MDBCol>
                            <MDBCol> {selectedData.checked_out_at}</MDBCol>
                          </>
                        ) : (
                          <>
                            <MDBCol>
                              <label>Check Out At :</label>
                            </MDBCol>
                            <MDBCol> {selectedData.checked_out_at}</MDBCol>
                          </>
                        )
                      ) : (
                        ""
                      )}
                    </MDBRow>
                  </MDBContainer>
                  <MDBRow className="booking-title">
                    <label>Detail </label>
                  </MDBRow>
                  <MDBContainer className="booking-container">
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Room Type : </label>
                      </MDBCol>
                      <MDBCol> {selectedData.title}</MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Stay Form : </label>
                      </MDBCol>
                      <MDBCol>{selectedData.checked_in_at}</MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Stay To :</label>
                      </MDBCol>
                      <MDBCol>
                        <label> {selectedData.checked_out_at}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Pax : </label>
                      </MDBCol>
                      <MDBCol>
                        <label> {selectedData.pax}</label>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                  <MDBRow className="booking-title">
                    <label>Customer Info</label>
                  </MDBRow>
                  <MDBContainer className="booking-container">
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>First name :</label>
                      </MDBCol>
                      <MDBCol>
                        <label> {selectedData.first_name}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Last name : </label>
                      </MDBCol>
                      <MDBCol>
                        <label> {selectedData.last_name}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Phone number:</label>
                      </MDBCol>
                      <MDBCol>
                        <label>{selectedData.phone_number}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Email :</label>
                      </MDBCol>
                      <MDBCol>
                        <label>{selectedData.email}</label>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                  <MDBRow className="booking-title">
                    <label>Billing Info</label>
                  </MDBRow>
                  <MDBContainer className="booking-container">
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Total Paid : RM</label>
                      </MDBCol>
                      <MDBCol>
                        <label>
                          {parseFloat(
                            selectedData.payment_info.total_paid
                          ).toFixed(2)}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Order ID:</label>
                      </MDBCol>
                      <MDBCol>
                        <label>{selectedData.oid}</label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>Purchase On :</label>
                      </MDBCol>
                      <MDBCol>{selectedData.payment_info.paid_at}</MDBCol>
                    </MDBRow>
                    <MDBRow className="booking-text">
                      <MDBCol>
                        <label>User Before :</label>
                      </MDBCol>{" "}
                      <MDBCol>{selectedData.payment_info.use_before}</MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </Form>
      )}
    </Formik>
  );
}
