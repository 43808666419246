import { useState, useEffect, useRef } from "react";
import { MDBCol, MDBIcon } from "mdb-react-ui-kit";
import config from "../../config.json";
//primereact
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

//component
import ToolbarTable from "./toolbar";
import { Icon } from "@iconify/react";
import Gif from "../../assets/fonts/loader/loader.gif";

//pagination
import ReactPaginate from "react-paginate";
import apiServices from "../../services/api.services";

export default function ListingTable({
  title,
  data,
  columns,
  createAble,
  setSearchValue,
  setHandleApplyFilter,
  setOrder,
  length,
  start,
  setLength,
  setStart,
  totalRecords,
  displayRecords,
  onRowEditComplete,
  bookingList,
  changeDate,
  dateLabel,
  userData,
  user,
}) {
  const [loader, setLoader] = useState(false);
  const role = localStorage.getItem("roles_id");
  const tableRef = useRef();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedListing, setSelectedListing] = useState(null);
  const [search, setSearch] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    last_active_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lastLoginedAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
    suspect_status: { value: null, matchMode: FilterMatchMode.EQUALS },
    create_at: { value: null, matchMode: FilterMatchMode.EQUALS },
    send_at: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const filtersMap = {
    filters: { value: search, callback: setSearch },
  };

  const onGlobalFilterChange = (event, filtersKey) => {
    const value = event.target.value;
    let filters = { ...filtersMap[filtersKey].value };
    filters["global"].value = value;

    filtersMap[filtersKey].callback(filters);
    setSearchValue(value);
  };

  const exportCSV = (selectionOnly) => {
    tableRef.current.exportCSV({ selectionOnly });
  };

  const renderHeader = (filtersKey) => {
    const filters = filtersMap[`${filtersKey}`].value;
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div>
        <ToolbarTable
          userData={userData}
          dateLabel={dateLabel}
          exportCSV={exportCSV}
          changeDate={changeDate}
          value={value}
          filters={filters}
          filtersKey={filtersKey}
          setFilters={setSearch}
          onGlobalFilterChange={onGlobalFilterChange}
          createAble={createAble}
          user={user}
          selectedListing={selectedListing}
          bookingList={bookingList}
        />
      </div>
    );
  };

  const onCustomPage = (event) => {
    setStart(event.first);
    setLength(event.rows);
    return data;
  };

  const handleChange = (data) => {
    let countstart = data.selected * length;
    setStart(countstart);

    return data;
  };

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: totalRecords },
      ];

      return (
        <MDBCol className="col-12 col-md-6 p-0">
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </MDBCol>
      );
    },
    CurrentPageReport: (options) => {
      const pageCount = Math.ceil(
        displayRecords == undefined ? 0 : displayRecords / length
      );
      return (
        <MDBCol className="col-12 col-md-6 p-0">
          <ReactPaginate
            previousLabel={<Icon icon="ep:arrow-left-bold" />}
            nextLabel={<Icon icon="ep:arrow-right-bold" />}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handleChange}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeclassname={"active"}
          />
        </MDBCol>
      );
    },
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    switch (field) {
      default:
        if (newValue.trim().length > 0) {
          rowData[field] = newValue;
        } else event.preventDefault();
        break;
    }
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="decimal"
        minFractionDigits={2}
      />
    );
  };

  const cellEditor = (options) => {
    switch (options.field) {
      case "current_price":
        return priceEditor(options);
      case "original_price":
        return priceEditor(options);
      default:
        return textEditor(options);
    }
  };

  const onFilterApplyClick = () => {
    setHandleApplyFilter((preState) => !preState);
  };

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
    const sortDir = event.order;
    switch (sortDir) {
      case 1:
        setOrder("desc");
        break;
      case -1:
        setOrder("desc");
        break;
    }
    return data;
  };

  const header = renderHeader("filters");
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  return (
    <div className="listing-table-modal">
      <MDBCol className="listing-title">
        <h3>{title}</h3>
        <p>Today : {date}</p>
      </MDBCol>
      <DataTable
        ref={tableRef}
        value={data}
        header={header}
        className="editable-cells-table"
        responsiveLayout="stack"
        breakpoint="780px"
        resizableColumns
        // columnResizeMode="fit"
        showGridlines
        // scrollable
        // scrollHeight="400px"
        // loading={loading}
        //filter
        // filters={search} //frontend handle filter
        onFilter={(e) => setSearch(e.filters)}
        emptyMessage="No customers found."
        //pagination
        paginator
        paginatorPosition="top"
        paginatorTemplate={
          config.operation_roles.includes(role) ? null : template2
        }
        totalRecords={totalRecords}
        // first={start}
        rows={config.operation_roles.includes(role) ? 99999 : length}
        // currentPageReportTemplate="Showing {first} to {last} of {totalPages}"
        onPage={onCustomPage}
        rowsPerPageOptions={[10, 20, 50]}
        //selection
        selectionMode="checkbox"
        selectionPageOnly
        selectionAutoFocus={false}
        selection={selectedListing}
        onSelectionChange={(e) => setSelectedListing(e.value)}
        //edit
        editMode="row"
        onRowEditComplete={onRowEditComplete}
      >
        {columns.map(
          (
            {
              field,
              header,
              body,
              className,
              filterElement,
              selectionMode,
              headerStyle,
              editor,
              rowEditor,
              sortable,
              frozen,
            },
            index
          ) => {
            return (
              <Column
                key={index}
                field={field}
                header={header}
                body={body && body}
                frozen={frozen && frozen}
                className={className && className}
                //filter mode
                filter={filterElement ? true : false}
                showFilterMenu={true}
                showFilterMatchModes={false}
                filterElement={filterElement && filterElement}
                showAddButton={false}
                showFilterMenuOptions={false}
                onFilterApplyClick={onFilterApplyClick}
                //edit mode = "cell"
                editor={editor ? (options) => cellEditor(options) : false}
                // onCellEditComplete={onCellEditComplete}
                //edit mode = "row"
                rowEditor={rowEditor ? rowEditor : false}
                //row selection mode
                selectionMode={selectionMode ? selectionMode : null}
                //header width styling
                headerStyle={{ width: headerStyle ? headerStyle : null }}
                //sort
                sortable={sortable && sortable}
                // sortableDisabled={true}
                sortFunction={sortable && sortFunction}
              />
            );
          }
        )}
      </DataTable>
    </div>
  );
}
