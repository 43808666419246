import { useEffect, useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";

//formik
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

//element component
import Button from "../../../components/element/button";
import { FieldArray } from "formik";
import apiServices from "../../../services/api.services";

export default function CreateModalBox({
  title,
  show,
  values,
  close,
  disabled,
  getListing,
}) {
  const [data, setData] = useState({
    sub: [null],
  });

  const useSchema = Yup.object({});

  const handleSubmit = async (values, setFieldError) => {
    const formData = new FormData();
    for (let key in values) {
      if (key == "sub") {
        values.sub.map((item) => formData.append("sub[]", item));
      } else {
      }
    }
    try {
      const response = await apiServices.CreateBanner({
        payload: formData,
      });
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
        close(false);
        getListing();
      }
    } catch (ex) {
      const errors = ex.response.data.errors;
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close((prevState) => !prevState)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({
                handleReset,
                errors,
                isSubmitting,
                setFieldValue,
                values,
              }) => (
                <Form className="modal-form">
                  <FieldArray name="sub">
                    {({ insert, remove, push }) => (
                      <MDBRow className="form-group">
                        <MDBCol className="col-12 col-md-3 form-label">
                          <label>Room Image</label>
                        </MDBCol>
                        <MDBCol>
                          {values.sub.length > 0 &&
                            values.sub.map((tag, index) => (
                              <MDBRow key={index} className="m-0">
                                <MDBCol className="p-0">
                                  <input
                                    className="form-control"
                                    type="file"
                                    id={`sub.${index}`}
                                    name={`sub.${index}`}
                                    accept="image/*"
                                    onChange={(event) => {
                                      setFieldValue(
                                        `sub.${index}`,
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                  {errors && errors[`sub.${index}`] ? (
                                    <div className="text-danger text-right no-padding error-message">
                                      {errors[`sub.${index}`]}
                                    </div>
                                  ) : null}
                                </MDBCol>
                                <MDBCol className="col-1 button-col">
                                  <button
                                    type="button"
                                    className="form-group remove-button"
                                    onClick={() => remove(index)}
                                  >
                                    X
                                  </button>
                                </MDBCol>
                              </MDBRow>
                            ))}
                          <button
                            type="button"
                            className="form-group add-button"
                            onClick={() => push()}
                          >
                            Add SUB
                          </button>
                        </MDBCol>
                      </MDBRow>
                    )}
                  </FieldArray>
                  <Button name="Next" type="submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
