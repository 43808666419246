import { useState } from "react";
import moment from "moment";
import { MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../components/modal/listingTable";

export default function CommissionListing({}) {
  const [date, setDate] = useState(new Date());

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    // { field: "name", header: "Name" },
    // { field: "pax", header: "Room for Pax " },
    // { field: "size", header: "Room Size" },
    // {
    //   field: "current_price",
    //   header: "Current Selling Price",
    //   editor: true,
    // },
    // {
    //   field: "description",
    //   header: "Change Status",
    //   editor: true,
    // },
    // { field: "room_unit", header: "Room Unit" },
    // {
    //   field: "status",
    //   header: "Status",
    //   body: statusBodyTemplate,
    // },
    // { field: "", body: actionBodyTemplate, headerStyle: "0.5em" },
    // { field: "", body: editBodyTemplate, headerStyle: "1em", header: "Update" },
  ];

  return (
    <div className="listing-container">
      <ListingTable
        title="Comission"
        // data={listingData}
        columns={columns}
        // createAble={createAble}
        // setSearchValue={setSearch}
        // setHandleApplyFilter={setHandleApplyFilter}
        // setOrder={setOrder}
        // length={length}
        // start={start}
        // setLength={setLength}
        // setStart={setStart}
        // totalRecords={totalRecords}
        // displayRecords={displayRecords}
      />
    </div>
  );
}
