import { MDBBadge, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import apiServices from "../../services/api.services";
import { sweetAlertHelper } from "../../helper/sweetalert.helper";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";
import { useEffect } from "react";

export default function InfoTemplate({
  item,
  getInfoDetail,
  handleCreateItemModal,
  setUpdateServiceModalBox,
  setUpdateServiceModalData,
  handleUpdateItemModal,
  selectedData,
  close,
}) {
  const [show, setShow] = useState(true);
  const [editTitle, setEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState("");

  const updateCategoryStatus = async (id) => {
    const response = await apiServices.ServiceCategoryStatusToggle({
      type: "room_service_category_services",
      id: id,
    });
    if (response) {
      getInfoDetail();
    }
  };

  const updateCategoryItemStatus = async (id) => {
    const response = await apiServices.ServiceCategoryStatusToggle({
      type: "room_service_category_service_items",
      id: id,
    });
    if (response) {
      getInfoDetail();
    }
  };

  const handleEditTitle = async () => {
    setEditTitle(false);
    if (newTitle) {
      const payload = {
        type: "room_service_category_services",
        id: item.id,
        title: newTitle,
      };
      try {
        const response = await apiServices.updateServiceCategory({
          api_name: "room_service_category",
          id: item.id,
          payload: payload,
        });
        if (response) {
          setNewTitle("");
          getInfoDetail();
          sweetAlertHelper({
            title: "Success",
            text: "Edit Category Success",
            icon: "success",
          });
        }
      } catch (ex) {}
    }
  };

  const deleteRoomService = async (id) => {
    try {
      const response = await apiServices.DeleteRoomService(item.id);
      if (response) {
        sweetAlertHelper({
          title: "Success",
          text: "Update  Success",
          icon: "success",
        });
        close();
        selectedData();
      }
    } catch (ex) {
      let errorMsg = "";
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              errorMsg = errors[item];
            });
          }
        }
        sweetAlertHelper({
          title: "Error",
          text: `${errorMsg}`,
          icon: "error",
        });
      }
    }
  };

  const confirmDelete = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Do you want to delete this record?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => deleteRoomService(),
    });
  };

  // useEffect(() => {
  //   getInfoDetail();
  // }, []);
  return (
    <MDBCol className="info-template">
      <MDBRow className="listing-header">
        <MDBCol className="header col-8">
          {editTitle ? (
            <Icon
              icon="dashicons:saved"
              width="25"
              onClick={() => handleEditTitle()}
            />
          ) : (
            <Icon
              icon="fluent-mdl2:pen-workspace"
              // icon="mdi:application-edit-outline"
              width="25"
              onClick={() => {
                // setEditTitle(true);
                // setNewTitle(item.title);
                setUpdateServiceModalBox(true);
                setUpdateServiceModalData(item);
              }}
            />
          )}
          {editTitle ? (
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
            />
          ) : (
            <label>{item.title}</label>
          )}
        </MDBCol>
        <MDBCol className="button-icon">
          <InputSwitch
            checked={item.status}
            trueValue="active"
            falseValue="inactive"
            onChange={(e) => updateCategoryStatus(item.id)}
          />
          <Button
            onClick={confirmDelete}
            icon="pi pi-times"
            label="Delete"
            className="p-button-danger p-button-outlined button-delete"
          ></Button>
        </MDBCol>
      </MDBRow>
      <div className={show ? "listing-info active" : "listing-info "}>
        <MDBRow className="title">
          <MDBCol className="col-md-1 edit-button"></MDBCol>
          <MDBCol className="col-md-6 p-0">
            <label>Title</label>
          </MDBCol>
          <MDBCol className="col-md-3 p-0">
            <label>Current</label>
          </MDBCol>
          {/* <MDBCol className="col-md-3">
            <label>Quantity</label>
          </MDBCol> */}
          <MDBCol className="col-md-2 p-0">
            <label
              className="d-flex"
              onClick={() => handleCreateItemModal(item.id)}
            >
              <Icon icon="fluent:add-square-multiple-20-filled" width="25" />
              Add Item
            </label>
          </MDBCol>
        </MDBRow>
        <div className="line"></div>
        {item.room_service_category_service_items.map((data, index) => (
          <div
            key={index}
            className="listing-content"
            style={show ? { display: "block" } : { display: "none" }}
          >
            <MDBRow className="text d-flex align-items-center">
              <MDBCol className="col-md-1 edit-button">
                <Icon
                  icon="fluent-mdl2:pen-workspace"
                  width="18"
                  onClick={() => handleUpdateItemModal(data)}
                />
              </MDBCol>
              <MDBCol className="col-md-6">
                <label>{data.title}</label>
              </MDBCol>
              <MDBCol className="col-md-3 price p-0">
                <label className="current_price">
                  RM{parseFloat(data.current_price).toFixed(2)}
                </label>
                {/* <label className="original_price">
                  RM{parseFloat(data.original_price).toFixed(2)}
                </label> */}
              </MDBCol>
              {/* <MDBCol className="col-3"><label>RM{parseFloat(data.original_price).toFixed(2)}</label></MDBCol> */}
              {/* <MDBCol className="col-2 col-md-3">
                <label>{data.quantity ? data.quantity : 0}</label>
              </MDBCol> */}
              <MDBCol className="col-3 col-md-2 button-toggle d-flex align-items-center">
                <InputSwitch
                  checked={data.status}
                  trueValue="active"
                  falseValue="inactive"
                  onChange={(e) => updateCategoryItemStatus(data.id)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="date">
              {/* <MDBCol className="col-8"><label>Create At : {data.created_at}</label></MDBCol> */}
            </MDBRow>
            <div className="line"></div>
          </div>
        ))}
      </div>
    </MDBCol>
  );
}
