import "./App.scss";
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import apiServices from "./services/api.services";
import { useEffect, useState } from "react";
import config from "./config.json"
import { sweetAlertHelper } from "./helper/sweetalert.helper";
//Pages
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import User from "./pages/User";
import RoomServiceRequest from "./pages/RoomServiceRequest";
import RoomServiceCategory from "./pages/RoomServiceCategory";
import Ticket from "./pages/Ticket";
import Room from "./pages/Room";
import Admin from "./pages/Admin";
import Merchant from "./pages/Merchant";
import Investor from "./pages/Investor";
import AgentSetting from "./pages/AgentSetting";
import SummaryReport from "./pages/Summary";
import CommissionSetting from "./pages/CommissionSetting/inedex";
import Wallet from "./pages/WalletPage";
import Agent from "./pages/Agent";
import AgentRequest from "./pages/AgentRequest";
import TicketReport from "./pages/TicketReport";
import PlanningHistory from "./pages/PlanningHistory";
import Booking from "./pages/Booking";
import BookingCheckOut from "./pages/BookingCheckOut";
import DaySetting from "./pages/DaySetting";
import Withdrawal from "./pages/Withdrawal";
import TicketBooking from "./pages/TicketBooking";
import TicketClaim from "./pages/TicketClaim";
import RoomServiceOrderHistory from "./pages/RoomServiceHistory";
import CreditPurchase from "./pages/CreditPurchase";
import Transaction from "./pages/Transaction";
import RoomHistory from "./pages/RoomHistory";
import TicketTransaction from "./pages/TicketTransaction";
import RoomServiceTransaction from "./pages/RoomServiceTransaction";
import RoomServiceOperationOrder from "./pages/RoomServiceOperationOrder";
import RoomTransaction from "./pages/RoomTransaction";
import Banner from "./pages/BannerSetting";
import RoomJob from "./pages/RoomJob";
import Guest from "./pages/Guest";
import ErrorPage from "./pages/404Page";
import Payment from "./pages/Payment Transaction";

import OneSignal from 'react-onesignal';
import axios from "axios";

function App() {
  const role = localStorage.getItem("roles")
  const token = localStorage.getItem("token")
const [name , setName]=useState("")
  

  useEffect(() => {
    OneSignal.init({
      appId: "d1de3f42-4c5d-4d5a-8dff-90c15738076f",
      // safari_web_id: "web.onesignal.auto.3c5e9739-5d2f-4f69-94b2-78aed3043174",
      // notifyButton: {
      //   enable: true,
      // },
    });
    OneSignal.showSlidedownPrompt();

    OneSignal.on('subscriptionChange', function(isSubscribed) {
      // console.log("The user's subscription state is now:", isSubscribed);
    });

    OneSignal.getUserId(function(userId) {
      setName(userId)
      // console.log("OneSignal User ID:", userId);
    });
  }, [token]);
// const axios = require('axios')

  const apiUrl = 'https://onesignal.com/api/v1/players';
const headers = {
  'Content-Type': 'application/json',
  'accept' : 'application/json'
};

// define user id and tag name to add
const userId = name; // replace with actual user id
// const userId =  "90df671c-e651-4a4a-a37e-a2b528ac5e78"
const tagName = role;
const appId = "d1de3f42-4c5d-4d5a-8dff-90c15738076f"

// send API request to add tag to user

useEffect(() => {
  if(userId !== "" &&  role == "housekeeping" ||
  role == "maintenance" ||
  role == "food and beverage"){
  fetch(`${apiUrl}/${userId}`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify({
      'tags': {roles :tagName},
    'app_id' : appId
    })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error(`Failed to add tag ${tagName} to user ${userId}`);
    }
    // console.log(`Tag ${tagName} added to user ${userId}`);
  })
}
} , [token])



  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="*" element={<Dashboard />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        {/* User Managment */}
        <Route path="/user" element={<User />} />
        <Route path="/guest" element={<Guest />} />
        {/* Admin */}
        <Route path="/admin" element={<Admin />} />
        {/* Merchant */}
        <Route path="/merchant" element={<Merchant />} />
        {/* Agent */}
        <Route path="/agent" element={<Agent />} />
        {/* AgentRequest */}
        <Route path="/agent_request" element={<AgentRequest />} />
        {/* AgentSetting */}
        <Route path="/agent_setting" element={<AgentSetting />} />
        {/* Report */}
        <Route path="/reload_transaction" element={<SummaryReport />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/pg_transaction" element={<Wallet />} />
        <Route path="/wallet_transaction" element={<CreditPurchase />} />
        <Route path="/credit_payment_transaction" element={<Payment />} />
        {/* Commission */}
        <Route path="/commission" element={<CommissionSetting />} />
        {/* Investor */}
        {/* <Route path="/investor" element={<Investor />} /> */}
       
        {/* Plannning */}
        <Route path="/planning_history" element={<PlanningHistory />} />
        {/* Room Service Managment */}
        <Route path="/room_service_order" element={<RoomServiceRequest />} />
        <Route path="/room_service_operation_order" element={<RoomServiceOperationOrder />} />
        <Route path="/room_service_order_history" element={<RoomServiceOrderHistory />} />
        <Route path="/room_service_transaction" element={<RoomServiceTransaction />} />
        <Route
          path="/room_service_category"
          element={<RoomServiceCategory />}
        />
        <Route path="/room_revamp" element={<RoomServiceCategory />} />
        {/* Room */}
        <Route path="/room" element={<Room />} />
        <Route path="/room_my_job" element={<RoomJob />} />
        <Route path="/room_history" element={<RoomHistory />} />
        <Route path="/room_transaction" element={<RoomTransaction />} />

        {/* Ticket */}
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/ticket_booking" element={<TicketBooking />} />
        <Route path="/ticket_history" element={<TicketClaim />} />
        <Route path="/ticket_transaction" element={<TicketTransaction />} />
        {/* Ticket Report */}
        <Route path="/ticket_report" element={<TicketReport />} />
        {/* Booking*/}
        <Route path="/booking" element={<Booking />} />
        <Route path="/booking_checkout" element={<BookingCheckOut />} />
        {/* Setting*/}
        <Route path="/day" element={<DaySetting />} />
        <Route path="/banner" element={<Banner />} />

        {/* Withdrawal*/}
        <Route path="/maintenance" element={<ErrorPage />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
