import { useState } from "react";
import moment from "moment";
import { MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

//primereact
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

//component
import ListingTable from "../../../components/modal/listingTable";
import Dropdown from "../../../components/element/dropdown";
import ButtonToggle from "../../../components/element/toggleButton";
import apiServices from "../../../services/api.services";
import ToggleSwitch from "../../../components/element/toggleSwitch";

export default function GuestListing({
  length,
  start,
  createAble,
  listingData,
  totalRecords,
  displayRecords,
  setSearch,
  setStartDate,
  setEndDate,
  setOrder,
  setLength,
  setStart,
  actionValue,
  handleActionChange,
  setHandleApplyFilter,
  getListing,
}) {
  const actionOptions = ["Wallet Log", "Ticket Log"];
  const [date, setDate] = useState(new Date());

  const handleChange = async (userId) => {
    try {
      const response = await apiServices.userStatusToggle({ userId });
      if (response == 200) {
        getListing();
      }
    } catch (ex) {}
  };

  const statusBodyTemplate = (options) => {
    return (
      <InputSwitch
        checked={options.status}
        trueValue="Active"
        falseValue="Inactive"
        onChange={(e) => handleChange(options.id)}
      />
    );
  };

  const phoneBodyTemplate = ({ phone }) => {
    if (phone) {
      return (
        <span>
          {phone.countryCode}
          {phone.phoneNumber}
        </span>
      );
    }
  };

  const dateBodyTemplate = ({ lastLoginedAt }) => {
    if (lastLoginedAt) {
      return <span>{moment(lastLoginedAt).format("DD/MM/YYYY")}</span>;
    }
  };

  const actionBodyTemplate = (options, { rowIndex }) => {
    return (
      <MDBRow className="action-button">
        <div
          style={{ width: "auto" }}
          onClick={() => handleActionChange("information", options)}
        >
          <Icon
            icon="heroicons:clipboard-document-list"
            color="#41B1CD"
            width="25"
            height="25"
          />
        </div>
      </MDBRow>
    );
  };

  const filterDateTemplate = (options) => {
    return (
      <Calendar
        id="range"
        value={date}
        onChange={(e) => {
          setDate(e.value);
          setStartDate(e.value[0]);
          setEndDate(e.value[1]);
          // options.filterCallback(e.value);
        }}
        selectionMode="range"
        readOnlyInput
        showIcon
      />
    );
  };

  const monthlyCheckInBodyTemplate = (options) => {
    return <InputSwitch checked={options.monthly_check_in} disabled />;
  };

  const columns = [
    { selectionMode: "multiple", headerStyle: "3em" },
    {
      field: "room_number",
      header: "Room Number",
    },
    { field: "username", header: "User Name" },
    // {
    //   field: "status",
    //   header: "Status",
    //   body: statusBodyTemplate,
    // },
    { field: "check_in_at", header: "Check In At" },
    { field: "check_out_at", header: "Check Out At" },
    // { field: "", header: "Action", body: actionBodyTemplate },
  ];

  const sortFunction = (event) => {
    const field = event.field.split(".")[0];
  };

  return (
    <div className="listing-container">
      <ListingTable
        title="Guest "
        data={listingData}
        columns={columns}
        createAble={createAble}
        setSearchValue={setSearch}
        setHandleApplyFilter={setHandleApplyFilter}
        setOrder={setOrder}
        length={length}
        start={start}
        setLength={setLength}
        setStart={setStart}
        totalRecords={totalRecords}
        displayRecords={displayRecords}
      />
    </div>
  );
}
