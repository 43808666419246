import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Field, ErrorMessage, useFormikContext } from "formik";

export default function Input({
  row,
  label,
  name,
  disabled,
  placeholder,
  type,
}) {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <MDBRow className="form-group">
      {label ? (
        <MDBCol className="col-12 col-md-3 form-label">
          <label htmlFor={name}>{label}</label>
        </MDBCol>
      ) : (
        ""
      )}
      <MDBCol>
        <Field
          className="form-control"
          type={type ? type : "text"}
          row={row}
          id={name}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
        />

        {errors && errors[name] ? (
          <div className="text-danger text-right no-padding error-message">
            {errors[name]}
          </div>
        ) : null}
      </MDBCol>
    </MDBRow>
  );
}
