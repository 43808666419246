import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Button from "../element/button";
import apiServices from "../../services/api.services";
import { sweetAlertHelper } from "../../helper/sweetalert.helper";
import Input from "../element/input";
import {
  Html5QrcodeScanner,
  Html5QrcodeSupportedFormats,
  Html5Qrcode,
} from "html5-qrcode";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";
export default function QrCodeScanner({ show, close, getListing }) {
  const role = localStorage.getItem("roles");
  const [scannedCodes, setScannedCodes] = useState([]);
  const [price, setPrice] = useState(null);
  const [displayQr, setDisplayQr] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    amount: "",
  });

  const useSchema = Yup.object({
    amount: Yup.string()
      .matches(/^[0-9]+$/, "Amount must enter numbers ")
      .required("Requied"),
  });

  const nextStep = (values) => {
    setPrice(values);
    setDisplayQr(true);
  };

  const handleSubmit = async (values, setFieldError) => {
    close(false);
    if (role === "merchant") {
      try {
        const response = await apiServices.scanQr({
          api_name: role,
          payload: {
            qr: scannedCodes[0].decodedText,
            amount: price,
          },
        });
        if (response) {
          sweetAlertHelper({
            title: "Success",
            text: "Create  Success",
            icon: "success",
          });
          navigate("/credit_payment_transaction");
          getListing();
        }
      } catch (ex) {
        let errorMsg = "";
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
                errorMsg = errors[item];
              });
            }
          }

          sweetAlertHelper({
            title: "Error",
            text: `${errorMsg}`,
            icon: "error",
          });
        }
      }
    } else {
      sweetAlertHelper({
        title: "Error",
        text: "The role does not support the scanning function",
        icon: "error",
      });
    }
  };

  const errorMessage = () => {
    sweetAlertHelper({
      title: "Error",
      text: "The role does not support the scanning function",
      icon: "error",
    });
  };

  useEffect(() => {
    if (role === "merchant") {
      if (scannedCodes[0] && role === "merchant") {
        handleSubmit();
      }
    } else {
      errorMessage();
      close(false);
    }
  }, [scannedCodes]);

  useEffect(() => {
    if (displayQr === true) {
      let html5QrCode = new Html5Qrcode("reader", {
        formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      });
      function onScanSuccess(decodedText, decodedResult) {
        // handle the scanned code as you like, for example:
        setScannedCodes(scannedCodes.concat([{ decodedText, decodedResult }]));
        html5QrCode
          .stop()
          .then((ignore) => {
            // QR Code scanning is stopped.
          })
          .catch((err) => {
            // Stop failed, handle it.
          });
      }
      const config = { fps: 10, qrbox: { width: 250, height: 250 } };
      html5QrCode.start({ facingMode: "environment" }, config, onScanSuccess);
    } else {
    }
  }, [displayQr]);

  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Scan Payment </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                nextStep(values.amount);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div id="reader" width="600px"></div>
                  {displayQr === true ? (
                    scannedCodes.map((scannedCode, index) => (
                      <label key={index}>{scannedCode.decodedText}</label>
                    ))
                  ) : (
                    <>
                      <Input name="amount" label="Amount" />
                      <Button name="Submit" />
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
