import { useState } from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

//formik
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";

//primereact
import { Image } from "primereact/image";

//element component
import Button from "../../../components/element/button";
import InfoTemplate from "../../../components/modal/info.template";
import apiServices from "../../../services/api.services";
import Input from "../../../components/element/input";
import Select from "../../../components/element/select";
import { useEffect } from "react";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import CheckBox from "../../../components/element/checkbox";

export default function CreateItemModalBox({
  show,
  close,
  selectedData,
  serviceId,
  getInfoDetail,
}) {
  const [roleId, setRoleId] = useState([
    {
      label: "Maintenance",
      id: 9,
    },
    {
      label: "Housekeeping",
      id: 10,
    },
    {
      label: "F&B",
      id: 13,
    },
  ]);
  const [data, setData] = useState({
    title: "",
    original_price: "0.00",
    current_price: "",
    is_quantity: false,
    description: "",
    // roles_id: serviceId ? serviceId : "",
  });

  // const getRoleListing = async () => {
  //   const response = await apiServices.getRoleListing();
  //   if (response) {
  //     setRoleId(response);
  //   }
  // };

  const useSchema = Yup.object({
    title: Yup.string().required("required"),
    // original_price: Yup.string().required("required"),
    current_price: Yup.string().required("required"),
  });

  const handleSubmit = async (values, setFieldError) => {
    const payload = {
      type: "room_service_category_service_items",
      id: selectedData,
      title: values.title,
      original_price: values.original_price,
      current_price: values.current_price,
      is_quantity: values.is_quantity,
      description: values.description,
      // roles_id: values.roles_id,
    };

    try {
      const response = await apiServices.createService({
        api_name: "room_service_category",
        payload: payload,
      });
      if (response) {
        close();
        getInfoDetail();
        sweetAlertHelper({
          title: "Success",
          text: "Add Item Success",
          icon: "success",
        });
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   getRoleListing();
  // }, []);
  return (
    <MDBModal staticBackdrop tabIndex="-1" show={show}>
      <MDBModalDialog scrollable className="small-modal" size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Create Service Item</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => close()}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <Formik
              enableReinitialize={true}
              validateOnChange={true}
              initialValues={data}
              validationSchema={useSchema}
              onSubmit={(
                values,
                { setFieldError, resetForm, setSubmitting }
              ) => {
                handleSubmit(values, setFieldError, setSubmitting);
                // resetForm({ values: ""})
              }}
            >
              {({ handleReset, values, setFieldValue }) => (
                <Form className="modal-form">
                  <Input name="title" label="Title" />
                  <Input name="description" label="Description" />
                  {/* <Input name="original_price" label="Original Price" /> */}
                  <Input name="current_price" label="Price" />
                  <CheckBox name="is_quantity" label="Quantity" />
                  {/* <Select
                    name="roles_id"
                    label="Roles Id"
                    options={roleId}
                    placeholder="- Please Select -"
                  /> */}
                  <Button name="submit" type="submit" />
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
