import http from "./http.services";
import config from "../config.json";

const apiEndpoint = process.env.REACT_APP_API_URL + "/login";
const tokenKey = "token";

async function login(values) {
  const { data } = await http.post(apiEndpoint, values);
  const token = data.data.token || "";
  localStorage.setItem(tokenKey, token);
  localStorage.setItem("roles", data.data.role);
  localStorage.setItem("roles_id", data.data.role_id);
  localStorage.setItem("user_id", data.data.id);
  localStorage.setItem("username", data.data.username);

  if (token) return true;
  else return false;
}

function getToken() {
  return localStorage.getItem(tokenKey);
}

function getRole() {
  return localStorage.getItem("roles");
}

async function logout() {
  localStorage.clear();
}

function getCurrentUser() {
  const token = localStorage.getItem(tokenKey);
  if (token) return { token };
  else return "";
}

export default {
  login,
  logout,
  getToken,
  getRole,
  getCurrentUser,
};
